import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { UpdateCorpAll } from '@/gateway/affiliate/UpdateCorpAll'
import { regexToPatternString } from '@/data/Regex'
import CountryEnum from '@/enums/CountryEnum'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import { CeoInformation } from '@/presentation/affiliate/CeoInformationField'
import { ShareHolderInformationField, ShareHolderStatus } from '@/presentation/affiliate/ShareHolderInformationField'
import { FormValidator } from '@/presentation/FormValidator'
import { Form, IFormView } from '@/presentation/IFormView'
import store from '@/store'
import FormItemsOption from '@/models/forms/FormItemsOption'
import FormRule from '@/models/forms/FormRule'

export interface BusinessOwnerInfo extends IFormView {
  formValidator: FormValidator;
  subButtonLabel: string;
  onClickSubButton (): Promise<void>;

  isEnable (): boolean;
  isActivate (): boolean;

  ceoInfoList?: Array<CeoInformation>

  onClickLoadOwnerInfo (ceoInfo: CeoInformation): void;
  onSelectSkipOwnership (): void;
  onSelectQuarter (): void;
  onSelectOwnershipType (): void;

  disabledSubmit: boolean;
}

export class BusinessOwnerInfo implements BusinessOwnerInfo {
  private corpAll: CorpAll
  public ceoInfoList?: Array<CeoInformation>
  constructor () {
    this.field = {
      skipOwnership: undefined,
      quarterStake: undefined,
      ownershipType: undefined,
      share_holder_information: undefined
    }
    this.corpAll = store.getters.getCorpAllState
    this.ceoInfoList = this.corpAll.ceo_information_list
    this.setFields()
    this.fieldInitialize()
  }
  private setFields (): void {
    this.field.share_holder_information = this.corpAll.share_holder_information || {
      name: '',
      english_name: '',
      nationality: undefined,
      birthday: new Date(),
      share: undefined
    }
    const shareHolderStatus = Number(this.corpAll.share_holder_status)
    if (shareHolderStatus) {
      const isEntrepreneur = shareHolderStatus === Number(ShareHolderStatus.NOT_OWNER) || shareHolderStatus === Number(ShareHolderStatus.OWNER)
      if (isEntrepreneur) this.corpAll.share_holder_status = undefined
      const isSkippable = shareHolderStatus === Number(ShareHolderStatus.SKIPPABLE)
      this.field.skipOwnership = isSkippable
      if (isSkippable) return
      const isQuarterUpper = shareHolderStatus === Number(ShareHolderStatus.QUARTER_UPPER)
      this.field.quarterStake = isQuarterUpper
      if (!isQuarterUpper) this.field.ownershipType = this.corpAll.share_holder_status
    } else {
      this.field.skipOwnership = false
    }
  }
  private fieldInitialize (): void {
    if (this.field.skipOwnership !== false) return
    this.onSelectSkipOwnership()
    if (this.field.quarterStake === undefined) return
    this.onSelectQuarter()
    this.onSelectOwnershipType()
  }

  formValidator: FormValidator = new FormValidator()
  formName = 'businessOwnerDocument'
  formClass = 'divided-form last-form'

  field: ShareHolderInformationField = {
    skipOwnership: undefined,
    quarterStake: undefined,
    ownershipType: undefined,
    share_holder_information: {
      name: '',
      english_name: '',
      nationality: undefined,
      birthday: new Date(),
      share: undefined
    }
  }

  rules: Dictionary<Array<FormRule>> = {
    skipOwnership: [{ required: true }],
    quarterStake: [{ required: true }],
    ownershipType: [{ required: true }],
    'share_holder_information.name': [{ required: true }, { pattern: regexToPatternString('korean'), message: 'korean only' }],
    'share_holder_information.english_name': [{ required: true }],
    'share_holder_information.nationality': [{ required: true }],
    'share_holder_information.birthday': [{ required: true }],
    'share_holder_information.share': [{ required: true }]
  }

  private ownerInfoTitle = '사업체 실제 소유자 정보 (법인사업자)'
  private ownerInfoFormOptions: Array<FormItemsOption> = [
    {
      prop: 'skipOwnership',
      label: '귀 법인/단체는 실제 소유자 확인 생략 가능한 법인입니까?',
      class: 'vertical-radio-group',
      type: 'radio',
      options: [
        { label: '생략 가능하지 않습니다', value: false },
        { label: '생략 가능합니다', value: true }
      ],
      value: undefined,
      emitHandlerName: 'onSelectSkipOwnership'
    }
  ]

  private quarterStakeOptions: Array<FormItemsOption> = [
    {
      prop: 'quarterStake',
      type: 'radio',
      label: '25% 이상의 지분을 소유한 사람이 있습니까?',
      options: [
        { label: '있습니다.', value: true },
        { label: '없습니다.', value: false }
      ],
      class: 'vertical-radio-group',
      value: undefined,
      emitHandlerName: 'onSelectQuarter'
    },
    {
      prop: 'ownershipType',
      class: 'form-indent vertical-radio-group',
      type: 'radio',
      hide: true,
      options: [
        { label: '최대 지분을 소유한 사람이 있습니다.', value: ShareHolderStatus.LARGEST.toString() },
        { label: '대표자 또는 임원∙업무집행사원의 과반수를 선임한 주주(자연인)이 있습니다.', value: ShareHolderStatus.EXECUTIVES.toString() },
        { label: '위 두 경우 외에 법인∙단체를 사실상 지배하는 사람이 있습니다.', value: ShareHolderStatus.DOMINATION.toString() },
        { label: '모두 확인할 수 없습니다', value: ShareHolderStatus.UNKNOWN.toString() }
      ],
      value: undefined,
      emitHandlerName: 'onSelectOwnershipType'
    }
  ]
  ownerSubTitle = '최대 지분 소유자의 정보'
  private ownerOptions: Array<FormItemsOption> = [
    {
      prop: 'share_holder_information.name',
      label: '성명(한글)',
      type: 'text',
      value: undefined
    },
    {
      prop: 'share_holder_information.english_name',
      label: '성명(영문)',
      type: 'text',
      value: undefined
    },
    {
      prop: 'share_holder_information.nationality',
      label: '국적',
      type: 'select',
      options: getEnumOptions(CountryEnum, getEnumValueArray(CountryEnum), true).sort((a, b) => {
        const nameA = a.label
        const nameB = b.label
        if (a.value === 'KR') return -1
        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0)
      }),
      value: undefined
    },
    {
      prop: 'share_holder_information.birthday',
      label: '생년월일',
      type: 'date',
      value: undefined
    },
    {
      prop: 'share_holder_information.share',
      label: '지분율',
      type: 'text',
      subType: 'number',
      suffix: '%',
      step: 0.001,
      max: 100,
      min: 0,
      value: undefined
    }
  ]

  ownerForm: Form = {
    subTitle: this.ownerSubTitle,
    hasSubTitleButton: true,
    formItemOptions: this.ownerOptions
  }

  forms: Array<Form> = [
    {
      subTitle: this.ownerInfoTitle,
      formItemOptions: this.ownerInfoFormOptions
    }
  ]

  isEnable (): boolean {
    const hasBooleanFields = this.field.skipOwnership !== undefined ||
      this.field.quarterStake !== undefined ||
      this.field.ownershipType !== undefined
    const shareHolderInfo: Dictionary<any> = Object.assign({}, this.field.share_holder_information)
    const hasShareHolderInfo = Object.values(shareHolderInfo).some(field => {
      if (field === '0001-01-01T00:00:00Z') return false
      return !!field
    })
    return hasBooleanFields || hasShareHolderInfo
  }

  isActivate (): boolean {
    return this.corpAll.status === Number(CorpStatus.FORM_OWNER_PROCESSING)
  }

  onSelectSkipOwnership (): void {
    const quarterStakeForm: Form = {
      formInnerClass: 'expand',
      formItemOptions: this.quarterStakeOptions
    }
    if (this.field.skipOwnership) {
      this.forms.splice(1)
      this.field.quarterStake = undefined
      this.field.ownershipType = undefined
      this.field.share_holder_information = undefined
    } else {
      this.forms.push(quarterStakeForm)
    }
  }

  private hideOption (options: Array<FormItemsOption>, prop: string, isHide: boolean): void {
    options.find(option => {
      if (option.prop === prop) option.hide = isHide
    })
  }
  onSelectQuarter (): void {
    if (this.field.quarterStake) {
      this.field.ownershipType = undefined
      this.forms[2] = this.ownerForm
      this.hideOption(this.quarterStakeOptions, 'ownershipType', true)
      this.forms = Array(...this.forms)
    } else {
      this.hideOption(this.quarterStakeOptions, 'ownershipType', false)
      this.forms.splice(2)
    }
  }

  onClickLoadOwnerInfo (ceoInfo: CeoInformation) {
    this.field.share_holder_information = {
      name: ceoInfo.name,
      english_name: ceoInfo.english_name,
      nationality: ceoInfo.nationality,
      birthday: ceoInfo.birthday as Date,
      share: ''
    }
  }

  onSelectOwnershipType (): void {
    let ownerForm: Form = Object.assign({}, this.ownerForm)
    switch (Number(this.field.ownershipType)) {
      case Number(ShareHolderStatus.LARGEST):
        break
      case Number(ShareHolderStatus.EXECUTIVES):
        ownerForm = {
          subTitle: '주주(자연인)의 정보',
          hasSubTitleButton: true,
          formItemOptions: ownerForm.formItemOptions.filter(option => {
            return option.prop !== 'share_holder_information.share'
          })
        }
        break
      case Number(ShareHolderStatus.DOMINATION):
        ownerForm = {
          subTitle: '법인 - 단체를 사실상 지배하는 사람의 정보',
          hasSubTitleButton: true,
          formItemOptions: ownerForm.formItemOptions.filter(option => {
            return option.prop !== 'share_holder_information.share'
          })
        }
        break
      case Number(ShareHolderStatus.UNKNOWN):
        ownerForm = {
          subTitle: '법인 또는 단체의 대표자 중 1인',
          hasSubTitleButton: true,
          formItemOptions: ownerForm.formItemOptions.filter(option => {
            return option.prop !== 'share_holder_information.share'
          })
        }
        break
    }
    this.forms[2] = ownerForm
    this.forms = Array(...this.forms)
  }

  private setCorpAll () {
    let shareHolderStatus: ShareHolderStatus | undefined
    if (this.field.skipOwnership) shareHolderStatus = ShareHolderStatus.SKIPPABLE
    else if (this.field.quarterStake) shareHolderStatus = ShareHolderStatus.QUARTER_UPPER
    else shareHolderStatus = this.field.ownershipType
    this.corpAll = store.getters.getCorpAllState
    this.corpAll.share_holder_status = shareHolderStatus
    this.corpAll.share_holder_information = this.field.share_holder_information
    this.corpAll.status = CorpStatus.FORM_OWNER_PROCESSING
    store.commit('setCorpAllState', this.corpAll)
  }

  private async updateCorpAll () {
    const updateCorpAll: UpdateCorpAll = UpdateCorpAll.getInstance()
    await updateCorpAll.request(this.corpAll)
  }

  subButtonLabel = '임시 저장'
  async onClickSubButton (): Promise<void> {
    this.setCorpAll()
    await this.updateCorpAll()
  }

  disabledSubmit = false
  submitLabel = '문서 작성 완료'

  async onSubmit (): Promise<boolean | void> {
    this.setCorpAll()
    this.corpAll.status = CorpStatus.FORM_COMPLETE
    store.commit('setCorpAllState', this.corpAll)
    await this.updateCorpAll()
  }
}
