import CountryEnum from '@/enums/CountryEnum'

export enum IdentificationType {
  REGISTRATION_CARD = 1,
  PASSPORT
}

export interface CeoInformation {
  name: string;
  english_name: string;
  nationality?: keyof typeof CountryEnum;
  identification_type?: IdentificationType | string;
  identification_number?: string;
  gender?: 'male' | 'female';
  birthday?: Date;
}

export interface CeoInformationField {
  ceo_information_list?: Array<CeoInformation>;
  bank_name?: string;
  bank_account_number?: string;
  account_holder?: string;
}
