import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  ko: require('../../lokalise/locale/ko.json'),
  en: require('../../lokalise/locale/en.json')
}

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || navigator.language || navigator.userLanguage,
  fallbackLocale: 'ko',
  messages,
  silentTranslationWarn: true
})

export default i18n
