<template>
  <div>
    <el-table
      :class="['simple-data-table', { 'is-header-font-size-large': isHeaderFontSizeLarge }]"
      ref="multipleTable"
      :data="tableData"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      :row-class-name="tableRowClassName"
      :stripe="stripeStyle"
      style="width: 100%"
      @select="handleSelectionChange"
      @row-click="rowClicked"
    >
      <el-table-column
        v-if="useSelection"
        width="55"
        type="selection"
      />
      <el-table-column
        v-for="prop in tableHead"
        :key="prop"
        :prop="prop"
        :align="getAlign(prop)"
        :label="makeLabel(prop)"
        :width="cellWidth(prop)"
        :fixed="getFixed(prop)"
      >
        <template slot-scope="scope">
          <div
            v-if="isButtonType(scope.row[prop])"
            class="align-center"
          >
            <el-button
              :class="scope.row[prop].class"
              :circle="scope.row[prop].circle"
              :icon="scope.row[prop].icon"
              :size="scope.row[prop].size"
              :type="buttonType(scope.row[prop])"
              :disabled="scope.row[prop].disabled"
              :plain="getPlain(scope.row[prop])"
              @click="scope.row[prop].callback(scope.row[prop].value)"
            >
              {{ scope.row[prop].text }}
            </el-button>
          </div>
          <div v-else-if="isButtonArrayType(scope.row[prop])">
            <el-button
              v-for="button in scope.row[prop].value"
              :key="button.name"
              :class="button.class"
              :size="button.size"
              @click="button.callback(button.value)"
            >
              {{ button.text }}
            </el-button>
          </div>
          <template v-else-if="isSummaryType(scope.row[prop])">
            <div
              v-for="(currency, index) in Object.keys(scope.row[prop].value)"
              :key="currency"
              class="total-amount"
            >
              <span v-if="index === 0">{{ $t('commons.total') }}</span>
              <span v-else />
              <span><b>{{ formatLabel(prop, scope.row[prop].value[currency]) }}</b> {{ currency.toUpperCase() }}</span>
            </div>
          </template>
          <template v-else-if="isFileType(scope.row[prop])">
            {{ scope.row[prop].name }}
          </template>
          <template v-else-if="isCounterType(scope.row[prop])">
            {{ $t('commons.total_case', { total_case: scope.row[prop].value }) }}
          </template>
          <template v-else>
            {{ formatLabel(prop, scope.row[prop]) }} {{ scope.row[`${prop}_currency`] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formattersMap } from '@/lib/Utils'

export default {
  name: 'SimpleDataTable',
  props: {
    tableData: {
      type: Array,
      required: true
    },
    hideColumnProps: {
      type: Array,
      required: false,
      default () { return [] }
    },
    showSummary: Boolean,
    disableSummaryProps: {
      type: Array,
      required: false,
      default () { return [] }
    },
    numberFormatProps: {
      type: Array,
      required: false,
      default () { return [] }
    },
    hideLabel: {
      type: Array,
      required: false,
      default () { return [] }
    },
    useOriginLabel: Boolean,
    useSelection: Boolean,
    tableAlign: {
      type: String,
      default: ''
    },
    stripeStyle: {
      type: Boolean,
      default: true
    },
    fixedColumns: {
      type: Array,
      default () { return [] }
    },
    isHeaderFontSizeLarge: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      tableHead: []
    }
  },
  created () {
    this.setTableHead()
  },
  methods: {
    getAlign (label) {
      const alignCentered = ['counter', 'corp_pid', 'transfer_certificate', 'invoice', 'comment']
      return this.tableAlign || (alignCentered.includes(label) || label.includes('_amount') ? 'center' : 'left')
    },
    refineLabel (label) {
      return label.replace('_document', '')
    },
    makeLabel (label) {
      if (this.useOriginLabel) return label
      if (this.hideLabel.includes(label)) return ''
      return label === 'buttons' || label === 'remove_btn' ? '' : this.$t(`sheet.field.${this.refineLabel(label)}`) || label
    },
    formatLabel (prop, label) {
      if (!this.numberFormatProps.includes(prop)) return label
      return formattersMap.number(label)
    },
    handleSelectionChange (changeRows, selectRow) {
      if (!this.useSelection) return
      this.$emit('onSelectRow', selectRow)
    },
    tableRowClassName ({ rowIndex }) {
      if (this.showSummary && rowIndex === this.tableData.length - 1) return 'summary-row'
      return ''
    },
    setTableHead () {
      if (!this.tableData.length) return []
      const keys = this.tableData.reduce((prev, curr) => {
        return prev.concat(Object.keys(curr))
      }, [])
      const filteredKeys = keys.filter(key => !this.hideColumnProps.includes(key))
      const result = new Set(filteredKeys)
      this.tableHead = Array.from(result)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (this.disableSummaryProps.includes(column.property)) return
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = formattersMap.number(sums[index])
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    isButtonType (prop) {
      return prop && prop.type && prop.type === 'button'
    },
    isButtonArrayType (prop) {
      return prop && prop.type && prop.type === 'buttonArray'
    },
    isCounterType (prop) {
      return prop && prop.type && prop.type === 'counter'
    },
    isFileType (prop) {
      return prop && prop.hasOwnProperty('value') && prop.hasOwnProperty('name')
    },
    isSummaryType (prop) {
      return prop && prop.type && prop.type === 'summary'
    },
    buttonType (obj) {
      if (obj.buttonType) {
        return obj.buttonType
      }
      return obj.text ? 'text' : null
    },
    getFixed (prop) {
      if (this.fixedColumns.findIndex(column => column === prop) >= 0) return 'right'
      return false
    },
    align (prop) {
      return this.isButtonType(prop) ? 'center' : 'left'
    },
    getPlain (obj) {
      return !!obj.plain
    },
    cellWidth (prop) {
      const widths = {
        counter: 90,
        id: 60,
        transfer_id: 90,
        bank_account_number: 120,
        master_code_label: 120,
        phone_number: 150,
        comment: 330,
        buttons: 254,
        default: 0
      }
      return `${widths[prop] || widths.default}`
    },
    rowClicked (row) {
      if (!this.useSelection) return
      this.$emit('onSelectRow', row)
      this.$refs.multipleTable.toggleRowSelection(row)
    }
  }
}
</script>
<style lang="scss" scoped>
.simple-data-table.is-header-font-size-large {
  ::v-deep {
    .el-table__header-wrapper {
      .cell {
        font-size: 1.6rem;  
      }
    }
  }
}
</style>
