import { CreatePaymentTransaction } from '@/gateway/wallet/CreatePaymentTransaction'
import { GetWallet } from '@/gateway/wallet/GetWallet'
import { GetWalletActivities } from '@/gateway/wallet/GetWalletActivities'
import { Wallet } from '@/models/wallet/Wallet'
import { WalletActivity } from '@/models/wallet/WalletActivity'
import { CreatePaymentTransactionParam } from '@/gateway/wallet/model/CreatePaymentTransactionParam'
import { GetWalletActivitiesParam } from '@/gateway/wallet/model/GetWalletActivitiesParam'
import { GetWalletParam } from '@/gateway/wallet/model/GetWalletParam'
import { CreatePaymentTransactionResponse } from '@/gateway/wallet/model/CreatePaymentTransactionResponse'
import dayjs from 'dayjs'

export interface IWalletUseCase {
    getWallet(params: GetWalletParam): Promise<Array<Wallet>>;
    getWalletActivities(params: GetWalletActivitiesParam): Promise<Array<WalletActivity>>
    createPaymentTransaction(params: CreatePaymentTransactionParam): Promise<CreatePaymentTransactionResponse>
}

export class WalletUseCase implements IWalletUseCase {
    private walletInfo!: Array<Wallet>

    /**
     * GetWalletParam으로 wallet을 가져오는 API를 호출한다.
     * 
     * @param {GetWalletParam} params wallet을 가져올 파라미터
     * @returns {Promise<Array<Wallet>>} 가져온 Wallet 배열
     */
    public async getWallet (params: GetWalletParam): Promise<Array<Wallet>> {
      if (!this.walletInfo) this.walletInfo = await GetWallet.getInstance().request(params)
      return this.walletInfo
    }

    /**
     * GetWalletActivitiesParam으로 WalletActivity를 가져오는 API를 호출한다.
     * 
     * - local_transaction_time은 'YYYY-MM-DD HH:mm:ss' 형태로 변환
     * 
     * @param {GetWalletActivitiesParam} params WalletActivity를 가져올 파라미터
     * @returns {Promise<Array<WalletActivity>>} 가져와 변환한 WalletActivity 배열
     */
    public async getWalletActivities (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>> {
      let response = await GetWalletActivities.getInstance().request(params)
      response.forEach(activity => {
        activity.local_transaction_time = dayjs(new Date(activity.transaction_time)).format('YYYY-MM-DD HH:mm:ss')
      })
      return response
    }

    /**
     * /payment [POST] API를 CreatePaymentTransactionParam으로 호출한다.
     * 
     * 함수 명칭과는 다소 다르게, 입금 계좌정보를 가져올 때 사용
     * 
     * @param {CreatePaymentTransactionParam} params 입금 계좌정보를 가져올 파라미터
     * @returns {Promise<CreatePaymentTransactionResponse>} 가져온 입금계좌정보
     */
    public async createPaymentTransaction (params: CreatePaymentTransactionParam): Promise<CreatePaymentTransactionResponse> {
      return CreatePaymentTransaction.getInstance().request(params)
    }
}
