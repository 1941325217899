import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { RemittanceHistoryGroupDetail } from '@/models/remittance'

export interface GetRemittanceGroupDetail extends IApiRequest {
  getInstance (): GetRemittanceGroupDetail;
  request (data: { remittance_group_id: string }): Promise<Array<RemittanceHistoryGroupDetail>>;
}

export class GetRemittanceGroupDetail implements GetRemittanceGroupDetail {
  private static instance: GetRemittanceGroupDetail
  private constructor () {}
  public static getInstance (): GetRemittanceGroupDetail {
    if (!this.instance) this.instance = new GetRemittanceGroupDetail()
    return this.instance
  }

  async request (data: { remittance_group_id: string }): Promise<Array<RemittanceHistoryGroupDetail>> {
    const requestOption: RequestOption = {
      url: 'remittance/group/detail',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.list
  }
}
