
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OtpSignIn from '@/components/shared/OtpSignIn.vue'
import { initializeStaticValues } from '@/static/StaticValues'
import i18n from '@/plugins/i18n'

@Component({
  components: { OtpSignIn }
})
export default class SignIn extends Vue {
  isOtpRequested: boolean = false

  otpRequested (): void {
    this.isOtpRequested = true
  }

  async otpConfirmed () {
    await initializeStaticValues()
    await this.$router.push('/home')
    if (i18n.locale !== localStorage.getItem('locale')) location.reload()
  }
}
