import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { AffiliatedSignUpField } from '@/presentation/affiliate/AffiliatedSignUp'
import { sentbizApiWithoutAuth } from '@/gateway/axios'

export interface SignUpResponse {
  isSuccess: boolean;
  auth: string;
  refresh: string;
}

export interface SignUp extends IApiRequest {
  getInstance (): SignUp;

  request (data: AffiliatedSignUpField): Promise<SignUpResponse>;
}
export class SignUp implements SignUp {
  private static instance: SignUp
  private constructor () {}
  public static getInstance (): SignUp {
    if (!this.instance) this.instance = new SignUp()
    return this.instance
  }

  async request (data: AffiliatedSignUpField): Promise<SignUpResponse> {
    const requestOption: RequestOption = {
      url: 'signin',
      method: 'post',
      data
    }
    const response: any = await sentbizApiWithoutAuth.request(requestOption)
    const isSuccess = response.code === 200
    return Object.assign({}, { isSuccess }, response.data)
  }
}
