export const nationMasterCode: Dictionary<any> = {
  AU: 11326,
  GB: 11328,
  IN: 11329,
  US: 11330,
  CN: 100001,
  SE: 11459,
  ES: 11458,
  PT: 11457,
  PL: 11456,
  NL: 11455,
  MC: 11454,
  MT: 11453,
  LU: 11452,
  LT: 11451,
  LV: 11450,
  IT: 11449,
  IE: 11448,
  DE: 11447,
  FR: 11446,
  FI: 11445,
  EE: 11444,
  DK: 11443,
  CY: 11442,
  BG: 11441,
  BE: 11440,
  AT: 11439
}
