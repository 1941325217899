import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/store/RootState'
import { transferStore } from '@/store/modules/TransferState'
import { recipientStore } from '@/store/modules/RecipientState'
import { affiliateState } from '@/store/modules/AffiliateState'
import { walletStore } from '@/store/modules/WalletState'
import { fraudTransactionState } from '@/dome/store/fraudTransactionState'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    isLoading: false,
    loadingText: '...Loading',
    memberInfo: undefined
  },
  modules: {
    transferStore,
    recipientStore,
    affiliateState,
    walletStore,
    fraudTransactionState
  },
  getters: {},
  mutations: {
    disableLoading (state) {
      state.isLoading = false
      state.loadingText = '...Loading'
    },
    enableLoading (state, receivedLoadingText?: string) {
      state.isLoading = true
      state.loadingText = receivedLoadingText || state.loadingText
    }
  }
}

export default new Vuex.Store<RootState>(store)
