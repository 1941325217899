import { render, staticRenderFns } from "./MainTitle.vue?vue&type=template&id=4453be4c"
import script from "./MainTitle.vue?vue&type=script&lang=ts"
export * from "./MainTitle.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports