export enum BusinessCategory {
  CORPORATE_BUSINESS = 1,
  ENTREPRENEUR
}

export enum BusinessDivision {
  LARGE = 1,
  MEDIUM,
  SMALL,
  PUBLIC,
  ORGANIZATION,
  FINANCE,
  ETC
}

export enum StockInfo {
  PRIVATE = 1,
  KOSPI,
  KOSDAQ,
  ETC
}

export enum FundSource {
  BUSINESS = 1,
  REALTY,
  PENSION,
  FINANCE_SALE,
  INHERITANCE,
  SEVERANCE,
  REALTY_SALE,
  STOCK_SALE
}

export enum TransactionPurpose {
  CALCULATE = 1,
  ETC
}

export interface CompanyInfoField {
  name?: string
  name_ko?: string
  biz_category?: BusinessCategory
  biz_division?: BusinessDivision
  stock_info?: StockInfo
  country?: string
  line1?: string
  line2?: string
  line1Detail?: string
  city?: string
  region?: string
  postal_code?: string
  phone_code?: number
  phone_number?: number
  homepage?: string
  founded_at?: Date | string
  fund_source?: FundSource
  transaction_purpose?: TransactionPurpose
}
