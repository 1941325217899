import { UpdateCorpMemberLocaleParam } from '@/gateway/corpMember/UpdateCorpMemberLocale'
import { CorpMemberLocaleUseCase, ICorpMemberLocaleUseCase } from '@/usecase/user/CorpMemberLocaleUseCase'

export interface ICorpMemberLocalePresentation {
  updateCorpMemberLocale (data: UpdateCorpMemberLocaleParam): any
}

export class CorpMemberLocalePresentation implements ICorpMemberLocalePresentation {
  private useCase: ICorpMemberLocaleUseCase

  constructor () {
    this.useCase = new CorpMemberLocaleUseCase()
  }

  updateCorpMemberLocale (data: UpdateCorpMemberLocaleParam): any {
    return this.useCase.updateCorpMemberLocale(data)
  }
}
