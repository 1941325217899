import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import GetDefaultSnapshot from '@/gateway/home/GetDefaultSnapshot'
import { RemittanceSummarySeries } from '@/gateway/dashboard/model/RemittanceSummarySeries'
import { IRemittanceCharts } from '@/usecase/home/model/IRemittanceCharts'
import {
  DateFilterType,
  GetRemittanceSummarySeries,
  GetRemittanceSummarySeriesParam
} from '@/gateway/dashboard/GetRemittanceSummarySeries'
import dayjs from 'dayjs'
import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { SnapshotResponse } from '@/gateway/home/model/SnapshotResponse'

export interface IHomeUseCase {
  corpId: string;

  getCorpName (): Promise<string>;
  getDefaultSnapshot (): Promise<SnapshotResponse>;
  getRemittanceSummaryChart (param: GetRemittanceSummarySeriesParam): Promise<IRemittanceCharts>;
}

export class HomeUseCase implements IHomeUseCase {
  public corpId: string

  constructor () {
    this.corpId = MemberInfoGateway.getMemberInfo().corp_id
  }

  async getCorpName (): Promise<string> {
    const response = await GetCorpInformation.get()
    return response.corp_name
  }

  async getDefaultSnapshot (): Promise<SnapshotResponse> {
    const response: SnapshotResponse = await GetDefaultSnapshot.getInstance().request(this.corpId)
    return response
  }

  async getRemittanceSummaryChart (param: GetRemittanceSummarySeriesParam): Promise<IRemittanceCharts> {
    const chartData: IRemittanceCharts = {
      period: [], count: [], remittance: [], deposit: []
    }
    const fromStringFormat = dayjs(param.from).format('YYYY-MM-DD')
    const toStringFormat = dayjs(param.to).format('YYYY-MM-DD')
    param.from = new Date(`${fromStringFormat}T00:00:00.000Z`)
    param.to = new Date(`${toStringFormat}T23:59:59.999Z`)
    const isDailyType = DateFilterType.DAY === param.date_filter_type
    const isMonthlyType = DateFilterType.MONTH === param.date_filter_type
    const dateFormatTemplate = isMonthlyType ? 'YYYY-MM' : 'YYYY-MM-DD'
    const dailyPeriodMap = new Map<string, { period: string; count: number; remittance: number; deposit: number; }>()
    if (isDailyType) {
      for (let day = dayjs(param.from); day.isBefore(param.to, 'day') || day.isSame(param.to, 'day'); day = day.add(1, 'day')) {
        const periodDate = day.format(dateFormatTemplate)
        dailyPeriodMap.set(periodDate, { period: periodDate, count: 0, remittance: 0, deposit: 0 })
      }
    }
    const remittanceSummaries: Array<RemittanceSummarySeries> = await GetRemittanceSummarySeries.getInstance().request(param)
    remittanceSummaries.forEach(remittanceSummary => {
      const currentDate = dayjs(remittanceSummary.timestamp).format(dateFormatTemplate)
      if (isDailyType) {
        dailyPeriodMap.set(currentDate, {
          period: currentDate,
          count: remittanceSummary.remittance_count,
          remittance: remittanceSummary.usd_amount,
          deposit: remittanceSummary.send_amount
        })
        return
      }
      chartData.period.push(currentDate)
      chartData.count.push(remittanceSummary.remittance_count)
      chartData.remittance.push(remittanceSummary.usd_amount)
      chartData.deposit.push(remittanceSummary.send_amount)
    })
    if (!isDailyType) return chartData
    dailyPeriodMap.forEach((remittanceSummary) => {
      chartData.period.push(remittanceSummary.period)
      chartData.count.push(remittanceSummary.count)
      chartData.remittance.push(remittanceSummary.remittance)
      chartData.deposit.push(remittanceSummary.deposit)
    })
    return chartData
  }
}
