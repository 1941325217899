<template>
  <div class="default-wrapper">
    <slot name="upper"> </slot>
    <el-row class="sub-head" type="flex">
      <el-col class="sub-title">
        <h2>{{ title }}</h2>
        <h5>{{ subtitle }}</h5>
        <slot name="titleContents" />
      </el-col>
      <slot name="side" />
    </el-row>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
.dome-transactional-info {
  position: relative;
  max-width: 1638px;
  min-width: 1119px;

  .sub-title {
    padding-top: 16px;
    h2 {
      font-size: 36px !important;
      color: #1b1b1c;
    }
  }

  .sub-head {
    margin-top: 0;
  }

  .sub-head-upper {
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: #9297a0;
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
</style>
