import store from '@/store'
import {
  BusinessCategory,
  BusinessDivision,
  CompanyInfoField,
  FundSource,
  StockInfo,
  TransactionPurpose
} from '@/presentation/affiliate/CompanyInfoField'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import { regexToPatternString } from '@/data/Regex'
import { FormValidator } from '@/presentation/FormValidator'
import { Form, IFormView } from '@/presentation/IFormView'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { UpdateCorpAll } from '@/gateway/affiliate/UpdateCorpAll'
import FormRule from '@/models/forms/FormRule'

export interface CompanyInfo extends IFormView {
  formValidator: FormValidator
  setCorpAll(): void
  onClickSubButton(): Promise<void>

  onSelectedBusinessCategory(isCorporateType: boolean): void
  onSelectedPurpose(): boolean
}

export class CompanyInfo implements CompanyInfo {
  private corpAll: CorpAll

  constructor(emitCallback: any) {
    this.corpAll = store.getters.getCorpAllState
    this.field = {
      name: this.corpAll.name || '',
      name_ko: this.corpAll.name_ko || '',
      biz_category: this.corpAll.biz_category || undefined,
      biz_division: this.corpAll.biz_division || undefined,
      stock_info: this.corpAll.stock_info || undefined,
      country: this.corpAll.country,
      line1: this.corpAll.line1,
      line2: this.corpAll.line2,
      line1Detail: this.corpAll.line1Detail,
      city: this.corpAll.city,
      region: this.corpAll.region,
      postal_code: this.corpAll.postal_code,
      phone_number: this.corpAll.phone_number,
      homepage: this.corpAll.homepage,
      founded_at: this.corpAll.founded_at,
      fund_source: this.corpAll.fund_source || undefined,
      transaction_purpose: this.corpAll.transaction_purpose || undefined
    }
    this.fieldInitialize(emitCallback)
  }

  private fieldInitialize(emitCallback: any): void {
    this.onSelectedPurpose()
    if (this.corpAll.biz_category === undefined) return
    const isCorporateType = Number(BusinessCategory.CORPORATE_BUSINESS) === this.field.biz_category
    emitCallback(isCorporateType)
    this.onSelectedBusinessCategory(isCorporateType)
  }

  formValidator: FormValidator = new FormValidator()

  formName = 'companyDocuments'
  formClass = 'divided-form'

  field: CompanyInfoField

  private required: FormRule = { required: true }
  private entrepreneurRules: Dictionary<Array<FormRule>> = {
    name: [this.required],
    name_ko: [this.required],
    biz_category: [this.required],
    biz_division: [this.required],
    stock_info: [this.required],
    line1: [this.required],
    line2: [this.required],
    line1Detail: [this.required],
    phone_number: [this.required, { pattern: regexToPatternString('number'), message: 'invalid format' }],
    homepage: [{ pattern: regexToPatternString('website'), message: 'invalid format' }],
    founded_at: [this.required],
    fund_source: [{ ...this.required, message: 'This field is required' }],
    transaction_purpose: [{ ...this.required, message: 'This field is required' }]
  }
  rules: Dictionary<Array<FormRule>> = Object.assign({}, this.entrepreneurRules)

  private companyInfoTitle = '사업체 정보'
  private companyInfoFormOptions: Array<FormItemsOption> = [
    { prop: 'name', label: '상호(법인)명 (영문)', type: 'text', value: undefined, rules: [
      {
        pattern: regexToPatternString('numberEnglish'),
        message: '영문으로 입력해 주세요.',
        required: true
      }
    ] },
    { prop: 'name_ko', label: '상호(법인)명 (국문)', type: 'text', value: undefined },
    {
      prop: 'biz_division',
      label: '법인 구분 정보',
      type: 'select',
      hide: true,
      options: getEnumOptions(BusinessDivision, getEnumValueArray(BusinessDivision)),
      value: undefined
    },
    {
      prop: 'stock_info',
      label: '상장 정보',
      type: 'select',
      hide: true,
      options: getEnumOptions(StockInfo, getEnumValueArray(StockInfo)),
      value: undefined,
      rules: [this.required]
    },
    {
      prop: 'line2',
      label: '상호(법인) 주소 (영문, 상세주소 포함)',
      type: 'text',
      value: undefined,
      rules: [
        {
          pattern: regexToPatternString('address_line1'),
          message: '영문으로 입력해 주세요.',
          required: true
        }
      ]
    },
    {
      prop: 'line1',
      label: '상호(법인) 주소 (국문, 상세주소 포함)',
      type: 'input_button',
      emitHandlerName: 'onClickAddress',
      value: undefined
    },  
    { prop: 'phone_number', label: '회사 전화번호', type: 'text', value: undefined },
    { prop: 'homepage', label: '웹사이트 (선택)', type: 'text', value: undefined },
    { prop: 'founded_at', label: '사업체 설립일', type: 'date', value: undefined },
    {
      prop: 'biz_category',
      label: '사업체 구분',
      type: 'select',
      options: getEnumOptions(BusinessCategory, getEnumValueArray(BusinessCategory)),
      emitHandlerName: 'onSelectedBusinessCategory',
      value: undefined
    }    
  ]

  private sourcePurposeTitle = '거래 목적 및 자금 원천'
  private sourcePurposeFormOptions: Array<FormItemsOption> = [
    {
      prop: 'fund_source',
      label: '자금의 원천 및 출처',
      type: 'radio',
      options: getEnumOptions(FundSource, getEnumValueArray(FundSource)),
      value: undefined
    },
    {
      prop: 'transaction_purpose',
      label: '거래 목적',
      type: 'radio',
      options: getEnumOptions(TransactionPurpose, getEnumValueArray(TransactionPurpose)),
      value: undefined,
      emitHandlerName: 'onSelectedPurpose'
    }
  ]

  forms: Array<Form> = [
    {
      subTitle: this.companyInfoTitle,
      formItemOptions: this.companyInfoFormOptions
    },
    {
      subTitle: this.sourcePurposeTitle,
      formInnerClass: 'expand radio-form-item',
      formItemOptions: this.sourcePurposeFormOptions
    }
  ]

  onSelectedBusinessCategory(isCorporateType: boolean): void {
    const businessDivisionOption = this.companyInfoFormOptions.find(option => option.prop === 'biz_division')
    const stockInfoOption = this.companyInfoFormOptions.find(option => option.prop === 'stock_info')
    if (!businessDivisionOption || !stockInfoOption) return
    if (isCorporateType) {
      businessDivisionOption.hide = false
      stockInfoOption.hide = false
      businessDivisionOption.value = this.field.biz_division || undefined
      stockInfoOption.value = this.field.stock_info || undefined
      const rules: Dictionary<Array<FormRule>> = {
        biz_division: [this.required],
        stock_info: [this.required]
      }
      this.rules = Object.assign({}, this.entrepreneurRules, rules)
    } else {
      businessDivisionOption.hide = true
      stockInfoOption.hide = true
      this.rules = Object.assign({}, this.entrepreneurRules)
    }
  }

  onSelectedPurpose(): boolean {
    const isEtcSelected = Number(this.field.transaction_purpose) === Number(TransactionPurpose.ETC)
    this.isSubmitDisabled = isEtcSelected
    return isEtcSelected
  }

  setCorpAll() {
    this.corpAll = store.getters.getCorpAllState
    this.corpAll = Object.assign(this.corpAll, this.field)
    this.corpAll.status = CorpStatus.FORM_COMPANY_PROCESSING
    store.commit('setCorpAllState', this.corpAll)
  }

  private async updateCorpAll() {
    const updateCorpAll: UpdateCorpAll = UpdateCorpAll.getInstance()
    await updateCorpAll.request(this.corpAll)
  }

  subButtonLabel = '임시 저장'

  async onClickSubButton(): Promise<void> {
    this.setCorpAll()
    await this.updateCorpAll()
  }

  isSubmitDisabled = false
  submitLabel = '다음'

  async onSubmit(formName: any): Promise<boolean | void> {
    const isValid = await this.formValidator.validate(formName)
    if (!isValid || Object.keys(isValid).length >= 1) return false
    this.setCorpAll()
    await this.updateCorpAll()
    return isValid
  }
}
