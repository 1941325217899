import { GetRemittanceBase } from '@/gateway/remittanceBase/GetRemittanceBase'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'

export class AvailableCurrencies {
  private static remittanceBase: Array<IRemittanceBase> = []
  private static async getRemittanceBase(): Promise<Array<IRemittanceBase>> {
    if (this.remittanceBase.length) return this.remittanceBase
    this.remittanceBase = await GetRemittanceBase.getInstance().request()
    return this.remittanceBase
  }

  private static availableCurrencies: Array<string> = []
  public static async getAllList(): Promise<Array<string>> {
    if (!this.availableCurrencies.length) {
      const currenciesSet: Set<string> = new Set<string>()
      const remittanceBaseList = await this.getRemittanceBase()
      const hasRemittanceBaseList = remittanceBaseList?.length
      if (!hasRemittanceBaseList) return []
      remittanceBaseList.forEach(remittanceBase => {
        remittanceBase.supported_currency.forEach(currency => {
          currenciesSet.add(currency)
        })
      })
      currenciesSet.add('KRW')
      this.availableCurrencies = Array.from(currenciesSet)
    }
    return this.availableCurrencies
  }

  private static receivableCurrencies: Dictionary<Array<string>> = {}
  public static async getReceivableCurrencies(): Promise<Dictionary<Array<string>>> {
    if (!Object.keys(this.receivableCurrencies).length) {
      const countryCurrencies: Dictionary<Array<string>> = {}
      const remittanceBaseList = await this.getRemittanceBase()
      const hasRemittanceBaseList = remittanceBaseList?.length
      if (!hasRemittanceBaseList) return {}
      remittanceBaseList.forEach(remittanceBase => {
        countryCurrencies[remittanceBase.country] = remittanceBase.supported_currency
      })
      countryCurrencies['ZZ'] = ['USD']
      this.receivableCurrencies = countryCurrencies
    }
    return this.receivableCurrencies
  }

  private static transferableCurrencies: Dictionary<Array<string>> = {}
  public static async getTransferableCurrencies(): Promise<Dictionary<Array<string>>> {
    if (!Object.keys(this.transferableCurrencies).length) {
      const countryCurrencies: Dictionary<Array<string>> = {}
      const remittanceBaseList = await this.getRemittanceBase()
      const hasRemittanceBaseList = remittanceBaseList?.length
      if (!hasRemittanceBaseList) return {}
      remittanceBaseList.forEach(remittanceBase => {
        const defaultCurrencies = []
        const supportedCurrencies = remittanceBase.supported_currency
        if (!supportedCurrencies.includes('USD')) defaultCurrencies.push('USD')
        if (!supportedCurrencies.includes('KRW')) defaultCurrencies.push('KRW')
        countryCurrencies[remittanceBase.country] = [...supportedCurrencies, ...defaultCurrencies]
      })
      countryCurrencies['ZZ'] = ['USD']
      this.transferableCurrencies = countryCurrencies
    }
    return this.transferableCurrencies
  }
}
