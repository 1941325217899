import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { FxRates } from '@/gateway/fxRates/FxRates'
import { GetFxRates } from '@/gateway/fxRates/GetFxRates'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import { CancelRemittanceGroup } from '@/gateway/remittance/CancelRemittanceGroup'
import { GetRemittanceGroupDetail } from '@/gateway/remittance/GetRemittanceGroupDetail'
import { GetRemittanceGroups } from '@/gateway/remittance/GetRemittanceGroups'
import { GetRemittanceInvoice } from '@/gateway/remittance/GetRemittanceInvoice'
import { GetVirtualAccount } from '@/gateway/remittance/GetVirtualAccount'
import { UpdateRemittanceComment } from '@/gateway/remittance/UpdateRemittanceComment'
import MemberInfo from '@/models/MemberInfo'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import { CurrencyEnum } from '@/enums/CurrencyEnum'

export interface ITransferHistoryUseCase {
  corpInfo: CorpInformation

  getRemittanceGroupDetail(remittanceGroupId: string): any
  getRemittanceGroups(data: any): any
  getVirtualAccount(corpId: string): any
  getCorpInfo(): Promise<CorpInformation | undefined>
  getFxRates(fxRatesId: string): Promise<FxRates>
  getKrwRates(fxRatesId: string): Promise<KrwFxRates>
  getKrwRatesV4(
    fxRatesId: string,
    rateFee?: { currency: keyof typeof CurrencyEnum; rate_fee: number }
  ): Promise<KrwFxRates>
  cancelRemittanceGroup(id: string): any
  getRemittanceInvoice(remittanceId: string): Promise<string>
  updateRemittanceComment(param: { remittance_id: any; comment: any }): Promise<any>
}

export class TransferHistoryUseCase implements ITransferHistoryUseCase {
  public corpInfo!: CorpInformation

  getRemittanceGroupDetail(remittanceGroupId: string): any {
    return GetRemittanceGroupDetail.getInstance().request({ remittance_group_id: remittanceGroupId })
  }

  getRemittanceGroups(data: any): any {
    return GetRemittanceGroups.getInstance().request(data)
  }

  getVirtualAccount(corpId: string): any {
    return GetVirtualAccount.getInstance().request({ corp_id: corpId })
  }

  async getCorpInfo(): Promise<CorpInformation | undefined> {
    if (this.corpInfo) return this.corpInfo
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    this.corpInfo = (await GetCorpInformation.get({ corp_id: memberInfo.corp_id })) as CorpInformation
    return this.corpInfo
  }

  async getFxRates(fxRatesId: string): Promise<FxRates> {
    const fxRates = await GetFxRates.get(fxRatesId)
    return fxRates
  }

  async getKrwRates(fxRatesId: string): Promise<KrwFxRates> {
    const krwFxRates = await GetFxRates.getKrwMidRatesV3(fxRatesId)
    return krwFxRates
  }

  async getKrwRatesV4(
    fxRatesId: string,
    rateFee?: { currency: keyof typeof CurrencyEnum; rate_fee: number }
  ): Promise<KrwFxRates> {
    const krwFxRates = await GetFxRates.getKrwAppliedRatesV4(fxRatesId, rateFee)
    return krwFxRates
  }

  cancelRemittanceGroup(id: string): any {
    return CancelRemittanceGroup.getInstance().request({ remittance_group_id: id })
  }

  async getRemittanceInvoice(remittanceId: string): Promise<string> {
    return GetRemittanceInvoice.getInstance().request({ remittance_id: remittanceId })
  }

  async updateRemittanceComment(param: { remittance_id: any; comment: any }): Promise<any> {
    return UpdateRemittanceComment.getInstance().request(param)
  }
}
