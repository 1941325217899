import { AddressResponse, GetAddress } from '@/gateway/address/GetAddress'
import { Address, AddressField } from '@/presentation/address/AddressField'
import { FormValidator } from '@/presentation/FormValidator'
import { IFormView } from '@/presentation/IFormView'
import FormItemsOption from '@/models/forms/FormItemsOption'
import CountryEnum from '@/enums/CountryEnum'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import { staticValues } from '@/static/StaticValues'
import FormRule from '@/models/forms/FormRule'

interface SearchedAddress {
  line1: string;
  line2?: string;
  line1Detail?: string;
}

interface ManualAddress {
  country?: string;
  line1: string;
  line2?: string;
  city: string;
  region: string;
  postal_code: string;
}

export interface AddressModalPresenter extends IFormView {
  keyword: string;
  hasSearched: boolean;
  addressList: Array<AddressResponse>;
  selectedAddress: AddressResponse | undefined;
  address: Address;
  field: SearchedAddress;
  rules: Dictionary<Array<FormRule>>;

  manualField: ManualAddress;
  manualForms: Array<FormItemsOption>;
  manualFormRules: Dictionary<Array<FormRule>>;

  showManualInput: boolean;

  searchAddress (): Promise<void>;
  onClickAddress (address: AddressResponse): void;
  onSelectManualCountry (): void;
  onSubmitManual (formName: any): Promise<boolean>;
  onSubmit (formName: any): Promise<boolean>;
}

export class AddressModalPresenter implements AddressModalPresenter {
  constructor (address: Address) {
    if (!address) address = { line1: '' }
    this.field = {
      line1: address.line1 || '',
      line1Detail: address.line1Detail || ''
    }
    const line1 = address.line1.split(',')[0]
    this.manualField = {
      country: address.country || '',
      region: address.region || '',
      city: address.city || '',
      line1: line1 || '',
      line2: address.line2 || '',
      postal_code: address.postal_code || ''
    }
  }

  private formValidator: FormValidator = new FormValidator()
  formName = 'searchForm'
  keyword = ''
  hasSearched = false
  showManualInput = false
  addressList: Array<AddressResponse> = []
  selectedAddress: AddressResponse | undefined = undefined
  address: Address = { line1: '' }
  field: SearchedAddress
  rules: Dictionary<Array<FormRule>> = {
    line1: [{ required: true, message: '선택된 주소가 없습니다.' }],
    line1Detail: [
      { required: true },
      {
        message: '필수 입력값입니다.'
      }
    ]
  }

  manualField: ManualAddress
  private required: FormRule = { required: true, message: 'This field is required' }
  manualForms: Array<FormItemsOption> = [
    {
      prop: 'country',
      label: '국가',
      type: 'select',
      options: [
        ...getEnumOptions(CountryEnum, getEnumValueArray(CountryEnum), true),
        { label: '그외 국가', value: 'ZZ' }
      ],
      value: undefined,
      emitHandlerName: 'onSelectManualCountry'
    },
    {
      prop: 'region',
      label: '주',
      type: 'text',
      value: undefined
    },
    {
      prop: 'city',
      label: '시',
      type: 'text',
      value: undefined
    },
    {
      prop: 'line1',
      label: '주소 라인1',
      type: 'text',
      value: undefined
    },
    {
      prop: 'line2',
      label: '주소 라인2',
      type: 'text',
      value: undefined
    },
    {
      prop: 'postal_code',
      label: '우편번호',
      type: 'text',
      value: undefined
    }
  ]
  manualFormRules: Dictionary<Array<FormRule>> = {
    country: [this.required],
    region: [this.required],
    city: [this.required],
    line1: [this.required],
    postal_code: [this.required]
  }

  async searchAddress (): Promise<void> {
    this.selectedAddress = undefined
    const getAddress = GetAddress.getInstance()
    const param: AddressField = {
      language: 'ko',
      countPerPage: 10,
      currentPage: 1,
      keyword: this.keyword
    }
    this.addressList = await getAddress.request(param)
    this.hasSearched = true
  }

  async onClickAddress (address: AddressResponse): Promise<void> {
    const param: AddressField = {
      language: 'ko',
      countPerPage: 1,
      currentPage: 1,
      keyword: address.roadAddr
    }
    const engAddr = await GetAddress.getInstance().request(param)
    this.selectedAddress = engAddr.length ? engAddr[0] : address
    this.field.line1 = this.selectedAddress.roadAddr
    this.field = Object.assign({}, this.field)
  }

  onSelectManualCountry (): void {
    if (this.manualField.country !== 'ZZ') return
    this.manualField.country = undefined
    this.manualForms[0].options = CountryPhoneCodes.map(country => {
      return { label: country.name, value: country.iso }
    })
  }

  private setManualAddress (): void {
    let phoneCode
    let selectedCountry: any = staticValues.availableCountriesIso.find(country => country === this.manualField.country)
    if (selectedCountry) {
      phoneCode = CountryPhoneCodes.find(country => country.iso === selectedCountry)?.phone_code || 0
    } else {
      selectedCountry = CountryPhoneCodes.find(country => country.iso === this.manualField.country)
      phoneCode = selectedCountry ? selectedCountry.phone_code : 0
    }
    this.address = {
      country: this.manualField.country,
      phone_code: phoneCode || 0,
      line1: `${this.manualField.line1}, ${this.manualField.city}, ${this.manualField.region}, ${this.manualField.country}`,
      line2: this.manualField.line2,
      city: this.manualField.city,
      region: this.manualField.region,
      postal_code: this.manualField.postal_code
    }
  }

  async onSubmitManual (formName: any): Promise<boolean> {
    const isValid = await this.formValidator.validate(formName)
    if (!isValid) return isValid
    this.setManualAddress()
    return isValid
  }

  private setAddress (): void {
    if (!this.selectedAddress) return

    const [, city, region] = this.selectedAddress.engAddr?.split(',') as string[]

    this.address = {
      country: 'KR',
      phone_code: 82,
      line1: `${this.selectedAddress.roadAddr} ${this.field.line1Detail}`,
      city: city.trim(),
      region: region.trim(),
      postal_code: this.selectedAddress.zipNo
    }
  }

  async onSubmit (formName: any): Promise<boolean> {
    const isValid = await this.formValidator.validate(formName)
    if (!isValid) return isValid
    this.setAddress()
    return isValid
  }
}
