// @ts-ignore
import 'jspdf-autotable'
import jsPDF, { jsPDFOptions } from 'jspdf'

export abstract class CertDocumentPdfHandler {
  protected doc: any
  protected finalY = 0
  protected topMargin = 10
  protected pageStandardName: 'landscape' | 'portrait'
  protected pageStandard: { width: number; height: number }
  protected readonly pageInfo = {
    'portrait': { width: 210, height: 297 },
    'landscape': { width: 297, height: 210 }
  }

  protected constructor (pageStandardName: 'landscape' | 'portrait' = 'landscape') {
    this.pageStandardName = pageStandardName
    this.pageStandard = this.pageInfo[pageStandardName]
    const pdfPageOptions: jsPDFOptions = { orientation: 'landscape', unit: 'mm' }
    // eslint-disable-next-line new-cap
    this.doc = new jsPDF(pdfPageOptions)
  }

  protected getAmountWithCurrency (sourceAmount: string) {
    const splitAmount = sourceAmount.split(' ')
    if (splitAmount.length < 2) return sourceAmount
    const amount = splitAmount[0]
    const currency = splitAmount[1].toUpperCase()
    const roundedAmount = Math.round(Number.parseFloat(amount) * 100) / 100
    return `${roundedAmount} ${currency}`
  }

  protected encodeBase64ImageFileByPath (url: any) {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
  }

  protected createdTableHeaderAndBody (tableList: Array<Dictionary<any>>): { head: Dictionary<any>, body: Dictionary<any> } {
    const head: Array<Dictionary<any>> = [Object.keys(tableList[0])]
    const body: Dictionary<any> = tableList.map((row: any) => {
      if (row.receive_amount) row.receive_amount = this.getAmountWithCurrency(row.receive_amount)
      if (row.send_amount) row.send_amount = this.getAmountWithCurrency(row.send_amount)
      if (row.receive_amount_usd) row.receive_amount_usd = this.getAmountWithCurrency(`${row.receive_amount_usd} usd`)
      return Object.values(row)
    })
    return { head, body }
  }

  protected getAlignCenterPositionX (text = '1'): number {
    const pageName = this.pageStandardName || 'landscape'
    return Math.floor((this.pageInfo[pageName].width / 2) - (text.length * 1.58))
  }

  protected getNextPositionY (distance = 1): number {
    this.finalY += this.topMargin * distance
    return this.finalY
  }

  protected makeCustomRowLines (blank: number, yDistance: number): void {
    this.doc.line(blank, this.getNextPositionY(yDistance), this.pageStandard.width - blank, this.getNextPositionY(0))
  }

  protected printTextObject (textObject: any, x: any, y: any, fontSize = 11, lineHeight = 3.5) {
    if (!this.doc || typeof textObject !== 'object') return
    Object.keys(textObject).forEach((key) => {
      this.doc.setFontSize(fontSize)
      if (typeof textObject[key] === 'object') {
        this.doc.text(key, x, y)
        x += 10
        y += lineHeight
        this.doc.setFontSize(fontSize - 2)
        Object.keys(textObject[key]).forEach((vKey) => {
          this.doc.text(`${vKey} : ${textObject[key][vKey]}`, x, y)
          y += lineHeight
        })
        x -= 10
        y += lineHeight
      } else if (typeof textObject[key] === 'string') {
        this.doc.text(`${key} : ${textObject[key]}`, x, y)
        y += (lineHeight * 2)
      }
    })
    return y
  }

  protected printFooter (message: string, x: number): void {
    const footerText = message
    this.finalY = this.pageStandard.height - 15
    this.doc.text(footerText, this.getAlignCenterPositionX(footerText) + x, this.getNextPositionY(0))
  }

  protected async printLogoHeader (): Promise<void> {
    const logoImage = await this.encodeBase64ImageFileByPath('images/SENTBIZ_logo_black.png')
    this.doc.addImage(logoImage, 'png', 10, this.getNextPositionY(0.6), 45, 9)

    this.doc.setLineWidth(0.2)
    this.doc.setDrawColor(0, 0, 0)
    this.doc.line(9, this.getNextPositionY(1.3), this.pageStandard.width - 9, this.getNextPositionY(0))
  }

  abstract printDocument (textContents: Dictionary<string>): Promise<void>
}
