import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface GetCorpInformationParam {
  corp_id: string
}

interface CorpInformationApi extends IApiRequest {
  getInstance (): CorpInformationApi;

  request (params?: GetCorpInformationParam): Promise<CorpInformation>;
}

class CorpInformationApi implements CorpInformationApi {
  private static instance: CorpInformationApi
  private constructor () {}
  public static getInstance (): CorpInformationApi {
    if (!this.instance) this.instance = new CorpInformationApi()
    return this.instance
  }

  async request (params?: GetCorpInformationParam): Promise<CorpInformation> {
    if (!params) {
      const memberInfo = MemberInfoGateway.getMemberInfo()
      params = { corp_id: memberInfo.corp_id }
    }
    const requestOption: RequestOption = {
      url: 'corp',
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }
}

export class GetCorpInformation {
  private static corpInfo: CorpInformation | null
  public static async get (params?: GetCorpInformationParam): Promise<CorpInformation> {
    if (!this.corpInfo) this.corpInfo = await CorpInformationApi.getInstance().request(params)
    return this.corpInfo
  }

  public static refresh () {
    if (this.corpInfo) this.corpInfo = null
  }
}
