import i18n from '@/plugins/i18n'
import CardInHomeOptions from '@/presentation/home/model/CardInHomeOptions'
import { HomeUseCase } from '@/usecase/home/HomeUseCase'
import { DateFilterType, GetRemittanceSummarySeriesParam } from '@/gateway/dashboard/GetRemittanceSummarySeries'
import { IRemittanceCharts } from '@/usecase/home/model/IRemittanceCharts'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { SnapshotResponse } from '@/gateway/home/model/SnapshotResponse'

export interface SnapshotData {
  recent_remittance: Array<CardInHomeOptions>;
  total_remittance: Array<CardInHomeOptions>;
}

export interface IHomeMainPresentation {
  snapshotData: SnapshotData;
  hasRemittanceCount: boolean;
  dateFilterType: DateFilterType;
  dateRange: Array<Date>;
  chartData: IRemittanceCharts;

  getCorpName (): Promise<string>;
  setSnapshotData (): Promise<void>;
  setRemittanceSummaryChart (): Promise<void>;
}

export class HomeMainPresentation implements IHomeMainPresentation {
  public snapshotData: SnapshotData = {
    recent_remittance: [],
    total_remittance: []
  }
  public hasRemittanceCount: boolean
  public dateFilterType: DateFilterType
  public dateRange: Array<Date>
  public chartData: IRemittanceCharts
  private homeUseCase: HomeUseCase

  constructor () {
    this.homeUseCase = new HomeUseCase()
    let localizedFormat = require('dayjs/plugin/localizedFormat')
    dayjs.extend(localizedFormat)
    dayjs.locale(localStorage.getItem('locale') as string)
    this.hasRemittanceCount = false
    this.dateFilterType = DateFilterType.TRANSFER_DATE
    this.dateRange = [
      dayjs().add(-6, 'month').toDate(),
      dayjs().toDate()
    ]
    this.chartData = {
      period: [], count: [], remittance: [], deposit: []
    }
  }

  private async getSnapshotResponse (): Promise<SnapshotResponse> {
    const response: SnapshotResponse = await this.homeUseCase.getDefaultSnapshot()
    if (response.recent_remittance_count) this.hasRemittanceCount = true
    return response
  }

  private initSnapshotData (): void {
    this.snapshotData = {
      recent_remittance: [],
      total_remittance: []
    }
  }

  async getCorpName (): Promise<string> {
    const corpName = await this.homeUseCase.getCorpName()
    return corpName
  }

  async setSnapshotData (): Promise<void> {
    this.initSnapshotData()
    const response: SnapshotResponse = await this.getSnapshotResponse()
    this.snapshotData.recent_remittance = [{
      type: 'contentWithTitle',
      class: 'snapshot-card-date',
      content: {
        title: `${i18n.t('home.transfer_recent_date')}`,
        substance: dayjs(response.recent_remittance_date).format('L'),
        caption: `* ${i18n.t('home.show_transaction_all')}`
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_recent_count')}`,
        substance: response.recent_remittance_count.toLocaleString()
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_recent_amount_usd')}`,
        substance: response.recent_send_amount.toLocaleString()
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_recent_amount_krw')}`,
        substance: response.recent_deposit_amount.toLocaleString()
      }
    }]
    this.snapshotData.total_remittance = [{
      type: 'contentWithTitle',
      class: 'snapshot-card-date',
      content: {
        title: `${i18n.t('home.transfer_accumulate_date')}`,
        substance: `${dayjs(response.since).format('L')} ~`,
        caption: `* ${i18n.t('home.show_transaction_done')}`
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_accumulate_count')}`,
        substance: response.total_remittance_count.toLocaleString()
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_accumulate_amount_usd')}`,
        substance: response.total_send_amount.toLocaleString()
      }
    },
    {
      type: 'contentWithTitle',
      class: 'snapshot-card',
      content: {
        title: `${i18n.t('home.transfer_accumulate_amount_krw')}`,
        substance: response.total_deposit_amount.toLocaleString()
      }
    }]
  }

  async setRemittanceSummaryChart (): Promise<void> {
    const param: GetRemittanceSummarySeriesParam = {
      corp_id: MemberInfoGateway.getMemberInfo().corp_id,
      date_filter_type: this.dateFilterType,
      from: this.dateRange[0],
      to: this.dateRange[1]
    }
    this.chartData = await this.homeUseCase.getRemittanceSummaryChart(param)
  }
}
