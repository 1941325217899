import { CurrencyEnum } from '@/enums/CurrencyEnum'
import CountryEnum from '@/enums/CountryEnum'
import { Fee } from '@/gateway/commons/model/CorpInformation'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import Recipient from '@/models/recipient/Recipient'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import { CalculatorSourceType, ICalculatorSource } from '@/lib/calculator/Calculator'
import { RemittanceHistoryGroupDetail } from '@/models/remittance'
import { ReceiveMethodEnum } from '@/enums'

export interface CalculateOption {
  baseAmount: number
  baseAmountCurrency: keyof typeof CurrencyEnum
  sendAmountCurrency: keyof typeof CurrencyEnum
  receiveAmountCurrency: keyof typeof CurrencyEnum
  defaultBaseCurrency: keyof typeof CurrencyEnum
  receiveMethod?: string
  recipientCountry?: keyof typeof CountryEnum
  fee: Fee
  isReceipt?: boolean
  version?: number
}

export class CalculateOptionV3 {
  source: ICalculatorSource
  constructor(source: ICalculatorSource) {
    this.source = source
  }

  private getCountryCurrencyFee(recipient: Recipient): Fee {
    const defaultFee: Fee = { fixed: 0, rate: 0, ratio: 0, fee_currency: 'USD' }
    const corpInfo = this.source.corpInfo
    if (!corpInfo) return defaultFee
    const corpCountryFees: Array<Fee> = corpInfo.fees.filter(
      fee => fee.country === recipient.country && fee.currency === recipient.currency
    )
    const allCountryFee: Fee = corpInfo.fees.find(fee => fee.country === 'ZZ') || defaultFee
    return corpCountryFees[0] || allCountryFee
  }

  private getTransferRowOption(transferRow: TransferRow, recipient: Recipient): CalculateOption {
    const fee = this.getCountryCurrencyFee(recipient)
    return {
      baseAmount: Number(transferRow.amount),
      baseAmountCurrency: transferRow.base_currency as keyof typeof CurrencyEnum,
      sendAmountCurrency: 'KRW',
      receiveAmountCurrency: recipient.currency,
      defaultBaseCurrency: 'USD',
      recipientCountry: recipient.country,
      fee,
      version: fee.version
    }
  }

  private getRemittanceGroupOption(remittanceGroup: RemittanceGroupDetail): CalculateOption {
    return {
      baseAmount: remittanceGroup.base_amount.balance,
      baseAmountCurrency: remittanceGroup.base_amount.currency as keyof typeof CurrencyEnum,
      sendAmountCurrency: 'KRW',
      receiveAmountCurrency: remittanceGroup.recipient.currency,
      defaultBaseCurrency: 'USD',
      recipientCountry: remittanceGroup.recipient.country,
      fee: this.getCountryCurrencyFee(remittanceGroup.recipient),
      version: remittanceGroup.version
    }
  }

  private getReceiptOption(remittance: RemittanceHistoryGroupDetail): CalculateOption {
    return {
      baseAmount: remittance.base_amount.balance,
      baseAmountCurrency: remittance.base_amount.currency,
      sendAmountCurrency: remittance.send_amount.currency,
      receiveAmountCurrency: remittance.receive_amount.currency,
      defaultBaseCurrency: 'USD',
      receiveMethod: ReceiveMethodEnum[remittance.recipient.remittance_method_type],
      recipientCountry: remittance.recipient.country,
      fee: {
        fixed: remittance.fixed_fee,
        rate: remittance.rate_fee,
        ratio: remittance.ratio,
        min_amount: remittance.min_amount,
        min_fee: remittance.min_fee,
        fee_currency: remittance.fee_currency,
        country: remittance.recipient.country,
        currency: remittance.receive_amount.currency
      },
      isReceipt: true,
      version: remittance.version
    }
  }

  private getCurrencyReceiptOption(remittance: RemittanceHistoryGroupDetail): CalculateOption {
    return {
      baseAmount: remittance.base_amount.balance,
      baseAmountCurrency: remittance.base_amount.currency,
      sendAmountCurrency: remittance.send_amount.currency,
      receiveAmountCurrency: remittance.receive_amount.currency,
      defaultBaseCurrency: 'USD',
      fee: {
        fixed: remittance.fixed_fee,
        rate: remittance.rate_fee,
        ratio: remittance.ratio,
        min_amount: remittance.min_amount,
        min_fee: remittance.min_fee,
        fee_currency: remittance.fee_currency,
        country: remittance.recipient.country,
        currency: remittance.receive_amount.currency
      },
      isReceipt: true,
      version: remittance.version
    }
  }

  get option(): CalculateOption {
    let option: CalculateOption | null = null
    switch (this.source.type) {
      case CalculatorSourceType.RECEIPT_INDIVIDUAL_RECEIPT:
      case CalculatorSourceType.RECEIPT_TRANSACTION_RECEIPT:
      case CalculatorSourceType.TRANSFER_CONFIRMATION:
        const remittanceHistoryGroupDetail = this.source.remittanceHistoryGroupDetail
        if (!remittanceHistoryGroupDetail) break
        option = this.getReceiptOption(remittanceHistoryGroupDetail)
        break
      case CalculatorSourceType.TRANSFER_HISTORY:
      case CalculatorSourceType.TRANSFER_CURRENCY_RECEIPT:
        const historyGroupDetail = this.source.remittanceHistoryGroupDetail
        if (!historyGroupDetail) break
        option = this.getCurrencyReceiptOption(historyGroupDetail)
        break
      case CalculatorSourceType.REMITTANCE_GROUP:
        const remittanceGroup = this.source.remittanceGroupDetail
        if (!remittanceGroup || !this.source.corpInfo) break
        option = this.getRemittanceGroupOption(remittanceGroup)
        break
      case CalculatorSourceType.REMITTANCE:
        const transferRow = this.source.transferRow
        const recipient = this.source.recipient
        if (!transferRow || !recipient || !this.source.corpInfo) break
        option = this.getTransferRowOption(transferRow, recipient)
        break
      default:
        break
    }
    if (!option) {
      const errorSourceType = CalculatorSourceType[this.source.type]
      throw new Error(`There is no option source [${errorSourceType}]`)
    }
    return option
  }
}
