import { RemittanceHistoryGroupResources, RemittanceHistoryGroup } from '@/models/remittance/TransferHistory'
import MemberInfo from '@/models/MemberInfo'
import { ITransferHistoryUseCase, TransferHistoryUseCase } from '@/usecase/transferHistory/TransferHistoryUseCase'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface ITransferHistoryViewPresentation {
  dataResources: RemittanceHistoryGroupResources
  useCase: ITransferHistoryUseCase

  filterList (shownDetails: string | null): Array<RemittanceHistoryGroup>
  setData (param: { data: { list: Array<any>, total_group_count?: number }, field: string }): void
  setDetailsData (remittanceGroupId: string): Promise<void>
  setListData (param: { newParams: Object, field: string, page: number, period: Object }): Promise<void>
}

export class TransferHistoryViewPresentation implements ITransferHistoryViewPresentation {
  public useCase: ITransferHistoryUseCase
  public dataResources: RemittanceHistoryGroupResources = {
    list: {
      list: [],
      total_group_count: 0
    },
    listByPeriod: {
      list: [],
      total_group_count: 0
    },
    details: {
      list: []
    }
  }

  constructor () {
    this.useCase = new TransferHistoryUseCase()
  }

  filterList (shownDetails: string): Array<RemittanceHistoryGroup> {
    const selectedList = this.dataResources.list.list.filter(({ id }) => id === shownDetails)
    return selectedList
  }

  setData ({ data, field = 'list' }: { data: { list: Array<any>, total_group_count?: number }, field: string }): any {
    this.dataResources[field] = data
  }

  async setDetailsData (remittanceGroupId: string): Promise<void> {
    const remittanceGroupDetail = await this.useCase.getRemittanceGroupDetail(remittanceGroupId)
    this.setData({ data: { list: remittanceGroupDetail }, field: 'details' })
  }

  async setListData (param: { newParams: Object, field: string, page: number, period: Object }): Promise<void> {
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const data = {
      corp_id: memberInfo.corp_id,
      page: param.page,
      unit: 10
    }
    const requestParam = Object.assign({}, data, param.period, param.newParams)
    const response = await this.useCase.getRemittanceGroups(requestParam)
    this.setData({ data: response.data, field: param.field })
  }
}
