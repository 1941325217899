enum ResponseCode {
  EXIST_EMAIL = 26,
  INVALID_LOGIN = 44,
  NOT_FOUND_USER = 45,
  PW_NOT_MATCH = 46,
  PW_EXPIRE = 48,
  PW_LOCK = 49,
  OTP_TOKEN_NOTMATCH = 51,
  PW_DUPLICATE = 52,
  SMS_ERROR = 70
}
export default ResponseCode
