
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import RouterOption from '@/models/RouterOption'
import LanguageButton from '@/components/commons/LanguageButton.vue'
import CustomerCenterInfo from '@/components/commons/CustomerCenterInfo.vue'
import i18n from '@/plugins/i18n'

@Component({
  components: { LanguageButton, CustomerCenterInfo }
})
export default class NavFooterWrapper extends Vue {
  @Prop(Object) footerNav!: RouterOption
  isKorean: boolean = i18n.locale.includes('ko')
}
