var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.swiperInfos.outerClass},[_c('swiper',{class:_vm.swiperInfos.swiperClass,attrs:{"options":_vm.swiperInfos.swiperOptions}},_vm._l((_vm.swiperInfos.swiperElements),function(element){return _c('swiper-slide',{key:element.underlineClass},[(_vm.swiperInfos.type === 'normal')?_c('div',{staticClass:"swiper-info-wrapper"},[(element.underlineClass === 'fx' || element.underlineClass === 'singapore' || (element.isMultipleLines && _vm.isEnglish))?_c('div',{staticClass:"element-title-wrapper"},[_c('h2',{class:{ [_vm.swiperInfos.titleClass]: true, 'multiple-lines': true},domProps:{"innerHTML":_vm._s(element.titleMobile)}}),_c('hr',{class:{'under-multiple-line': true, [element.underlineClass]: true}})]):_c('div',{staticClass:"element-title-wrapper"},[_c('h2',{class:{ [_vm.swiperInfos.titleClass]: true, 'single-line': true }},[_vm._v(" "+_vm._s(element.title)+" ")])]),_c('p',{class:_vm.swiperInfos.contentClass},[_vm._v(" "+_vm._s(_vm.removeBr(element.content))+" ")])]):(_vm.swiperInfos.type === 'reviewCard')?[_c('ReviewCard',{attrs:{"review-summary":element.title,"review-summary-mobile":element.titleMobile,"review-content":element.content,"review-reviewer":element.reviewer}})]:_vm._e()],2)}),1),(_vm.swiperInfos.swiperOptions.navigation)?_c('span',[_c('img',{class:{
        'prev-icon': true,
        [_vm.swiperInfos.swiperBtnClass]: true
      },attrs:{"src":require("@/assets/images/left-arrow.svg"),"alt":"left"}}),_c('img',{class:{
        'next-icon': true,
        [_vm.swiperInfos.swiperBtnClass]: true
      },attrs:{"src":require("@/assets/images/right-arrow.svg"),"alt":"right"}})]):_vm._e(),(_vm.swiperInfos.swiperOptions.pagination)?_c('div',{class:_vm.swiperInfos.paginationClass,attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }