
import Vue from 'vue'
import Component from 'vue-class-component'
import Chart, { ChartDataSets, ChartOptions } from 'chart.js'
import { Prop, Watch } from 'vue-property-decorator'
import { IRemittanceCharts } from '@/usecase/home/model/IRemittanceCharts'
import { Utils } from '@/static/Utils'

@Component
export default class RemittanceChart extends Vue {
  @Prop(Object) chartData!: IRemittanceCharts
  axisId = {
    count: 'count-axis',
    remittance: 'remittance-axis',
    deposit: 'deposit-axis'
  }
  chart!: Chart

  get countData (): ChartDataSets {
    return {
      type: 'line',
      label: this.$t('home.transfer_graph_count') as string,
      data: this.chartData.count,
      yAxisID: this.axisId.count,
      borderColor: '#55c991',
      order: 0,
      fill: false,
      lineTension: 0
    }
  }

  get remittanceData (): ChartDataSets {
    return {
      type: 'bar',
      label: this.$t('home.transfer_graph_amount_usd') as string,
      data: this.chartData.remittance,
      yAxisID: this.axisId.remittance,
      backgroundColor: '#d2e7ff',
      order: 1
    }
  }

  get depositData (): ChartDataSets {
    return {
      type: 'bar',
      label: this.$t('home.transfer_graph_amount_krw') as string,
      data: this.chartData.deposit,
      yAxisID: this.axisId.deposit,
      backgroundColor: '#4ba7f0',
      order: 2
    }
  }

  renderChart (dateSets: Array<ChartDataSets>) {
    const canvas: any = this.$refs.canvas
    const options: ChartOptions = {
      tooltips: {
        mode: 'x-axis',
        displayColors: true,
        callbacks: {
          label: (tooltipItem, chartData) => {
            if (!tooltipItem) return ''
            if (!chartData || !chartData.datasets) return tooltipItem.yLabel?.toLocaleString() || ''
            const label = chartData.datasets[tooltipItem.datasetIndex || 0].label || ''
            return `${label} : ${tooltipItem.yLabel?.toLocaleString()}`
          }
        }
      },
      legend: {
        align: 'end',
        position: 'bottom'
      },
      scales: {
        yAxes: [
          {
            id: this.axisId.remittance,
            ticks: {
              callback: (value) => Utils.commarize(value)
            }
          },
          {
            id: this.axisId.deposit,
            ticks: {
              callback: (value) => Utils.commarize(value)
            }
          },
          {
            id: this.axisId.count,
            ticks: {
              callback: (value) => Utils.commarize(value)
            },
            position: 'right'
          }
        ]
      }
    }
    this.chart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: {
        labels: this.chartData.period,
        datasets: dateSets
      },
      options: options
    })
  }

  @Watch('chartData')
  setChartData () {
    if (this.chart) this.chart.destroy()
    this.renderChart([
      this.countData,
      this.remittanceData,
      this.depositData
    ])
  }

  mounted () {
    if (this.chart) this.chart.destroy()
    this.setChartData()
  }
}
