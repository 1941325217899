import { sentbizApi } from '../axios'
import { FxRates } from '@/gateway/fxRates/FxRates'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import { CurrencyEnum } from '@/enums/CurrencyEnum'

interface FxRatesParams {
  fx_task_id?: string
}

export class FxRatesApi {
  private static instance: FxRatesApi

  private constructor() {}

  public static getInstance(): FxRatesApi {
    if (!this.instance) this.instance = new FxRatesApi()
    return this.instance
  }

  private convertRateStructure(appliedRates: Dictionary<any>): KrwFxRates {
    const krwRates: KrwFxRates = { id: appliedRates.id }
    appliedRates.rates.forEach((rate: any) => {
      const rateKey = rate.rate_type
      const rateValue = rate.rate
      krwRates[rateKey as keyof KrwFxRates] = rateValue
    })
    return krwRates
  }

  async request(params?: FxRatesParams): Promise<FxRates | KrwFxRates> {
    const url = 'rates'
    const requestOption: RequestOption = {
      url,
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }

  async midRateRequest(params?: FxRatesParams): Promise<KrwFxRates> {
    const url = 'mid-rates'
    const requestOption: RequestOption = { url, method: 'get', params }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }

  async appliedRateRequest(): Promise<KrwFxRates> {
    const url = 'applied-rates'
    const requestOption: RequestOption = { url, method: 'get' }
    const response = await sentbizApi.request(requestOption)
    return this.convertRateStructure(response.data)
  }
}

export class GetFxRates {
  private static ratesMap: Map<string, FxRates> = new Map<string, FxRates>()
  private static krwMidRatesMapV3: Map<string, KrwFxRates> = new Map<string, KrwFxRates>()
  private static krwAppliedRatesMapV4: Map<string, KrwFxRates> = new Map<string, KrwFxRates>()

  private static calculateAppliedRateFromMidRate(midKrwRates: KrwFxRates, rateFee: number): KrwFxRates {
    const appliedRates: KrwFxRates = { id: midKrwRates.id }
    Object.keys(midKrwRates).forEach(rateKey => {
      const rateValue = midKrwRates[rateKey as keyof KrwFxRates]
      if (!rateKey.toLowerCase().includes('krw')) return
      const appliedRate: number = Number(rateValue) * (1 + rateFee)
      appliedRates[rateKey as keyof KrwFxRates] = appliedRate.toString()
    })
    return appliedRates
  }

  public static async get(fxTaskId?: string): Promise<FxRates> {
    if (fxTaskId && this.ratesMap.has(fxTaskId)) return this.ratesMap.get(fxTaskId) as FxRates
    const fxRatesParam: FxRatesParams = fxTaskId ? { fx_task_id: fxTaskId } : {}
    const fxRates: FxRates = await FxRatesApi.getInstance().request(fxRatesParam)
    this.ratesMap.set(fxRates.id, fxRates)
    return fxRates
  }

  public static async getKrwMidRatesV3(fxTaskId?: string): Promise<KrwFxRates> {
    if (fxTaskId && this.krwMidRatesMapV3.has(fxTaskId)) return this.krwMidRatesMapV3.get(fxTaskId) as KrwFxRates
    const fxRatesParam: FxRatesParams = fxTaskId ? { fx_task_id: fxTaskId } : {}
    const krwFxRates: KrwFxRates = await FxRatesApi.getInstance().midRateRequest(fxRatesParam)
    this.krwMidRatesMapV3.set(krwFxRates.id, krwFxRates)
    return krwFxRates
  }

  public static async getKrwAppliedRatesV4(
    fxTaskId?: string,
    feeRate?: { currency: keyof typeof CurrencyEnum; rate_fee: number }
  ): Promise<KrwFxRates> {
    if (!(fxTaskId && feeRate)) {
      if (fxTaskId && this.krwAppliedRatesMapV4.has(fxTaskId))
        return this.krwAppliedRatesMapV4.get(fxTaskId) as KrwFxRates
      const krwFxRates = await FxRatesApi.getInstance().appliedRateRequest()
      this.krwAppliedRatesMapV4.set(krwFxRates.id, krwFxRates)
      return krwFxRates
    }
    const appliedFxTaskId = `${fxTaskId}-${feeRate.currency}`
    if (fxTaskId && this.krwAppliedRatesMapV4.has(appliedFxTaskId))
      return this.krwAppliedRatesMapV4.get(appliedFxTaskId) as KrwFxRates
    const krwFxRates: KrwFxRates = await this.getKrwMidRatesV3(fxTaskId)
    const appliedKrwFxRatesV4 = this.calculateAppliedRateFromMidRate(krwFxRates, feeRate.rate_fee as number)
    this.krwAppliedRatesMapV4.set(appliedFxTaskId, appliedKrwFxRatesV4)
    return appliedKrwFxRatesV4
  }
}
