import { sentbizApiWithoutAuth } from '@/gateway/axios'
import { IApiRequest } from '@/gateway/axios/IApiRequest'

export interface RegisterInquiryParam {
  corp_name: string;
  name: string;
  email: string;
  phone_number: string;
  content: string;
  remittance_purpose: string;
  sign_up_path: string;
  license_number: string;
  receive_country: string;
  receive_currency: string;
  remittance_volume: string;
}

export interface RegisterInquiry extends IApiRequest {
  getInstance (): RegisterInquiry;

  request (data: RegisterInquiryParam): Promise<any>;
}

export class RegisterInquiry implements RegisterInquiry {
  private static instance: RegisterInquiry

  private constructor () {}
  public static getInstance (): RegisterInquiry {
    if (!this.instance) this.instance = new RegisterInquiry()
    return this.instance
  }

  async request (data?: RegisterInquiryParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'notify/inquiry',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }
}
