import { CertDocumentPdfHandler } from '@/lib/pdf/cert/CertDocumentPdfHandler'
import { StatusType } from '@/enums'
import { formattersMap } from '@/lib/Utils'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import dayjs from 'dayjs'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { RemittanceHistoryGroup } from '@/models/remittance'

export interface CurrencyBalance {
  amount: number
  amountKrw: number
  feeKrw: number
  subTotalAmountKrw: number
  transactionNo: number
}

export interface TransactionCurrencyReceiptParam {
  corpInfo: CorpInformation
  currenciesBalance: Dictionary<CurrencyBalance>
  krwFxRates: KrwFxRates
  remittanceGroup: RemittanceHistoryGroup
  fxTaskId: string
  statusType: StatusType | Array<StatusType>
}

export class TransactionReceiptCurrencyHandler extends CertDocumentPdfHandler {
  private readonly transactionReceiptParam: TransactionCurrencyReceiptParam
  private readonly transactionsContent: Array<Dictionary<any>>
  private readonly textContents: Dictionary<any>
  private grandTotal: number = 0

  constructor(transaction: TransactionCurrencyReceiptParam) {
    super('landscape')
    this.transactionReceiptParam = transaction
    this.transactionsContent = this.makeTransactionContents()
    this.textContents = this.makeTextContents()
  }

  private makeTransactionContents(): Array<Dictionary<any>> {
    const currenciesBalance = this.transactionReceiptParam.currenciesBalance
    const createdAt = dayjs(this.transactionReceiptParam.remittanceGroup.created_at)
    const krwFxRates = this.transactionReceiptParam.krwFxRates
    const statusType = this.transactionReceiptParam.statusType
    return Object.keys(currenciesBalance).map(currency => {
      const krwFxRatesKey: keyof KrwFxRates = `${currency.toLowerCase()}_krw` as keyof KrwFxRates
      const rate: number = currency.toLowerCase() === 'krw' ? 1 : parseFloat(krwFxRates[krwFxRatesKey] as string)
      const dataForTransaction = currenciesBalance[currency]
      this.grandTotal += dataForTransaction.subTotalAmountKrw
      const transaction: Dictionary<any> = {
        Date: createdAt.format('YYYY-MM-DD'),
        Time: createdAt.format('HH:mm'),
        'No. Transactions': dataForTransaction.transactionNo.toString(),
        Currency: currency
      }
      if (statusType === StatusType.REFUNDED)
        transaction['Refund Amount'] = formattersMap.number(dataForTransaction.amount)
      else transaction['Amount'] = formattersMap.number(dataForTransaction.amount)
      transaction['FX Rate'] = formattersMap.number(rate.toFixed(2))
      transaction['Amount (KRW)'] = formattersMap.number(dataForTransaction.amountKrw)
      transaction['Fee (KRW)'] = formattersMap.number(dataForTransaction.feeKrw)
      transaction['Sub Total Amount (KRW)'] = formattersMap.number(dataForTransaction.subTotalAmountKrw)
      return transaction
    })
  }

  private makeTextContents(): Dictionary<any> {
    const createdAt = dayjs(this.transactionReceiptParam.remittanceGroup.created_at)
    const statusType = this.transactionReceiptParam.statusType
    const corpInfo = this.transactionReceiptParam.corpInfo
    const askText =
      statusType === StatusType.REFUNDED ? 'Refund process could take maximum 1 business day from the issuing date' : ''
    const today: string = dayjs().format('YYYY-MM-DD')
    return {
      title: (statusType !== StatusType.REFUNDED ? 'Transaction' : 'Refund') + ' Receipt',
      issueInfo: {
        'Date of issue': today,
        To: corpInfo.corp_name,
        'Transaction Group Number': this.transactionReceiptParam.remittanceGroup.code
      },
      grandTotal: `Grand Total: ${formattersMap.number(this.grandTotal)} (KRW)`,
      askText,
      bankInfo: {
        'Refund Account': statusType !== StatusType.REFUNDED ? undefined : ' ',
        'Bank Name': corpInfo.bank_account_name,
        'Account Number': corpInfo.bank_account_number
      },
      fileName:
        statusType !== StatusType.REFUNDED
          ? `${createdAt.format('YYYYMMDD')}_deposit_currency_receipt.pdf`
          : `${createdAt.format('YYYYMMDD')}_refund_currency_receipt.pdf`
    }
  }

  private getAutoTableOption(tableList: any): Dictionary<any> {
    const tableOptions: Dictionary<any> = {
      startY: this.getNextPositionY(0),
      styles: { halign: 'right', fontSize: 10, cellPadding: 1 }
    }
    const createdTableOptions = this.createdTableHeaderAndBody(tableList)
    tableOptions.head = createdTableOptions.head
    tableOptions.body = createdTableOptions.body
    return tableOptions
  }

  async printDocument(): Promise<void> {
    await this.printLogoHeader()

    // Title
    const title = this.textContents.title
    this.doc.setFontSize(18)
    this.doc.text(title, this.getAlignCenterPositionX(title) + 5, this.getNextPositionY(1))

    // to from text
    const issueInfoTexts = this.textContents.issueInfo
    this.finalY = this.printTextObject(issueInfoTexts, 10, this.getNextPositionY(0.5))

    // first page table
    const firstPageLimit = 15
    const firstPageList = this.transactionsContent.splice(0, firstPageLimit)
    const autoTableOption = this.getAutoTableOption(firstPageList)
    this.doc.autoTable(autoTableOption)

    // grand total
    const distanceRate = 0.6
    const distanceFromTable = firstPageList.length * distanceRate + distanceRate * 2.5
    const totalText = this.textContents.grandTotal
    this.doc.setFontSize(13)
    this.doc.text(totalText, 210, this.getNextPositionY(distanceFromTable))

    // bankInfo contents
    this.finalY = this.pageStandard.height - 35
    const askText = this.textContents.askText
    this.doc.setFontSize(11)
    this.doc.text(askText, 10, this.getNextPositionY(0))
    const bankInfoText = this.textContents.bankInfo
    const lineHeight = 2.5
    this.printTextObject(bankInfoText, 10, this.getNextPositionY(), 10, lineHeight)

    // footer text
    const footerText = 'This is a computer-generated this.document. No signature is required.'
    this.finalY = this.pageStandard.height - 5
    this.doc.text(footerText, this.getAlignCenterPositionX(footerText) + 55, this.getNextPositionY(0))

    this.doc.save(this.textContents.fileName)
    // window.open(URL.createObjectURL(this.doc.output('blob', { filename: this.textContents.fileName })))
  }
}
