import { sentbizApi } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'

export interface UpdateCorpMemberLocaleParam {
  id: number;
  locale: string;
}

export interface UpdateCorpMemberLocale extends IApiRequest {
  getInstance (): UpdateCorpMemberLocale;
  request (data: UpdateCorpMemberLocaleParam): Promise<any>;
}

export class UpdateCorpMemberLocale implements UpdateCorpMemberLocale {
  private static instance: UpdateCorpMemberLocale

  private constructor () {}

  public static getInstance (): UpdateCorpMemberLocale {
    if (!this.instance) this.instance = new UpdateCorpMemberLocale()
    return this.instance
  }

  async request (data: UpdateCorpMemberLocaleParam): Promise<any> {
    if (!data.locale) data.locale = 'KO'
    const requestOption: RequestOption = {
      url: 'corp-member/locale',
      method: 'put',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
