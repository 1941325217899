export const koEnum: Dictionary<string> = {
  // Country
  AT: '오스트리아',
  AU: '호주',
  BD: '방글라데시',
  BE: '벨기에',
  BG: '불가리아',
  CA: '캐나다',
  CN: '중국',
  CY: '사이프러스',
  DE: '독일',
  DK: '덴마크',
  EE: '에스토니아',
  ES: '스페인',
  FI: '핀란드',
  FR: '프랑스',
  GB: '영국',
  HK: '홍콩',
  ID: '인도네시아',
  IE: '아일랜드',
  IN: '인도',
  IT: '이탈리아',
  JP: '일본',
  KG: '키르기스탄',
  KH: '캄보디아',
  KR: '대한민국',
  KZ: '카자흐스탄',
  LA: '라오스',
  LK: '스리랑카',
  LT: '리투아니아',
  LU: '룩셈부르크',
  LV: '라트비아',
  MC: '모나코',
  MN: '몽골',
  MT: '몰타',
  MY: '말레이시아',
  NL: '네덜란드',
  NP: '네팔',
  PH: '필리핀',
  PK: '파키스탄',
  PL: '폴란드',
  PT: '포르투갈',
  RU: '러시아',
  SA: '사우디아라비아',
  SE: '스웨덴',
  SG: '싱가포르',
  TH: '태국',
  TJ: '타지키스탄',
  TR: '터키',
  UA: '우크라이나',
  US: '미국',
  UZ: '우즈베키스탄',
  VN: '베트남',
  // AffiliatedDocumentType
  INTRODUCE_ENTREPRENEUR: '서류 안내_개인사업자',
  INTRODUCE_CORPERATION: '서류 안내_법인사업자',
  USAGE_TERMS: '이용약관',
  USAGE_AGREEMENT: '센트비즈 이용약정서',
  TRADE_CONFIRMATION: '고객거래 확인서',
  PRIVACY_AGREEMENT: '개인정보등 활용 필수적 동의서',
  BUSINESS_REGISTRATION: '사업자 등록',
  OWNER_ID_CARD: '대표자 신분증',
  INVOICE_CONTRACT: '인보이스, 계약서 등 경상거래를 입증하는 자료',
  CORP_ACCOUNT: '정산 받으실 입금계좌 (법인)',
  CORP_CERTIFIED: '법인 등기부등본',
  CORP_SIGNATURE: '법인 인감증명서',
  CORP_SHAREHOLDERS: '주주명부',
  ENTRE_ACCOUNT: '정산 받으실 입금계좌 (개인)',
  ENTRE_SIGNATURE: '개인 인감증명서',
  // BusinessCategory
  CORPORATE_BUSINESS: '법인 사업자',
  ENTREPRENEUR: '개인 사업자',
  // BusinessDivision
  LARGE: '대기업',
  MEDIUM: '중견기업',
  SMALL: '중소기업',
  PUBLIC: '공기업',
  ORGANIZATION: '정부/비영리조직',
  FINANCE: '금융기관',
  ETC: '기타',
  // StockInfo
  PRIVATE: '비상장',
  KOSPI: '코스피',
  KOSDAQ: '코스닥',
  // FundSource
  BUSINESS: '사업소득',
  REALTY: '부동산 소득',
  PENSION: '연금 소득',
  FINANCE_SALE: '금융 소득(이자 및 배당)',
  INHERITANCE: '상속/증여',
  SEVERANCE: '퇴직 소득',
  REALTY_SALE: '부동산 등 유형 자산 처분',
  STOCK_SALE: '유가 증권 등 투기 자산 처분',
  // TransactionPurpose
  CALCULATE: '경상거래대금 정산 대행'
}
