import FormItemsOption from '@/models/forms/FormItemsOption'
import { FormValidator } from '@/presentation/FormValidator'
import { Form, IFormView } from '@/presentation/IFormView'
import { OwnerInfoField } from '@/presentation/affiliate/OwnerInfoField'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import store from '@/store'
import { ShareHolderStatus } from '@/presentation/affiliate/ShareHolderInformationField'
import { UpdateCorpAll } from '@/gateway/affiliate/UpdateCorpAll'
import FormRule from '@/models/forms/FormRule'

export interface OwnerInfo extends IFormView {
  formValidator: FormValidator;

  isEnable (): boolean;
  isActivate (): boolean;

  subButtonLabel: string;
  onClickSubButton (): Promise<void>;
  onSelectFalseOwner (): void;
  disabledSubmit: boolean;
}

export class OwnerInfo implements OwnerInfo {
  private corpAll: CorpAll
  constructor () {
    this.corpAll = store.getters.getCorpAllState
    this.field = {
      share_holder_status: this.corpAll.share_holder_status
    }
    this.fieldInitialize()
  }
  private fieldInitialize (): void {
    this.onSelectFalseOwner()
  }

  formValidator: FormValidator = new FormValidator()
  formName = 'ownerDocument'
  formClass = 'divided-form'

  field: OwnerInfoField

  rules: Dictionary<Array<FormRule>> = {
    share_holder_status: [{ required: true }]
  }

  private ownerInfoTitle = '사업체 실제 소유자 정보 (개인사업자)'
  private ownerInfoFormOptions: Array<FormItemsOption> = [
    {
      prop: 'share_holder_status',
      label: '사업체의 실제 소유자입니까?',
      type: 'radio',
      options: [
        { label: '예', value: ShareHolderStatus.OWNER.toString() },
        { label: '아니오', value: ShareHolderStatus.NOT_OWNER.toString() }
      ],
      value: undefined,
      emitHandlerName: 'onSelectFalseOwner'
    }
  ]

  forms: Array<Form> = [
    {
      subTitle: this.ownerInfoTitle,
      formItemOptions: this.ownerInfoFormOptions
    }
  ]

  isEnable (): boolean {
    return !!this.field.share_holder_status
  }

  isActivate (): boolean {
    const isProcessing = this.corpAll.status === Number(CorpStatus.FORM_OWNER_PROCESSING)
    return isProcessing
  }

  onSelectFalseOwner (): void {
    this.disabledSubmit = Number(this.field.share_holder_status) === Number(ShareHolderStatus.NOT_OWNER)
  }

  private setCorpAll () {
    this.corpAll = store.getters.getCorpAllState
    this.corpAll = Object.assign(this.corpAll, this.field)
    this.corpAll.status = CorpStatus.FORM_OWNER_PROCESSING
    store.commit('setCorpAllState', this.corpAll)
  }

  private async updateCorpAll () {
    const updateCorpAll: UpdateCorpAll = UpdateCorpAll.getInstance()
    await updateCorpAll.request(this.corpAll)
  }

  subButtonLabel = '임시 저장'
  async onClickSubButton (): Promise<void> {
    this.setCorpAll()
    await this.updateCorpAll()
  }

  disabledSubmit = false
  submitLabel = '문서 작성 완료'
  async onSubmit (): Promise<void> {
    this.setCorpAll()
    this.corpAll.status = CorpStatus.FORM_COMPLETE
    store.commit('setCorpAllState', this.corpAll)
    await this.updateCorpAll()
  }
}
