import { formattersMap } from '@/lib/Utils'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import i18n from '@/plugins/i18n'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { ReceiveMethodEnum } from '@/enums'

export interface CountryCode {
  name: string,
  name_korean: string,
  phone_code: number,
  iso: string
}
export class Utils {
  public static isValidEnumKey (enumKey: string, enumerator: any): boolean {
    return Object.keys(enumerator).some(() => {
      return !isNaN(enumerator[enumKey.toUpperCase()])
    })
  }

  public static get isKoreanLocale (): boolean {
    return localStorage.getItem('locale') !== 'en'
  }

  public static currencyToCountry (currency: keyof typeof CurrencyEnum): string {
    return currency.substr(0, 2).toUpperCase()
  }

  public static getCountryMethodLabel (countryWithMethod: string): string {
    const translated = formattersMap.countryWithMethod(countryWithMethod)
    const isTranslated = !translated.includes('.')
    if (isTranslated) return translated
    const country = countryWithMethod.split('_')[0]
    const otherCountry = CountryPhoneCodes.find(countryPhone => countryPhone.iso === country.toUpperCase())
    const otherCountryName = Utils.isKoreanLocale ? otherCountry?.name_korean : otherCountry?.name
    return otherCountryName || country.toUpperCase()
  }

  public static getCountryLabel (country: string): string {
    const translated = i18n.t(`country.${country}`) as string
    const isTranslated = !translated.includes('.')
    if (isTranslated) return translated
    const otherCountry = CountryPhoneCodes.find(countryPhone => countryPhone.iso === country.toUpperCase())
    const otherCountryName = Utils.isKoreanLocale ? otherCountry?.name_korean : otherCountry?.name
    return otherCountryName || country.toUpperCase()
  }

  private static countryCodes: Array<CountryCode> = []
  public static sortedCountryCodes (): Array<CountryCode> {
    if (this.countryCodes.length) return this.countryCodes
    const labelProp = Utils.isKoreanLocale ? 'name_korean' : 'name'
    this.countryCodes = CountryPhoneCodes.sort((a, b) => {
      return a[labelProp] < b[labelProp] ? -1 : (a[labelProp] > b[labelProp] ? 1 : 0)
    })
    return this.countryCodes
  }

  public static commarize (input: number | string): string {
    if (isNaN(Number(input))) return input.toString()
    const inputNumber = Number(input)
    if (inputNumber < 1e3) return inputNumber.toLocaleString()
    const units = ['k', 'M', 'B', 'T']
    let unit = Math.floor(((inputNumber).toFixed(0).length - 1) / 3) * 3
    const num = (inputNumber / Number('1e' + unit)).toFixed()
    const unitString = units[Math.floor(unit / 3) - 1]
    return `${num} ${unitString}`
  }
  
  public static splitCountryAndMethod (countryWithMethod: string): {country: string, method: keyof typeof ReceiveMethodEnum, currency:"USD" | null} {
    const splitCountryWithMethod = countryWithMethod.split('_')
    const country = splitCountryWithMethod[0]
    const ExceptCountry = countryWithMethod.replace(`${country}_`, '')
    
    let method: keyof typeof ReceiveMethodEnum
    let currency: 'USD'| null = null
  
    if (ExceptCountry.includes('USD')){
      if( ExceptCountry === 'USD_ANYWHERE'){
        method = ExceptCountry
      }
      else{
        method = ExceptCountry.replace('USD_', '') as keyof typeof ReceiveMethodEnum
        currency = 'USD'
      }
    }
    else{
      method = ExceptCountry as keyof typeof ReceiveMethodEnum
    }
  
    return {
      country,
      method,
      currency
    }
  }
}
