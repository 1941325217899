
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RemittanceHistoryCount, RemittanceHistoryGroup, RemittanceHistoryGroupDetail } from '@/models/remittance'
import dayjs from 'dayjs'
import { StatusType } from '@/enums'
import { DEPOSIT_WITHIN_HOURS } from '@/data/TransferConstants'
import {
  ITransferHistoryComponentPresentation,
  TransferHistoryComponentPresentation
} from '@/presentation/transferHIstory/TransferHistoryComponentPresentation'

@Component
export default class TransferHistory extends Vue {
  @Prop(Array) readonly list!: Array<RemittanceHistoryGroup>
  @Prop(Array) readonly detailList!: Array<RemittanceHistoryGroupDetail>
  @Prop(Boolean) readonly showDialog!: boolean
  @Prop(Number) readonly total!: number
  @Prop(Number) readonly page!: number
  @Prop(Object) readonly period!: { from: string, to: string }

  transferHistoryComponentPresentation: ITransferHistoryComponentPresentation = new TransferHistoryComponentPresentation()

  refundedStatusType: number = StatusType.REFUNDED
  doneStatusType: Array<number> = [
    StatusType.DONE, StatusType.INPROGRESS, StatusType.WAITING_TRANSFER,
    StatusType.RECEIVABLE, StatusType.REFUNDED, StatusType.WAITING_RECEIVE,
    StatusType.FAILED_TRANSFER
  ]

  get waitingForDepositList (): Array<RemittanceHistoryGroup> {
    const DEPOSIT_WAIT_STATUS_CODE = 1
    return this.list.filter(item => item.group_status === DEPOSIT_WAIT_STATUS_CODE)
  }

  get excludeWaitingDepositList (): Array<RemittanceHistoryGroup> {
    const DEPOSIT_WAIT_STATUS_CODE = 1
    return this.list.filter(item => item.group_status !== DEPOSIT_WAIT_STATUS_CODE)
  }

  get showDepositMoney (): boolean {
    return (!this.showDialog && !this.shownDetails) && this.waitingForDepositList.length > 0
  }

  get usableRefundCertificate (): boolean {
    if (!this.detailList.length) return false
    const usableStatus = [StatusType.REFUNDED, StatusType.DONE, StatusType.EXPIRED, StatusType.FAILED_TRANSFER, StatusType.CANCELED, StatusType.UNKNOWN_TRANSFER_STATE_LABEL]
    return this.detailList.every(item => usableStatus.includes(item.status_type)) &&
      this.detailList.some(item => item.status_type === StatusType.REFUNDED)
  }

  get usableTransferCertificate (): boolean {
    if (!this.detailList.length) return false
    const usableStatus = [
      StatusType.WAITING_DEPOSIT, StatusType.UNKNOWN_TRANSFER_STATE_LABEL, StatusType.CANCELED, StatusType.EXPIRED
    ]
    return !this.detailList.every(item => usableStatus.includes(item.status_type))
  }

  getCount (count: RemittanceHistoryCount): string {
    const sum = `${this.$t('commons.total_case', { total_case: count.total_count })}`
    const details = Object.keys(this.remittanceStatusLabels).reduce((accu: Array<string>, prop) => {
      count[prop] && accu.push(`${this.remittanceStatusLabels[prop]} : ${count[prop]}`)
      return accu
    }, [])
    const detail = details.length ? ` (${details.join(', ')})` : ''
    return `${sum}${detail}`
  }

  getDateFormat (date: Date): string {
    return dayjs(new Date(date)).format('YYYY-MM-DD')
  }

  getDateAndTimeFormat (date: Date): string {
    return dayjs(new Date(date)).format('YYYY-MM-DD HH:mm')
  }

  depositDeadline (date: Date): string {
    return dayjs(new Date(date)).add(DEPOSIT_WITHIN_HOURS, 'hour').format('YYYY-MM-DD HH:mm')
  }

  get remittanceStatusLabels (): { [key: string]: string } {
    const labels: { [key: string]: string } = {
      failed_count: `${this.$t('commons.transfer_status.failed_transfer')}`,
      inprogress_count: `${this.$t('commons.transfer_status.inprogress')}`,
      completed_count: `${this.$t('commons.transfer_status.done')}`,
      refunded_count: `${this.$t('commons.transfer_status.refunded')}`
    }
    return labels
  }

  get shownDetails (): string | (string | null)[] {
    return this.$route.query.details
  }

  showDetails (id: string): void {
    const { path } = this.$route
    let query = {
      details: id,
      page: `${this.page}`
    }
    query = Object.assign(query, this.period)
    const route = this.$router.resolve({ path, query })
    window.open(route.href, '_blank')
    this.$emit('close')
  }

  changePage (val: number): void {
    this.$emit('change', val)
  }

  cancelRemittance (id: string): void {
    this.transferHistoryComponentPresentation.cancelRemittance(id, this)
  }

  async created (): Promise<void> {
    await this.transferHistoryComponentPresentation.getVirtualAccount()
  }
}
