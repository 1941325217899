
import i18n from '@/plugins/i18n'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class MainHeader extends Vue {
  currentLanguage: string = i18n.locale.slice(0, 2)

  get version(): string {
    return `${process.env.NODE_ENV}-${process.env.VUE_APP_VERSION || ''}`
  }

  languageChanged(): void {
    localStorage.setItem('locale', this.currentLanguage)
    location.reload()
  }

  scrollTo(target: string) {
    this.$emit('scrollTo', target)
  }
}
