import { sentbizApi } from '@/gateway/axios'
import { RemittanceSummarySeries } from '@/gateway/dashboard/model/RemittanceSummarySeries'

export enum DateFilterType {
  DAY = 1,
  MONTH,
  TRANSFER_DATE = 100 // Client only
}

export interface GetRemittanceSummarySeriesParam {
  corp_id: string;
  date_filter_type: DateFilterType;
  from: Date;
  to: Date;
}

export class GetRemittanceSummarySeries {
  private static instance: GetRemittanceSummarySeries

  private constructor () {}
  public static getInstance (): GetRemittanceSummarySeries {
    if (!this.instance) this.instance = new GetRemittanceSummarySeries()
    return this.instance
  }

  async request (data: GetRemittanceSummarySeriesParam): Promise<Array<RemittanceSummarySeries>> {
    const requestOption: RequestOption = {
      url: 'snapshot/remittance/series',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.series
  }
}
