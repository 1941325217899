import Vue from 'vue'
import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { CorpAll } from '@/gateway/affiliate/model/CorpAll'
import dayjs from 'dayjs'

export interface UpdateCorpAll extends IApiRequest {
  getInstance(): UpdateCorpAll
  request(data: CorpAll): Promise<CorpAll>
}

export class UpdateCorpAll implements UpdateCorpAll {
  private vue = new Vue()
  private static instance: UpdateCorpAll
  private constructor() {}
  public static getInstance(): UpdateCorpAll {
    if (!this.instance) this.instance = new UpdateCorpAll()
    return this.instance
  }

  async request(data: CorpAll): Promise<CorpAll> {
    data.ceo_information_list?.forEach(ceoInfo => {
      if (!ceoInfo.identification_type) return
      ceoInfo.identification_type = ceoInfo.identification_type.toString()
    })
    if ('founded_at' in data) {
      data.founded_at = dayjs(data.founded_at).format()
    }

    delete data.line1Detail

    const requestOption: RequestOption = {
      url: 'corp',
      method: 'put',
      data
    }


    const response: any = await sentbizApi.request(requestOption)
    const isSuccess = response.code === 200
    if (isSuccess) this.vue.$message({ message: '저장 되었습니다.', type: 'success' })
    return response?.data
  }
}
