import { WalletActivity } from '@/models/wallet/WalletActivity'
import { GetWalletActivitiesParam } from './model/GetWalletActivitiesParam'
import { sentbizApiWithoutOverlay } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'

export interface GetWalletActivities extends IApiRequest {
    getInstance (): GetWalletActivities;
    request (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>>;
}

export class GetWalletActivities implements GetWalletActivities {
    private static instance: GetWalletActivities

    private constructor () {}

    public static getInstance (): GetWalletActivities {
      if (!this.instance) this.instance = new GetWalletActivities()
      return this.instance
    }

    async request (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>> {
      const requestOption: RequestOption = {
        url: 'wallet/activity',
        method: 'post',
        data: params
      }
      const response = await sentbizApiWithoutOverlay.request(requestOption)
      return response.data.list
    }
}
