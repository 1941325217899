import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { addressApi } from '@/gateway/axios'
import { AddressField } from '@/presentation/address/AddressField'

export interface AddressResponse {
  admCd: string;
  bdKdcd: string;
  bdMgtSn: string;
  bdNm: string;
  buldMnnm: string;
  buldSlno: string;
  detBdNmList: string;
  emdNm: string;
  emdNo: string;
  korAddr?: string;
  engAddr?: string;
  jibunAddr: string;
  liNm: string;
  lnbrMnnm: string;
  lnbrSlno: string;
  mtYn: string;
  rn: string;
  rnMgtSn: string;
  roadAddr: string;
  roadAddrPart1: string;
  roadAddrPart2: string;
  sggNm: string;
  siNm: string;
  udrtYn: string;
  zipNo: string;
}

export interface GetAddress extends IApiRequest {
  getInstance (): GetAddress;

  request (params: AddressField): Promise<Array<AddressResponse>>;
}

export class GetAddress implements GetAddress {
  private static instance: GetAddress
  private constructor () {}
  public static getInstance (): GetAddress {
    if (!this.instance) this.instance = new GetAddress()
    return this.instance
  }

  async request (params: AddressField): Promise<Array<AddressResponse>> {
    const requestOption: RequestOption = {
      url: 'juso-go-kr',
      method: 'post',
      data: params
    }
    const response: any = await addressApi.request(requestOption)
    return response.data
  }
}
