import { CertDocumentPdfHandler } from '@/lib/pdf/cert/CertDocumentPdfHandler'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { RemittanceHistoryGroupDetail } from '@/models/remittance'
import { FxRates } from '@/gateway/fxRates/FxRates'
import dayjs from 'dayjs'
import { formattersMap } from '@/lib/Utils'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { MasterCode } from '@/gateway/sheet/MasterCode'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'
import { malgunBase64Font } from '@/assets/malgun-base64'

export interface TransferConfirmationParam {
  corpInfo: CorpInformation
  remittance: RemittanceHistoryGroupDetail
  fxRates: FxRates
}

export class TransferConfirmationHandler extends CertDocumentPdfHandler {
  private transfer: Array<Dictionary<any>>
  private readonly textContents: Dictionary<string>
  private readonly transferConfirmationParam: TransferConfirmationParam
  constructor(transferConfirmationParam: TransferConfirmationParam) {
    super('landscape')
    this.transferConfirmationParam = transferConfirmationParam
    this.transfer = this.makeTransferConfirmationTable()
    this.textContents = this.makeTextContents()
  }

  private makeTransferConfirmationTable(): Array<Array<string>> {
    const remittance: RemittanceHistoryGroupDetail = this.transferConfirmationParam.remittance
    const createdAt: dayjs.Dayjs = dayjs(remittance.created_at)
    const fxRates: FxRates = this.transferConfirmationParam.fxRates
    const corpInfo: CorpInformation = this.transferConfirmationParam.corpInfo
    const calculatorSource: ICalculatorSource = {
      type: CalculatorSourceType.TRANSFER_CONFIRMATION,
      remittanceHistoryGroupDetail: remittance
    }
    const calculator: Calculator = new Calculator(fxRates)
    calculator.setSource(calculatorSource)
    const calculated: CalculateResult = calculator.calculate()
    const baseAmountBalance: string = formattersMap.number(calculated.baseAmount)
    const masterCodeLabels: Dictionary<string> = MasterCode.getMasterCodeLabels()
    let recipientBankInfo: string = masterCodeLabels[remittance.recipient.master_code]

    return [
      ['Transaction Date and Time', createdAt.format('YYYY-MM-DD hh:mm A')],
      ['Transaction Number', remittance.id],
      ['Sender', corpInfo.corp_name],
      ['Amount', `${remittance.base_amount.currency} ${baseAmountBalance}`],
      ['Beneficiary', `${remittance.recipient.first_name} ${remittance.recipient.last_name}`],
      ['Beneficiary Bank', recipientBankInfo],
      ['Beneficiary Account', `${remittance.recipient.remittance_method_data.bank_account_number}`]
    ]
  }

  private makeTextContents(): Dictionary<any> {
    const remittance = this.transferConfirmationParam.remittance
    const paymentCompletedAt = dayjs(remittance.payment_completed_at)
    return {
      address:
        '15F, 431 Teheran-ro, Gangnam-gu, Seoul, Republic of Korea',
      title: 'Transfer Confirmation',
      issueInfo: {
        'Date and Time of inquiry': dayjs().format('YYYY-MM-DD hh:mm A')
      },
      fileName: `${paymentCompletedAt.format('YYYYMMDD')}_${remittance.id}_transfer_confirmation.pdf`
    }
  }

  private getAutoTableOption(tableList: any): Dictionary<any> {
    return {
      startY: this.getNextPositionY(1),
      margin: 58.5,
      theme: 'plain',
      styles: { halign: 'center', valign: 'middle', fontSize: 12, cellPadding: 2.5, lineWidth: 0.1 },
      columnStyles: {
        0: { cellWidth: 75, halign: 'left' },
        1: { cellWidth: 105, halign: 'left' }
      },
      body: this.createdTableHeaderAndBody(tableList).body
    }
  }

  async printDocument(): Promise<void> {
    await this.printLogoHeader()

    this.doc.addFileToVFS('malgun.ttf', malgunBase64Font);
    this.doc.addFont('malgun.ttf', 'malgun', 'normal')
    this.doc.setFont('malgun')
    
    const address: string = this.textContents.address
    this.doc.setFontSize(10)
    this.doc.text(address, this.getAlignCenterPositionX(address) + 225, this.getNextPositionY(-1), { align: 'right' })

    // Title
    const title = this.textContents.title
    this.doc.setFontSize(18)
    this.doc.text(title, this.getAlignCenterPositionX(title) + 5, this.getNextPositionY(3))

    // IssueInfo
    const issueInfoTexts = this.textContents.issueInfo
    this.finalY = this.printTextObject(issueInfoTexts, 200, this.getNextPositionY(1))

    // first page table
    const firstPageLimit = 15
    const firstPageList = this.transfer.splice(0, firstPageLimit)
    const autoTableOption = this.getAutoTableOption(firstPageList)
    autoTableOption.styles = {
      ...autoTableOption.styles,
      font: 'malgun'
    }
    this.doc.autoTable(autoTableOption)

    // bankInfo contents
    this.finalY = this.pageStandard.height - 65
    this.doc.setFontSize(11)

    const footerMessage: string =
      'This statement is provided for your convenience and may only be used for reference purposes.'
    this.printFooter(footerMessage, 65)

    this.doc.save(this.textContents.fileName)
    // window.open(URL.createObjectURL(this.doc.output('blob', { filename: this.textContents.fileName })))
  }
}
