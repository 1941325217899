import { CountryEnum } from '@/enums'
import { GetRemittanceBase } from '@/gateway/remittanceBase/GetRemittanceBase'
import i18n from '@/plugins/i18n'

export class ReceivableCountries {
  private static receivableCountries: Array<keyof typeof CountryEnum> = []
  public static async get (): Promise<Array<keyof typeof CountryEnum>> {
    if (!this.receivableCountries.length) {
      const remittanceBaseList = await GetRemittanceBase.getInstance().request()
      const hasRemittanceBaseList = remittanceBaseList.length
      if (!hasRemittanceBaseList) return []
      this.receivableCountries = remittanceBaseList.map(remittanceBase => {
        return remittanceBase.country
      })
    }
    return this.receivableCountries
  }

  private static availableCountriesIso: Array<string> = []
  public static async getNameOfCountries (): Promise<Array<string>> {
    if (!this.availableCountriesIso.length) {
      const remittanceBaseCountries = await ReceivableCountries.get()
      const translatedCountries = remittanceBaseCountries.map(country => {
        const translatedCountry = i18n.t(`country.${country.toLowerCase()}`) as string
        return translatedCountry.includes('country.') ? country.toUpperCase() : translatedCountry
      })
      this.availableCountriesIso = translatedCountries.sort((nameA, nameB) => {
        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0)
      })
    }
    return this.availableCountriesIso
  }

  private static isoWithName: Array<{ iso: string, name: string }> = []
  public static async getIsoWithName () : Promise<Array<{ iso: string, name: string }>> {
    if (!this.isoWithName.length) {
      const remittanceBaseCountries = await ReceivableCountries.get()
      const unsortedisowithName: Array<{ iso: string, name: string }> = remittanceBaseCountries.map(country => {
        const translatedCountry = i18n.t(`country.${country.toLowerCase()}`) as string
        return { iso: country, name: translatedCountry }
      })
      this.isoWithName = unsortedisowithName.sort((objA, objB) => {
        return objA.name < objB.name ? -1 : (objA.name > objB.name ? 1 : 0)
      })
    }
    return this.isoWithName
  }
}
