export const regexpOptions: Dictionary<RegExp> = {
  number: /^[0-9]*$/,
  numberComma: /^[0-9, ]*$/,
  numberEnglish: /^[0-9a-zA-Z ]*$/,
  numberEnglishWithoutBlank: /^[0-9a-zA-Z]*$/,
  english: /^[a-zA-Z ]*$/,
  englishLower: /^[a-z]*$/,
  englishUpper: /^[A-Z]*$/,
  korean: /^[가-힣]+$/,
  corps_id: /^[0-9a-zA-Z\-_ ]*$/,
  name_first: /^[a-zA-Z\-. ]*$/,
  name_middle: /^[a-zA-Z\-. ]*$/,
  name_last: /^[a-zA-Z\-. ]*$/,
  name: /^[0-9a-zA-Z\-. ]*$/,
  amount: /^[0-9.]*$/,
  date: /([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/,
  phone: /^[0-9]*$/,
  id_card_number: /^[0-9a-zA-Z]*$/,
  account_number: /^[0-9a-zA-Z ]*$/,
  bsb_code: /^[0-9]{6}$/,
  transit_code: /^[0-9]{5}$/,
  bank_branch: /^[0-9]*$/,
  routing_number: /^[0-9]*$/,
  address_line1: /^[0-9a-zA-Z()\-.,_/ ]*$/,
  address_swift: /^[0-9a-zA-Z\-,_ ]*$/,
  swift: /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[XXX0-9]{0,3}/,
  birth_date: /^([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))$/,
  email: /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,6}$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()<>=+_`~?])[A-Za-z\d!@#$%^&*()<>=+_`~?]{8,}$/,
  website: /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  'alpha-2': /^[a-zA-Z]{2}$/,
  sort_code: /^[0-9]{6}$/,
  ifsc: /^[a-zA-Z]{4}0[0-9]{6}$/,
  card_number: /^[0-9]{14}$/,
  iban_NL: /^NL[0-9]{2}[a-zA-Z]{4}[0-9]{10}$/,
  iban_DE: /^DE[0-9]{20}$/,
  iban_LU: /^LU[0-9]{18}$/,
  iban_BE: /^BE[0-9]{14}$/,
  iban_ES: /^ES[0-9]{22}$/,
  iban_IE: /^IE[0-9]{2}[a-zA-Z]{4}[0-9]{14}$/,
  iban_AT: /^AT[0-9]{18}$/,
  iban_IT: /^IT[0-9]{2}[a-zA-Z][0-9]{22}$/,
  iban_PT: /^PT[0-9]{23}$/,
  iban_FR: /^FR[0-9]{25}$/,
  iban_FI: /^FI[0-9]{16}$/
}

export const regexToPatternString = (regexName: string) => {
  const regexString: string = regexpOptions[regexName].toString()
  return regexString.substring(1, regexString.length - 1)
}
