export const partneringCompaniesImgs: Array<string> = [
  'snmsung-pay',
  'moneygram',
  'koreainvestment',
  'kebhana',
  'shinhan',
  'welcomebank',
  'tranglo',
  'thunes',
  'nium',
  'ripple',
  'vietcombank',
  'bri',
  'metrobank',
  'DBS',
  'SCB',
  'MDAQ',
  'moneymatch',
  'wyre',
  'remitly',
  'coinph',
  'deemoney',
  'wallex',
  'rapyd',
  'xendit'
]
