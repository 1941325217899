import { FxRates } from '@/gateway/fxRates/FxRates'
import { Decimal } from 'decimal.js'
import { CorpInformation, Fee } from '@/gateway/commons/model/CorpInformation'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { CalculateOption, CalculateOptionV3 } from '@/lib/calculator/CalculateOption'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import Recipient from '@/models/recipient/Recipient'
import { DecimalCurrencies } from '@/lib/calculator/data/DecimalCurrencies'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { CalculatorNumber } from '@/lib/calculator/CalculatorNumber'
import { RemittanceHistoryGroupDetail } from '@/models/remittance'

interface CommissionSource {
  usdBaseAmount: CalculatorNumber
  sendExchangeRate: number
  receiveExchangeRate: number
  fee: Fee
  version?: number
  isReceipt: boolean
}

interface CalculatedCommissions {
  senderFixed: CalculatorNumber
  senderRate: CalculatorNumber
  senderCommission: CalculatorNumber
  receiverCommission: CalculatorNumber
}

interface CommissionResult {
  totalCommissionUsd: CalculatorNumber
  senderCommissionUsd: CalculatorNumber
  senderFixedCommissionCurrency: CalculatorNumber
  senderRateCommissionCurrency: CalculatorNumber
  senderCommissionCurrency: CalculatorNumber
  receiverCommissionUsd: CalculatorNumber
  receiverCommissionCurrency: CalculatorNumber
  isMinCommission: boolean
}

export enum CalculatorSourceType {
  RECEIPT_INDIVIDUAL_RECEIPT = 100,
  RECEIPT_TRANSACTION_RECEIPT = 101,
  TRANSFER_CONFIRMATION = 102,
  TRANSFER_CURRENCY_RECEIPT = 110,
  TRANSFER_HISTORY = 200,
  REMITTANCE = 300,
  REMITTANCE_GROUP = 3001
}

export interface ICalculatorSource {
  type: CalculatorSourceType
  transferRow?: TransferRow
  remittanceGroupDetail?: RemittanceGroupDetail
  recipient?: Recipient
  corpInfo?: CorpInformation
  remittanceHistoryGroupDetail?: RemittanceHistoryGroupDetail
}

export interface ICalculator {
  setRates(rates: FxRates): void

  setSource(source: ICalculatorSource): void

  calculate(option: CalculateOption): CalculateResult
}

export class Calculator implements ICalculator {
  private rates: FxRates
  private option!: CalculateOption

  constructor(rates: FxRates) {
    this.rates = rates
    Decimal.set({ precision: 32 })
  }

  private currencyDecimalRound(amount: number, currency: keyof typeof CurrencyEnum): number {
    const decimalAmount = new Decimal(amount)
    if (DecimalCurrencies.includes(currency)) {
      const rounded = Math.round(decimalAmount.mul(100).toNumber())
      return new Decimal(rounded).div(100).toNumber()
    }
    return Math.round(decimalAmount.toNumber())
  }

  private getExchangeRate(
    currency: keyof typeof CurrencyEnum,
    defaultBaseCurrency: keyof typeof CurrencyEnum = 'USD'
  ): number {
    const isDefaultCurrency = currency.toUpperCase() === defaultBaseCurrency
    const rateKey: string = `${defaultBaseCurrency.toLowerCase()}_${currency.toLowerCase()}`
    const rates: Dictionary<any> = Object.assign({}, this.rates)
    return isDefaultCurrency ? 1 : parseFloat(rates[rateKey])
  }

  private applyFeeCurrency(feeSource: Fee): Fee {
    const fee = Object.assign({}, feeSource)
    if (fee.fee_currency === 'USD') return fee
    const rate = this.getExchangeRate(fee.fee_currency)
    fee.fixed = new CalculatorNumber(fee.fixed).div(rate).toNumber()
    if (fee.min_fee) fee.min_fee = new CalculatorNumber(fee.min_fee).div(rate).toNumber()
    if (fee.min_amount) fee.min_amount = new CalculatorNumber(fee.min_amount).div(rate).toNumber()
    return fee
  }

  public setRates(rates: FxRates): void {
    this.rates = rates
  }

  public setSource(source: ICalculatorSource): void {
    const calculateOptionV3 = new CalculateOptionV3(source)
    this.option = calculateOptionV3.option
  }

  private feeCommissionsV3(usdBaseAmount: CalculatorNumber, fee: Fee, isMinFee: boolean): CalculatedCommissions {
    const feeCommission = isMinFee
      ? new CalculatorNumber(fee.min_fee as number)
      : new CalculatorNumber(fee.fixed)
    const senderRateCommission = isMinFee
      ? new CalculatorNumber(0)
      : usdBaseAmount.mul(fee.rate)
    const totalCommission = feeCommission.plus(senderRateCommission.toNumber())
    return {
      senderFixed: feeCommission,
      senderRate: senderRateCommission,
      senderCommission: totalCommission.mul(fee.ratio),
      receiverCommission: totalCommission.mul(1 - fee.ratio)
    }
  }

  private feeCommissionsV4(usdBaseAmount: CalculatorNumber, fee: Fee, isMinFee: boolean): CalculatedCommissions {
    const feeCommission = isMinFee
      ? new CalculatorNumber(fee.min_fee as number)
      : new CalculatorNumber(fee.fixed)
    const feeRate = fee.fee_currency === 'KRW' ? 0 : fee.rate
    const ratioFixed = feeCommission.mul(fee.ratio).mul(1 + feeRate)
    const senderRateCommission = usdBaseAmount.mul(fee.rate)
    return {
      senderFixed: ratioFixed,
      senderRate: senderRateCommission,
      senderCommission: ratioFixed.plus(senderRateCommission.toNumber()),
      receiverCommission: feeCommission.mul(1 - fee.ratio)
    }
  }

  private calculateCommission(source: CommissionSource): CommissionResult {
    const { usdBaseAmount, sendExchangeRate, receiveExchangeRate, fee, version, isReceipt } = source
    const isLowerThanMinFee = fee.min_amount && fee.min_fee && fee.min_amount > usdBaseAmount.toNumber()
    const isRequiredMinFeeCalculate = isLowerThanMinFee || (isReceipt && fee.min_fee)

    const isVersion4 = version === 4
    const feeCommissionMethod = isVersion4 ? this.feeCommissionsV4 : this.feeCommissionsV3
    const commissionsUSDs = feeCommissionMethod(usdBaseAmount, fee, !!isRequiredMinFeeCalculate)

    const senderFixedUsd = commissionsUSDs.senderFixed
    const senderRateUsd = commissionsUSDs.senderRate
    const senderCommissionUsd = commissionsUSDs.senderCommission

    const senderFixedCommissionCurrency = senderFixedUsd.mul(sendExchangeRate)
    const senderRateCommissionCurrency = senderRateUsd.mul(sendExchangeRate)
    const senderCommissionCurrency = senderCommissionUsd.mul(sendExchangeRate)

    const receiverCommissionUsd = commissionsUSDs.receiverCommission
    const receiverCommissionCurrency = receiverCommissionUsd.mul(receiveExchangeRate)
    return {
      isMinCommission: !!isLowerThanMinFee,
      totalCommissionUsd: senderCommissionUsd.plus(receiverCommissionUsd.toNumber()),
      senderCommissionUsd,
      senderFixedCommissionCurrency,
      senderRateCommissionCurrency,
      senderCommissionCurrency,
      receiverCommissionUsd,
      receiverCommissionCurrency
    }

  }

  calculate(): CalculateResult {
    const option: CalculateOption = Object.assign({}, this.option)

    const baseExchangeRate = this.getExchangeRate(option.baseAmountCurrency)
    const sendExchangeRate = this.getExchangeRate(option.sendAmountCurrency)
    const receiveExchangeRate = this.getExchangeRate(option.receiveAmountCurrency)

    const baseAmountDecimal = new CalculatorNumber(option.baseAmount)
    const usdBaseAmountDecimal = baseAmountDecimal.div(baseExchangeRate)

    const originalFee: Fee = Object.assign({}, option.fee)
    const calculateFee: Fee = this.applyFeeCurrency(option.fee)

    const commissionSource: CommissionSource = {
      usdBaseAmount: usdBaseAmountDecimal,
      sendExchangeRate,
      receiveExchangeRate,
      fee: calculateFee,
      version: option.version,
      isReceipt: !!option.isReceipt
    }

    const calculatedCommission = this.calculateCommission(commissionSource)
    const {
      isMinCommission,
      senderFixedCommissionCurrency,
      senderRateCommissionCurrency,
      senderCommissionCurrency,
      receiverCommissionUsd,
      receiverCommissionCurrency
    } = calculatedCommission

    if (!isMinCommission) {
      delete originalFee.min_fee
      delete originalFee.min_amount
    }

    const senderFixedCommission = this.currencyDecimalRound(
      senderFixedCommissionCurrency.toNumber(),
      option.sendAmountCurrency
    )
    const senderRateCommission = this.currencyDecimalRound(
      senderRateCommissionCurrency.toNumber(),
      option.sendAmountCurrency
    )
    const senderCommission =
      option.version === 4
        ? senderFixedCommission + senderRateCommission
        : this.currencyDecimalRound(senderCommissionCurrency.toNumber(), option.sendAmountCurrency)

    const receiverCommission: number = this.currencyDecimalRound(
      receiverCommissionCurrency.toNumber(),
      option.receiveAmountCurrency
    )

    const receiverCommissionSendCurrency = receiverCommissionUsd.mul(sendExchangeRate)
    const totalCommissionSenderCurrency = receiverCommissionSendCurrency.plus(senderCommissionCurrency.toNumber())
    const totalCommission: number = this.currencyDecimalRound(
      totalCommissionSenderCurrency.toNumber(),
      option.sendAmountCurrency
    )

    const sendAmountWithoutCommission = usdBaseAmountDecimal.mul(sendExchangeRate)
    const sendAmount: number = this.currencyDecimalRound(
      sendAmountWithoutCommission.plus(senderCommission).toNumber(),
      option.sendAmountCurrency
    )

    const receiveAmountWithoutCommission = usdBaseAmountDecimal.mul(receiveExchangeRate)
    const receiveAmount: number = this.currencyDecimalRound(
      receiveAmountWithoutCommission.minus(receiverCommission).toNumber(),
      option.receiveAmountCurrency
    )

    const usdAmount: number = this.currencyDecimalRound(usdBaseAmountDecimal.toNumber(), 'USD')

    const calculateResult: CalculateResult = {
      baseAmount: option.baseAmount,
      sendAmount,
      receiveAmount: receiveAmount > 0 ? receiveAmount : 0,
      fixedCommission: senderFixedCommission,
      commission: senderCommission,
      receiverCommissionSendCurrency: Number(receiverCommissionSendCurrency.toNumber().toFixed(2)),
      fee: originalFee,
      totalCommission,
      usdAmount
    }
    return calculateResult
  }
}
