
import { Component, Prop, Vue } from 'vue-property-decorator'
import SimpleDataTable from '@/components/shared/SimpleDataTable.vue'
import { StatusType } from '@/enums'
import { RemittanceHistoryGroup, RemittanceHistoryGroupDetail } from '@/models/remittance'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import CommentUpdateModal from '@/components/CommentUpdateModal.vue'
import { ITransferHistoryDetailsPresentation, TransferHistoryDetailsPresentation } from '@/presentation/transferHIstory/TransferHistoryDetailsPresentation'
import { LocaleMessage } from 'vue-i18n'

interface IAny {
  [key: string]: any;
}
@Component({
  components: {
    CommentUpdateModal,
    BasicDialog,
    SimpleDataTable
  }
})
export default class TransferHistoryDetails extends Vue {
  @Prop(Array) readonly list!: Array<RemittanceHistoryGroupDetail>
  @Prop(Object) readonly groupDetail!: RemittanceHistoryGroup
  @Prop(Boolean) readonly usableHistoryCertificate!: boolean

  transferHistoryPresentation: ITransferHistoryDetailsPresentation = new TransferHistoryDetailsPresentation()

  commentDialog: boolean = false
  commentData: { remittance_id?: string, recipient_id?: string, comment?: string } = {}

  fullName (recipient: IAny): string {
    let props = [recipient.first_name, recipient.middle_name, recipient.last_name]
    props = props.filter(name => name)
    return props.join(' ')
  }

  itemIndex (item: number): number {
    const statusOrders = [
      StatusType.FAILED_TRANSFER,
      StatusType.INPROGRESS,
      StatusType.DONE,
      StatusType.REFUNDED
    ]
    return statusOrders.includes(item)
      ? statusOrders.indexOf(item)
      : statusOrders.length
  }

  sortStatusType (types: Array<number>): Array<number> {
    return types.sort((a, b) => {
      return this.itemIndex(a) - this.itemIndex(b)
    })
  }

  get statusCodes (): Array<number> {
    const list = this.list.map((item: RemittanceHistoryGroupDetail) => item.status_type)
    const showStatusList = [StatusType.INPROGRESS, StatusType.FAILED_TRANSFER, StatusType.WAITING_TRANSFER]
    const filteredList = list.filter((statusType: number) => showStatusList.includes(statusType))
    const types = Array.from(new Set(filteredList))
    return this.sortStatusType(types)
  }

  sortAscending (list: Array<string>): Array<string> {
    return list.sort((a, b) => {
      const nameA = this.getCountryLabel(a)
      const nameB = this.getCountryLabel(b)
      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0)
    })
  }

  get countryCodes (): Array<string> {
    const countries = this.list.map(({ recipient }) => recipient.country)
    const codes = Array.from(new Set(countries))
    return this.sortAscending(codes)
  }

  filteredByStatusCode (code: string | number): Array<RemittanceHistoryGroupDetail> {
    return this.list.filter(item => item.status_type === code)
  }

  filteredByCountryCode (iso: string | number): Array<RemittanceHistoryGroupDetail> {
    return this.list.filter(({ recipient }) => recipient.country === iso)
  }

  listFormatterByCode ({ code, type }: { code: string | number, type: string }): Array<any> {
    const list: { [key: string]: Array<RemittanceHistoryGroupDetail> } = {
      status_type: this.filteredByStatusCode(code),
      country: this.filteredByCountryCode(code)
    }
    return list[type].map(({ id, status_type: statusType, recipient, comment, invoice, payment_completed_at: paymentCompletedAt }) => {
      const { corp_pid, country } = recipient
      const commentLimitLength: number = 10
      const newInstance: IAny = {
        status_type: {
          receive_country: country,
          transfer_id: id,
          corp_pid,
          full_name: this.fullName(recipient)
        },
        country: {
          transfer_id: id,
          corp_pid,
          full_name: this.fullName(recipient),
          status_type: this.getStatusLabel(statusType),
          buttons: {
            type: 'buttonArray',
            value: this.composeButtons(statusType, !!invoice, id, paymentCompletedAt)
          },
          comment: {
            type: 'button',
            icon: !comment ? 'el-icon-plus' : undefined,
            circle: true,
            size: 'mini',
            text: !comment ? '' : comment.length > commentLimitLength ? comment.substr(0, commentLimitLength) + '...' : comment,
            value: comment,
            disabled: false,
            callback: () => {
              this.commentData = Object.assign({}, {
                remittance_id: id,
                recipient_id: recipient.corp_pid,
                comment: comment
              })
              this.commentDialog = true
            }
          }
        }
      }

      return Object.assign({}, newInstance[type])
    })
  }

  async getInvoiceFile (remittanceId: string): Promise<void> {
    this.transferHistoryPresentation.getInvoiceFile(remittanceId, this)
  }

  composeButtons (statusType: number, invoice: boolean, id: string, paymentCompletedAt?: Date) {
    let composed: Array<object> = []
    const depositDone: boolean = statusType >= StatusType.INPROGRESS && statusType !== StatusType.EXPIRED
    const isPaid = !!paymentCompletedAt
    if (depositDone && isPaid) {
      composed.push({
        name: 'deposit',
        type: 'button',
        size: 'mini',
        text: `${this.$t('commons.deposit_receipt')}`,
        class: 'receipt-button',
        value: {
          id,
          status: statusType
        },
        callback: this.makeDepositReceipt
      })
    }
    if (invoice) {
      composed.push({
        name: 'invoice',
        type: 'button',
        size: 'mini',
        text: `${this.$t('commons.invoice')}`,
        class: 'receipt-button',
        value: id,
        callback: () => {
          this.getInvoiceFile(id)
        }
      })
    }
    if (depositDone && isPaid) {
      composed.push({
        name: 'transactionCertification',
        type: 'button',
        text: `${this.$t('sheet.field.transfer_certificate')}`,
        class: 'receipt-button',
        size: 'mini',
        value: id,
        disabled: StatusType[statusType] !== 'DONE',
        callback: this.makeDepositCertification
      })
    }
    if (statusType === StatusType.REFUNDED) {
      composed.push(
        {
          name: 'refund',
          type: 'button',
          text: `${this.$t('commons.refund_receipt')}`,
          class: 'receipt-button',
          size: 'mini',
          value: {
            id,
            status: statusType
          },
          callback: this.makeRefundReceipt
        }
      )
    }

    return composed
  }

  getListByStatus (code: number): Array<any> {
    return this.listFormatterByCode({ code, type: 'status_type' })
  }

  getListByCountry (code: string): Array<any> {
    return this.listFormatterByCode({ code, type: 'country' })
  }

  getStatusLabel (code: number): string {
    const statusTypeName = StatusType[code].toLowerCase()
    return this.$t(`commons.transfer_status.${statusTypeName}`) as string
  }

  isFailedTransfer (code: number): Boolean {
    const FAILED_TRANSFER = 9
    return code === FAILED_TRANSFER
  }

  getCountryLabel (code: string): LocaleMessage {
    return this.$t(`country.${[code.toLowerCase()]}`)
  }

  makeDepositReceipt (param: {id: string, status: number}): void {
    this.transferHistoryPresentation.makeIndividualReceipt(param.id, this.list, this.groupDetail)
  }

  makeRefundReceipt (param: {id: string, status: number}): void {
    this.transferHistoryPresentation.makeIndividualReceipt(param.id, this.list, this.groupDetail, true)
  }

  makeDepositCertification (id: string): void {
    this.transferHistoryPresentation.makeDepositCertification(id, this.list, this.groupDetail)
  }

  async updateComment (comment: Dictionary<any>): Promise<object | undefined> {
    const isSuccessResponse: boolean = await this.transferHistoryPresentation.updateComment(comment)
    if (!isSuccessResponse) return {}
    this.commentDialog = false
    this.$emit('refreshDetailsData')
  }
}
