export const countryMasterCodesWithCurrency: Dictionary<Dictionary<Dictionary<number>>> = {
  KH: {
    USD: {
      'Foreign Trade Bank of Cambodia (USD)': 11495,
      'Cambodia Commercial Bank LTD (USD)': 11496,
      'Cambodia Public Bank PLC (USD)': 11497,
      'Canadia Bank PLC (USD)': 11498,
      'Krung Thai Bank Public Co LTD PP Branch (USD)': 11499,
      'Cambodia Asia Bank LTD (USD)': 11500,
      'May Bank (Cambodia) PLC (USD)': 11501,
      'Union Commercial Bank PLC (USD)': 11502,
      'Cambodia Mekong Bank Public LTD (USD)': 11503,
      'Advanced Bank of Asia LTD (USD)': 11504,
      'Rural Development Bank (USD)': 11505,
      'First Commercial Bank, Phnom Penh Branch (USD)': 11506,
      'Vattanac Bank (USD)': 11508,
      'J Trust Royal Bank Ltd (USD)': 11509,
      'Shinhan Khmer Bank (USD)': 11510,
      'SATHAPANA BANK PLC (USD)': 11511,
      'Phnom Penh Commercial Bank (USD)': 11512,
      'Booyong Khmer Bank (USD)': 11513,
      'SACOM Bank (Cambodia) PLC (USD)': 11514,
      'Kookmin Bank Cambodia PLC (USD)': 11515,
      'Bank for Investment and Development of Cambodia Plc (USD)': 11516,
      'ICBC Limited Phnom Penh Branch (USD)': 11517,
      'Agri Bank of Cambodia (Vietnam Bank) (USD)': 11518,
      'Bank of China Limted Phnom Penh Branch (USD)': 11519,
      'CIMB Bank PLC (USD)': 11520,
      'Mega International Bank Co LTD PP Branch (USD)': 11521,
      'MB Bank PLC Phnom Penh Branch (USD)': 11522,
      'SHB PLC Phnom Penh Branch (USD)': 11523,
      'Taiwan Cooperative Bank PP Branch (USD)': 11524,
      'Hong Leong Bank (Cambodia) PLC (USD)': 11525,
      'RHB Indochina (Cambodia) PLC (USD)': 11526,
      'Cambodia Post Bank PLC (USD)': 11527,
      'Cathay United Bank (Cambodia) Corporation Bank (USD)': 11528,
      'Phillip Bank PLC (USD)': 11529,
      'Bangkok Bank Plc Cambodia Branch (USD)': 11530,
      'KASIKORN BANK (USD)': 11531,
      'Bred Bank PLC (USD)': 11532,
      'National Bank of Cambodia (USD)': 11533,
      'Chief (Cambodia) Commercial Bank Plc. (USD)': 11534,
      'Prince Bank Plc. (USD)': 11535,
      'Industrial Bank of Korea Phnom Penh Branch. (USD)': 11536,
      'BIC (Cambodia ) Bank Plc. (USD)': 11537,
      'CHIP MONG COMMERCIAL BANK PLC (USD)': 11538,
      'AMK MFI (USD)': 11539,
      'WB FINANCE (USD)': 11540,
      'HATHAKASEKAR MFI (USD)': 11541,
      'AMRET MFI (USD)': 11542,
      'KREDIT MFI (USD)': 11543,
      'PRASAC MFI (USD)': 11544,
      'LOLC MFI (USD)': 11545
    },
    KHR: {
      'Foreign Trade Bank of Cambodia': 11546,
      'Cambodia Commercial Bank LTD': 11547,
      'Cambodia Public Bank PLC': 11548,
      'Canadia Bank PLC': 11549,
      'Krung Thai Bank Public Co LTD PP Branch': 11550,
      'Cambodia Asia Bank LTD': 11551,
      'May Bank (Cambodia) PLC': 11552,
      'Union Commercial Bank PLC': 11553,
      'Cambodia Mekong Bank Public LTD': 11554,
      'Advanced Bank of Asia LTD': 11555,
      'Rural Development Bank': 11556,
      'First Commercial Bank, Phnom Penh Branch': 11557,
      'Vattanac Bank': 11559,
      'J Trust Royal Bank Ltd': 11560,
      'Shinhan Khmer Bank': 11561,
      'SATHAPANA BANK PLC': 11562,
      'Phnom Penh Commercial Bank': 11563,
      'Booyong Khmer Bank': 11564,
      'SACOM Bank (Cambodia) PLC': 11565,
      'Kookmin Bank Cambodia PLC': 11566,
      'Bank for Investment and Development of Cambodia Plc': 11567,
      'ICBC Limited Phnom Penh Branch': 11568,
      'Agri Bank of Cambodia (Vietnam Bank)': 11569,
      'Bank of China Limted Phnom Penh Branch': 11570,
      'CIMB Bank PLC': 11571,
      'Mega International Bank Co LTD PP Branch': 11572,
      'MB Bank PLC Phnom Penh Branch': 11573,
      'SHB PLC Phnom Penh Branch': 11574,
      'Taiwan Cooperative Bank PP Branch': 11575,
      'Hong Leong Bank (Cambodia) PLC': 11576,
      'RHB Indochina (Cambodia) PLC': 11577,
      'Cambodia Post Bank PLC': 11578,
      'Cathay United Bank (Cambodia) Corporation Bank': 11579,
      'Phillip Bank PLC': 11580,
      'Bangkok Bank Plc Cambodia Branch': 11581,
      'KASIKORN BANK': 11582,
      'Bred Bank PLC': 11583,
      'National Bank of Cambodia': 11584,
      'Chief (Cambodia) Commercial Bank Plc.': 11585,
      'Prince Bank Plc.': 11586,
      'Industrial Bank of Korea Phnom Penh Branch.': 11587,
      'BIC (Cambodia ) Bank Plc.': 11588,
      'CHIP MONG COMMERCIAL BANK PLC': 11589,
      'AMK MFI': 11590,
      'WB FINANCE': 11591,
      'HATHAKASEKAR MFI': 11592,
      'AMRET MFI': 11593,
      'KREDIT MFI': 11594,
      'PRASAC MFI': 11595,
      'LOLC MFI': 11596
    }
  },
  HK: {
    HKD: {
      'Bank of China Hong Kong': 10189,
      'DBS Bank Hong Kong': 10190,
      'HSBC Hong Kong': 10191,
      'Standard Chartered Hong Kong': 10192,
      'Citibank N.A.': 11415,
      'Citibank (Hong Kong) Limited': 11416,
      'Hang Seng Bank Ltd.': 11417,
      'Chong Hing Bank Limited': 11418,
      'CMB Wing Lung Bank Limited': 11419,
      'Industrial and Commercial Bank of China (Asia)': 11420,
      'Nanyang Commercial Bank': 11421,
      'China Construction Bank (Asia) Corporation Limited': 11422,
      'Agricultural Bank of China Limited': 11423,
      'China Minsheng Banking Corp. Ltd': 11424
    },
    USD: {
      'ABN AMRO Bank N.V. (USD)': 11901,
      'Agricultural Bank of China Limited (USD)': 11902,
      'Allahabad Bank (USD)': 11903,
      'Australia and New Zealand Banking Group Ltd. (USD)': 11904,
      'Axis Bank Limited (USD)': 11905,
      'Banca Monte dei Paschi di Siena S.p.A. Hong Kong Branch (USD)': 11906,
      'Banco Bilbao Vizcaya Argentaria, S.A. (USD)': 11907,
      'Banco Santander S.A. (USD)': 11908,
      'Bangkok Bank Public Company Limited (USD)': 11909,
      'Bank J. Safra Sarasin Ltd. (USD)': 11910,
      'Bank Julius Baer & Co. Ltd. (USD)': 11911,
      'Bank of America, N.A. (USD)': 11912,
      'Bank of Baroda (USD)': 11913,
      'Bank of China (Hong Kong) Limited (USD)': 11914,
      'Bank of China Limited, Hong Kong Branch (USD)': 11915,
      'Bank of Communications (Hong Kong) Limited (USD)': 11916,
      'Bank of Communications Co., Ltd. (USD)': 11917,
      'Bank of India (USD)': 11918,
      'Bank of Montreal (USD)': 11919,
      'Bank of Singapore Limited (USD)': 11920,
      'Bank of Taiwan (USD)': 11921,
      'Bank SinoPac (USD)': 11922,
      'Barclays Bank Plc. (USD)': 11923,
      'BDO Unibank, Inc. (USD)': 11924,
      'BNP Paribas (USD)': 11925,
      'BNP Paribas Securities Services (USD)': 11926,
      'CA Indosuez (Switzerland) SA (USD)': 11927,
      'Canadian Imperial Bank of Commerce (USD)': 11928,
      'Canara Bank (USD)': 11929,
      'Cathay Bank (USD)': 11930,
      'Cathay United Bank Company, Limited (USD)': 11931,
      'Chang Hwa Commercial Bank, Ltd. (USD)': 11932,
      'China CITIC Bank International Limited (USD)': 11933,
      'China Construction Bank (Asia) Corporation Limited (USD)': 11934,
      'China Construction Bank Corporation (USD)': 11935,
      'China Development Bank (USD)': 11936,
      'China Everbright Bank Co., Ltd. (USD)': 11937,
      'China Merchants Bank Co., Ltd. (USD)': 11938,
      'China Minsheng Banking Corp., Ltd. (USD)': 11939,
      'China Zheshang Bank Co., Ltd. (USD)': 11940,
      'Chiyu Banking Corporation Ltd. (USD)': 11941,
      'Chong Hing Bank Limited (USD)': 11942,
      'CIMB Bank Berhad (USD)': 11943,
      'Citibank (Hong Kong) Limited (USD)': 11944,
      'Citibank, N.A. (USD)': 11945,
      'CMB Wing Lung Bank Limited (USD)': 11946,
      'Commerzbank AG (USD)': 11947,
      'Commonwealth Bank of Australia (USD)': 11948,
      'Coöperatieve Rabobank U.A. (USD)': 11949,
      'Coutts & Co. Ltd. (USD)': 11950,
      'Credit Agricole Corporate and Investment Bank (USD)': 11951,
      'Credit Industriel et Commercial (USD)': 11952,
      'Credit Suisse AG (USD)': 11953,
      'CTBC Bank Co., Ltd. (USD)': 11954,
      'Dah Sing Bank, Ltd. (USD)': 11955,
      'DBS Bank (Hong Kong) Limited (USD)': 11956,
      'DBS Bank Ltd, Hong Kong Branch (USD)': 11957,
      'Deutsche Bank AG (USD)': 11958,
      'DZ BANK AG Deutsche Zentral-Genossenschaftsbank (USD)': 11959,
      'E.Sun Commercial Bank, Ltd. (USD)': 11960,
      'East West Bank (USD)': 11961,
      'EFG Bank AG (USD)': 11962,
      'Erste Group Bank AG (USD)': 11963,
      'Far Eastern International Bank (USD)': 11964,
      'First Abu Dhabi Bank PJSC (USD)': 11965,
      'First Commercial Bank (USD)': 11966,
      'Fubon Bank (Hong Kong) Limited (USD)': 11967,
      'Hang Seng Bank Ltd. (USD)': 11968,
      'HDFC Bank Limited (USD)': 11969,
      'Hong Leong Bank Berhad (USD)': 11970,
      'HSBC Bank USA, N.A. (USD)': 11971,
      'HSBC Private Bank (Suisse) SA (USD)': 11972,
      'Hua Nan Commercial Bank, Ltd. (USD)': 11973,
      'ICBC Standard Bank PLC (USD)': 11974,
      'ICICI Bank Limited (USD)': 11975,
      'Indian Overseas Bank (USD)': 11976,
      'Industrial and Commercial Bank of China (Asia) Limited (USD)': 11977,
      'Industrial and Commercial Bank of China Limited (USD)': 11978,
      'Industrial Bank Co., Ltd. (USD)': 11979,
      'Industrial Bank of Korea (USD)': 11980,
      'ING Bank N.V. (USD)': 11981,
      'Intesa Sanpaolo S.p.A. (USD)': 11982,
      'JPMorgan Chase Bank, N.A. (USD)': 11983,
      'KBC Bank N.V., Hong Kong Branch (USD)': 11984,
      'KEB Hana Bank (USD)': 11985,
      'Kookmin Bank (USD)': 11986,
      'Land Bank of Taiwan Co., Ltd. (USD)': 11987,
      'LGT Bank AG (USD)': 11988,
      'Livi VB Limited (USD)': 11989,
      'Macquarie Bank Limited (USD)': 11990,
      'Malayan Banking Berhad (Maybank) (USD)': 11991,
      'Mashreq Bank - Public Shareholding Company (USD)': 11992,
      'Mega International Commercial Bank Co., Ltd. (USD)': 11993,
      'Melli Bank plc (USD)': 11994,
      'Mitsubishi UFJ Trust and Banking Corporation (USD)': 11995,
      'Mizuho Bank, Ltd., Hong Kong Branch (USD)': 11996,
      'MUFG Bank, Ltd. (USD)': 11997,
      'Nanyang Commercial Bank, Ltd. (USD)': 11998,
      'National Australia Bank Limited (USD)': 11999,
      'National Bank of Pakistan (USD)': 12000,
      'Natixis (USD)': 12001,
      'NatWest Markets N.V. (USD)': 12002,
      'NatWest Markets Plc (USD)': 12003,
      'O-Bank Co., Ltd. (USD)': 12004,
      'OCBC Wing Hang Bank Limited (USD)': 12005,
      'Oversea-Chinese Banking Corporation Ltd. (USD)': 12006,
      'Philippine National Bank (USD)': 12007,
      'Pictet & Cie (Europe) S.A. (USD)': 12008,
      'PT. Bank Negara Indonesia (Persero) Tbk. (USD)': 12009,
      'Public Bank (Hong Kong) Limited (USD)': 12010,
      'Punjab National Bank (USD)': 12011,
      'Royal Bank of Canada (USD)': 12012,
      'Shanghai Commercial Bank Ltd. (USD)': 12013,
      'Shanghai Pudong Development Bank Co., Ltd. (USD)': 12014,
      'Shinhan Bank (USD)': 12015,
      'Skandinaviska Enskilda Banken AB (USD)': 12016,
      'Societe Generale (USD)': 12017,
      'Standard Chartered Bank (Hong Kong) Limited (USD)': 12018,
      'Standard Chartered Bank Hong Kong Branch (USD)': 12019,
      'State Bank of India (USD)': 12020,
      'State Street Bank and Trust Company (USD)': 12021,
      'Sumitomo Mitsui Banking Corporation (USD)': 12022,
      'Sumitomo Mitsui Trust Bank, Limited (USD)': 12023,
      'Svenska Handelsbanken AB (publ) (USD)': 12024,
      'Tai Sang Bank Limited (USD)': 12025,
      'Tai Yau Bank Ltd. (USD)': 12026,
      'Taipei Fubon Commercial Bank Co., Ltd. (USD)': 12027,
      'Taishin International Bank Co., Ltd. (USD)': 12028,
      'Taiwan Business Bank, Ltd. (USD)': 12029,
      'Taiwan Cooperative Bank, Ltd. (USD)': 12030,
      'Taiwan Shin Kong Commercial Bank Co., Ltd. (USD)': 12031,
      'The Bank of East Asia, Limited (USD)': 12032,
      'The Bank of New York Mellon (USD)': 12033,
      'The Bank of Nova Scotia (USD)': 12034,
      'The Chiba Bank Ltd. (USD)': 12035,
      'The Chugoku Bank Limited (USD)': 12036,
      'The Hachijuni Bank, Ltd. (USD)': 12037,
      'HSBC The Hongkong and Shanghai Banking Corporation Limited (USD)': 12038,
      'The Shanghai Commercial & Savings Bank, Ltd. (USD)': 12039,
      'The Shiga Bank, Ltd. (USD)': 12040,
      'The Shizuoka Bank Ltd. (USD)': 12041,
      'The Toronto-Dominion Bank (USD)': 12042,
      'UBS AG, Hong Kong (USD)': 12043,
      'UCO Bank (USD)': 12044,
      'UniCredit Bank AG (USD)': 12045,
      'Union Bancaire Privée, UBP SA (USD)': 12046,
      'Union Bank of India (USD)': 12047,
      'United Overseas Bank Ltd. (USD)': 12048,
      'Wells Fargo Bank, N.A., Hong Kong Branch (USD)': 12049,
      'Westpac Banking Corporation (USD)': 12050,
      'Woori Bank (USD)': 12051,
      'Yuanta Commercial Bank Co., Ltd. (USD)': 12052,
      'ZA Bank Limited (USD)': 12053
    }
  }
}
