import { sentbizApi } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'
import { CreatePaymentTransactionParam } from './model/CreatePaymentTransactionParam'
import { CreatePaymentTransactionResponse } from './model/CreatePaymentTransactionResponse'

export interface CreatePaymentTransaction extends IApiRequest {
  getInstance (): CreatePaymentTransaction;
  request (params: CreatePaymentTransactionParam): Promise<CreatePaymentTransactionResponse>;
}

export class CreatePaymentTransaction implements CreatePaymentTransaction {
  private static instance: CreatePaymentTransaction
  private constructor () {}

  public static getInstance (): CreatePaymentTransaction {
    if (!this.instance) this.instance = new CreatePaymentTransaction()
    return this.instance
  }

  async request (params: CreatePaymentTransactionParam): Promise<CreatePaymentTransactionResponse> {
    const requestOption: RequestOption = {
      url: 'payment',
      method: 'post',
      data: params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response.data
  }
}
