
import Vue from 'vue'
import Component from 'vue-class-component'
import CardInHome from '@/components/home/CardInHome.vue'
import { Prop } from 'vue-property-decorator'
import { SnapshotData } from '@/presentation/home/HomeMainPresentation'

@Component({ components: { CardInHome } })
export default class CorpSnapshot extends Vue {
  @Prop() snapshotData!: SnapshotData
}
