
import Vue from 'vue'
import Component from 'vue-class-component'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { Getter, Mutation } from 'vuex-class'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component
export default class AffiliatedWrapper extends Vue {
  @Getter getCorpStatusState: any
  @Mutation setCorpStatusState: any
  @Mutation setCorpAllState: any

  async created () {
    if (!MemberInfoGateway.hasAuthInfo()) return this.$router.replace('/signIn')
    const memberInfo = MemberInfoGateway.getMemberInfo()
    if (memberInfo.aud !== 'biz_temp') {
      this.$message({ message: '기업가입 중인 사업자가 아닙니다.', type: 'warning' })
      return this.$router.replace('/signIn')
    } else {
      if (!this.getCorpStatusState) {
        const corpId = memberInfo.corp_id
        const corpAll: CorpAll = await GetCorpAll.getInstance().request({ corp_id: corpId })
        this.setCorpStatusState(corpAll.status)
      }
      const isConfirmed = this.getCorpStatusState === CorpStatus.CONFIRMED
      if (isConfirmed) {
        this.$message({ message: '기업가입이 완료된 사용자 입니다. 로그인 페이지로 이동합니다.', type: 'warning' })
        this.setCorpStatusState(0)
        this.setCorpAllState(undefined)
        MemberInfoGateway.removeMemberInfo()
        await this.$router.replace('/signIn')
      }
    }
  }
}
