import { countryMasterCodes } from '@/data/masterCodes/countryMasterCodes'
import { countryMasterCodesWithCurrency } from '@/data/masterCodes/countryMasterCodesWithCurrency'
import i18n from '@/plugins/i18n'
import { nationMasterCode } from '@/data/masterCodes/NationMasterCode'
import { IGetMasterCodeOption } from '@/entity/validator/data/RecipientRules'
import { swiftCodesDictionary } from '@/data/masterCodes/SwiftCodes'

export interface BankCell {
  remittance_method_id: string | number
  receive_method: string
  country: string
  bank_name: string
  swift?: string
  '  '?: string
}

export class MasterCode {
  private static bankList: Array<BankCell> = [
    {
      remittance_method_id: i18n.t('sheet.bank_list.remittance_method_id') as string,
      receive_method: i18n.t('sheet.bank_list.receive_method') as string,
      country: i18n.t('sheet.bank_list.country') as string,
      bank_name: i18n.t('sheet.bank_list.bank_name') as string,
      swift: 'Swift',
      '  ': i18n.t('sheet.notice.precaution') as string
    }
  ]

  public static hasMasterCodes(country: string): boolean {
    return !!countryMasterCodes[country]
  }

  private static currencyMatchedCountryIso: Array<string> = Object.keys(countryMasterCodesWithCurrency)
  public static getMasterCode(option: IGetMasterCodeOption) {
    const isAllBankMasterCodeRequired = !option.masterCodeLabel || nationMasterCode[option.country]
    const isJapan: boolean = option.country === 'JP'
    if (isAllBankMasterCodeRequired) return nationMasterCode[option.country]
    if (isJapan) {
      for (const [key, value] of Object.entries(countryMasterCodes[option.country])) {
        if (key.includes(`${option.masterCodeLabel}`)) return value
      }
      return undefined
    }
    const hasCurrencyDivision = option.receiveCurrency && this.currencyMatchedCountryIso.includes(option.country)
    if (option.country.includes('USD')) option.country = option.country.replace('_USD', '')
    const masterCodes = hasCurrencyDivision
      ? countryMasterCodesWithCurrency[option.country][(option.receiveCurrency || '').toUpperCase()]
      : countryMasterCodes[option.country]
    return masterCodes[option.masterCodeLabel as string]
  }

  public static getMasterCodesWithCountry(option: IGetMasterCodeOption): Dictionary<number> {
    const hasCurrencyDivision = option.receiveCurrency && this.currencyMatchedCountryIso.includes(option.country)
    const masterCodes = hasCurrencyDivision
      ? countryMasterCodesWithCurrency[option.country][(option.receiveCurrency || '').toUpperCase()]
      : countryMasterCodes[option.country]
    return masterCodes
  }

  private static masterCodeLabels?: Dictionary<string> = undefined

  public static getMasterCodeLabels(): Dictionary<string> {
    const isSetMasterCodeLabelsAlready = !!this.masterCodeLabels
    if (isSetMasterCodeLabelsAlready) return this.masterCodeLabels as Dictionary<string>
    let masterCodeLabels: Dictionary<number> = {}
    Object.keys(countryMasterCodes).forEach((country: string) => {
      masterCodeLabels = Object.assign({}, masterCodeLabels, countryMasterCodes[country])
    })
    this.masterCodeLabels = Object.entries(masterCodeLabels).reduce(
      (accMasterCodeLabels: Dictionary<string>, entry) => {
        const [label, masterCode] = entry
        const stringValue: string = masterCode.toString()
        accMasterCodeLabels[stringValue] = label
        return accMasterCodeLabels
      },
      {}
    )
    return this.masterCodeLabels
  }

  public static getBankList(): Array<BankCell> {
    const isSetBankListAlready = this.bankList.length > 1
    if (isSetBankListAlready) return this.bankList
    const translatedCountries: any = i18n.t('country')
    const sortedCountryMasterCodes: Array<string> = Object.keys(countryMasterCodes).sort((a, b) => {
      const nameA = translatedCountries[a.toLowerCase()]
      const nameB = translatedCountries[b.toLowerCase()]
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    })
    sortedCountryMasterCodes.forEach(country => {
      const translatedCountry = translatedCountries[country.toLowerCase()] || country
      const masterCodes: Dictionary<number> = countryMasterCodes[country]
      const countryBankList: Array<BankCell> = Object.keys(masterCodes).map(masterCode => {
        const swiftMasterCode = Object.keys(swiftCodesDictionary).find(
          bankName => swiftCodesDictionary[bankName] === masterCodes[masterCode]
        )

        return {
          remittance_method_id: masterCodes[masterCode],
          receive_method: 'bank',
          country: `${translatedCountry} (${country})`,
          bank_name: masterCode,
          swift: swiftMasterCode || ''
        }
      })
      this.bankList.push(...countryBankList)
    })
    this.bankList[1] = Object.assign(this.bankList[1], { '  ': i18n.t('sheet.notice.precaution_first') as string })
    return this.bankList
  }
}
