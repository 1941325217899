import Vue from 'vue'
import i18n from '@/plugins/i18n'
import { koEnum } from '@/lokalise/enumLocale/ko'

const $this = new Vue({ i18n })

const numberFormatter = (source: number | string): string => {
  if (typeof source === 'string' && !parseFloat(source)) return source
  source = typeof source === 'number' ? source : parseFloat(source)
  return (source).toLocaleString()
}
const countryWithMethodFormatter = (source: string, showMethod = false): string => {
  const countryWithMethod: Array<string> = source.split('_')
  const country: string = countryWithMethod[0]
  const method: string = source.replace(`${country}_`, '')
  const translatedMethod = $this.$t(`commons.${method.toLowerCase()}`).toString()
  const formats = {
    country: $this.$t(`country.${country.toLowerCase()}`) || country,
    method: translatedMethod.includes('.') ? method : translatedMethod
  }
  return `${formats.country} ${showMethod ? formats.method : ''}`
}
export const getEnumValueArray = (enumerator: any): Array<any> => {
  const enumKeys = Object.keys(enumerator)
  return enumKeys.filter(key => !isNaN(Number(key)))
}
export const getEnumOptions = (enumerator: any, enumArray: Array<any>, isPropAsValue = false, useLocaleLabel = true) => {
  return enumArray.map(enumItem => {
    const value = isPropAsValue ? enumerator[enumItem] : enumItem
    const label = useLocaleLabel ? koEnum[enumerator[enumItem]] || enumerator[enumItem] : enumerator[enumItem]
    return { label, value }
  })
}
export const formattersMap: Dictionary<any> = {
  number: numberFormatter,
  countryWithMethod: countryWithMethodFormatter
}
