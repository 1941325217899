enum Status {
  DEFAULT_VALIDATOR = 1,
  RECEIVE_METHOD_VALIDATOR = 2,
  LIMIT_VALIDATOR = 3,
  CALCULATOR_VALIDATOR = 4,
  FISHING_VALIDATOR = 5,
  CHINA_VALIDATOR = 6,
  CHINA_MG_VALIDATOR = 7,
  CHINA_ALIPAY_VALIDATOR = 8,
  SYSTEM_ERROR = 10,
  WITHDRAW_ERR = 11,
  CANCEL = 20,
  CANCEL_BY_PERSON = 21,
  CANCEL_BY_DEPOSIT_EXPIRE = 22,
  CANCEL_BY_REAL_NAME = 23,
  REFUND = 30,
  REFUND_AML = 31,
  BASE = 100,
  INIT = 101,
  WAIT_VERIFICATION = 110,
  WAIT_VIRTUAL_ACCOUNT = 111,
  WAIT_AUTO_DEBIT = 112,
  AUTO_DEBIT_COMPLETE = 113,
  WITHDRAW_WALLET = 120,
  DEPOSIT_WALLET = 121,
  PAYMENT_DOING = 130,
  PAYMENT_DONE = 131,
  AML_ERROR = 135,
  IN_QUEUE_ERROR = 138,
  DUPLICATE_REMITTANCE = 139,
  TRANSFER_IN_QUEUE = 140,
  COMPLIANCE_DONE = 141,
  TRANSFER_QUEUE_ERROR = 142,
  TRANSFERING = 143,
  ANALOGUE_CHECK = 145,
  ANALOGUE_PROCESSING,
  ADMIN_CHECK = 150,
  REAL_NAME_FAIL = 151,
  LOCK_BY_FROM_AML = 160,
  LOCK_BY_TO_AML = 161,
  LOCK_BY_SYS = 162,
  LOCK_BY_FROM_SANCTION_AML = 163,
  LOCK_BY_TO_SANCTION_AML = 164,
  LOCK_BY_VT0301 = 165,
  TIMEOUT = 180,
  COMPLETE = 200,
  REJECTED_BY_PARTNER = 250,
  UNKNOWN = 1000
}

enum StatusType {
  UNKNOWN_TRANSFER_STATE_LABEL,
  WAITING_DEPOSIT,
  INPROGRESS,
  WAITING_TRANSFER,
  RECEIVABLE,
  REFUNDED,
  CANCELED,
  EXPIRED,
  WAITING_RECEIVE,
  FAILED_TRANSFER,
  DONE
}

export {
  Status,
  StatusType
}
