
import Vue from 'vue'
import Component from 'vue-class-component'
import OtpSignIn from '@/components/shared/OtpSignIn.vue'

@Component({
  components: { OtpSignIn }
})
export default class Password extends Vue {
  isOtpChecked: boolean = false

  otpConfirmed (): void {
    this.isOtpChecked = true
  }
}
