import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import './plugins/vue-awesome-swiper.js'
import './plugins/element.js'
import './plugins/vue-meta.js'
import './assets/styles/index.scss'
import DefaultLayout from '@/components/commons/DefaultLayout.vue'
import VueGtm from '@gtm-support/vue2-gtm'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

Vue.component('DefaultLayout', DefaultLayout)

Vue.use(VueLazyload, {
  lazyComponent: true,
  preLoad: 1.3,
  attempt: 1
})

Vue.use(VueGtm, {
  id: 'GTM-KX6NJX7',
  vueRouter: router
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
