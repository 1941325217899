import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApiWithoutAuth } from '@/gateway/axios'
import { SignApiParam } from '@/gateway/corpMember/model/SignApiParam'

export interface SignApi extends IApiRequest {
  getInstance (): SignApi;
  signIn (data: SignApiParam): Promise<any>
  signUp (data: SignApiParam): Promise<any>
}

export class SignApi implements SignApi {
  private static instance: SignApi
  private constructor () {}
  public static getInstance (): SignApi {
    if (!this.instance) this.instance = new SignApi()
    return this.instance
  }

  async signIn (data: SignApiParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'signin',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }

  async signUp (data: SignApiParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'signup',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }
}
