import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { CorpStatus } from '@/gateway/affiliate/model/CorpAll'

export enum CorpMemberPermission {
  ADMIN = 1,
  MEMBER
}

export interface CorpMember {
  id?: string;
  corp_id: string;
  email: string;
  phone_code: number;
  phone_number: number;
  permission: CorpMemberPermission;
  status: CorpStatus;
  password?: string;
}

export interface TinyCorpMember {
  email: string
  id: string
  is_site_admin: 'Y' | 'N'
  phone_code: number
  phone_number: string
  status: number
}

export interface CreateCorpMembersParams {
  list: Array<CorpMember>;
}

export interface DeleteCorpMemberParam {
  corp_member_id: string;
}

export interface CorpMembers extends IApiRequest {
  getInstance (): CorpMembers;
  get (params: { corp_id: string }): Promise<Dictionary<any>>;
  getTiny (params: { corp_id: string }): Promise<Dictionary<any>>;
  put (data: CreateCorpMembersParams): Promise<Dictionary<any>>;
  delete (data: DeleteCorpMemberParam): Promise<Dictionary<any>>;
  deleteByEmail (email: string): Promise<Dictionary<any>>;
}

export class CorpMembers implements CorpMembers {
  private static instance: CorpMembers
  private constructor () {}
  public static getInstance (): CorpMembers {
    if (!this.instance) this.instance = new CorpMembers()
    return this.instance
  }

  async get (params: { corp_id: string }): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmembers',
      method: 'get',
      params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response?.data
  }

  async getTiny(params: { corp_id: string; }): Promise<any> {
      const requestOption: RequestOption = {
        url: 'tinycorpmembers',
        method: 'get',
        params
      }

      const response: any = await sentbizApi.request(requestOption)

      return response
  }

  async put (data: CreateCorpMembersParams): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmembers',
      method: 'put',
      data
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }

  async delete (data: DeleteCorpMemberParam): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmember',
      method: 'delete',
      data
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }

  async deleteByEmail(email: string): Promise<Dictionary<any>> {
      const requestOption: RequestOption = {
        url: 'corpmemberbyemail',
        method: 'delete',
        data: { email }
      }

      const response: any = await sentbizApi.request(requestOption)
      return response
  }
}
