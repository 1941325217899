
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import CardInHomeOptions from '@/presentation/home/model/CardInHomeOptions'

@Component
export default class CardInHome extends Vue {
  @Prop() cardOptions!: CardInHomeOptions
}
