import { AvailableCurrencies } from '@/gateway/remittanceBase/AvailableCurrencies'
import { ReceivableCountries } from '@/gateway/remittanceBase/ReceivableCountries'

export interface IStaticValues {
  allTransferableCurrencies: Array<string>
  receivableCountryCurrencies: Dictionary<Array<string>>
  transferableCountryCurrencies: Dictionary<Array<string>>
  availableCountriesIso: Array<string>
}

export let staticValues: IStaticValues = {
  allTransferableCurrencies: [
    'EUR',
    'NPR',
    'MYR',
    'USD',
    'BDT',
    'VND',
    'LKR',
    'SGD',
    'GBP',
    'INR',
    'IDR',
    'JPY',
    'CNY',
    'CAD',
    'THB',
    'PKR',
    'PHP',
    'AUD',
    'HKD',
    'KHR',
    'TRY',
    'KRW'
  ],
  receivableCountryCurrencies: {
    NL: ['EUR'],
    NP: ['NPR'],
    DK: ['EUR'],
    DE: ['EUR'],
    LV: ['EUR'],
    LU: ['EUR'],
    LT: ['EUR'],
    MY: ['MYR'],
    MC: ['EUR'],
    MT: ['EUR'],
    US: ['USD'],
    BD: ['BDT'],
    VN: ['VND', 'USD'],
    BE: ['EUR'],
    BG: ['EUR'],
    CY: ['EUR'],
    LK: ['LKR'],
    SE: ['EUR'],
    ES: ['EUR'],
    SG: ['SGD'],
    IE: ['EUR'],
    EE: ['EUR'],
    GB: ['GBP'],
    AT: ['EUR'],
    IT: ['EUR'],
    IN: ['INR'],
    ID: ['IDR'],
    JP: ['JPY'],
    CN: ['CNY'],
    KH: ['KHR', 'USD'],
    CA: ['CAD'],
    TH: ['THB'],
    PK: ['PKR'],
    PT: ['EUR'],
    PL: ['EUR'],
    FR: ['EUR'],
    FI: ['EUR'],
    PH: ['PHP'],
    AU: ['AUD'],
    HK: ['HKD'],
    TR: ['TRY'],
    ZZ: ['USD']
  },
  transferableCountryCurrencies: {
    NL: ['EUR', 'USD', 'KRW'],
    NP: ['NPR', 'USD', 'KRW'],
    DK: ['EUR', 'USD', 'KRW'],
    DE: ['EUR', 'USD', 'KRW'],
    LV: ['EUR', 'USD', 'KRW'],
    LU: ['EUR', 'USD', 'KRW'],
    LT: ['EUR', 'USD', 'KRW'],
    MY: ['MYR', 'USD', 'KRW'],
    MC: ['EUR', 'USD', 'KRW'],
    MT: ['EUR', 'USD', 'KRW'],
    US: ['USD', 'KRW'],
    BD: ['BDT', 'USD', 'KRW'],
    VN: ['VND', 'USD', 'KRW'],
    BE: ['EUR', 'USD', 'KRW'],
    BG: ['EUR', 'USD', 'KRW'],
    CY: ['EUR', 'USD', 'KRW'],
    LK: ['LKR', 'USD', 'KRW'],
    SE: ['EUR', 'USD', 'KRW'],
    ES: ['EUR', 'USD', 'KRW'],
    SG: ['SGD', 'USD', 'KRW'],
    IE: ['EUR', 'USD', 'KRW'],
    EE: ['EUR', 'USD', 'KRW'],
    GB: ['GBP', 'USD', 'KRW'],
    AT: ['EUR', 'USD', 'KRW'],
    IT: ['EUR', 'USD', 'KRW'],
    IN: ['INR', 'USD', 'KRW'],
    ID: ['IDR', 'USD', 'KRW'],
    JP: ['JPY', 'USD', 'KRW'],
    CN: ['CNY', 'USD', 'KRW'],
    KH: ['KHR', 'USD', 'KRW'],
    CA: ['CAD', 'USD', 'KRW'],
    TH: ['THB', 'USD', 'KRW'],
    PK: ['PKR', 'USD', 'KRW'],
    PT: ['EUR', 'USD', 'KRW'],
    PL: ['EUR', 'USD', 'KRW'],
    FR: ['EUR', 'USD', 'KRW'],
    FI: ['EUR', 'USD', 'KRW'],
    PH: ['PHP', 'USD', 'KRW'],
    AU: ['AUD', 'USD', 'KRW'],
    HK: ['HKD', 'USD', 'KRW'],
    TR: ['TRY', 'USD', 'KRW'],
    PE: ['PEN', 'USD', 'KRW'],
    ZZ: ['USD']
  },
  availableCountriesIso: [
    'NL',
    'NP',
    'DK',
    'DE',
    'LV',
    'LU',
    'LT',
    'MY',
    'MC',
    'MT',
    'US',
    'BD',
    'VN',
    'BE',
    'BG',
    'CY',
    'LK',
    'SE',
    'ES',
    'SG',
    'IE',
    'EE',
    'GB',
    'AT',
    'IT',
    'IN',
    'ID',
    'JP',
    'CN',
    'KH',
    'CA',
    'TH',
    'PK',
    'PT',
    'PL',
    'FR',
    'FI',
    'PH',
    'AU',
    'HK',
    'TR',
    'PE'
  ]
}

export const initializeStaticValues = async (): Promise<void> => {
  const allTransferableCurrenciesValue = await AvailableCurrencies.getAllList()
  const allTransferableCurrencies = allTransferableCurrenciesValue.length
    ? allTransferableCurrenciesValue
    : staticValues.allTransferableCurrencies
  const receivableCountryCurrenciesValue = await AvailableCurrencies.getReceivableCurrencies()
  const receivableCountryCurrencies = Object.keys(receivableCountryCurrenciesValue).length
    ? receivableCountryCurrenciesValue
    : staticValues.receivableCountryCurrencies
  const transferableCountryCurrenciesValue = await AvailableCurrencies.getTransferableCurrencies()
  const transferableCountryCurrencies = Object.keys(transferableCountryCurrenciesValue).length
    ? transferableCountryCurrenciesValue
    : staticValues.transferableCountryCurrencies
  const availableCountriesIsoValue = await ReceivableCountries.get()
  const availableCountriesIso = availableCountriesIsoValue.length
    ? availableCountriesIsoValue
    : staticValues.availableCountriesIso
  staticValues = {
    allTransferableCurrencies,
    receivableCountryCurrencies,
    transferableCountryCurrencies,
    availableCountriesIso
  }
}
