import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'

export interface CancelRemittanceGroup extends IApiRequest {
  getInstance (): CancelRemittanceGroup;
  request (data: { remittance_group_id: string }): Promise<any>;
}

export class CancelRemittanceGroup implements CancelRemittanceGroup {
  private static instance: CancelRemittanceGroup
  private constructor () {}
  public static getInstance (): CancelRemittanceGroup {
    if (!this.instance) this.instance = new CancelRemittanceGroup()
    return this.instance
  }

  async request (data: { remittance_group_id: string }): Promise<any> {
    const requestOption: RequestOption = {
      url: 'remittance/group/cancel',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
