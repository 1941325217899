
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import RouterOption from '@/models/RouterOption'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { CorpMembers } from '@/gateway/affiliate/CorpMembers'
import MemberInfo from '@/models/MemberInfo'
import { Message } from 'element-ui'

@Component
export default class NaviHeaderWrapper extends Vue {
  @Prop(Array) activeChildren!: Array<RouterOption>
  @Prop(Object) parentNav!: RouterOption
  dropDownToggle: boolean = false
  corpName: string = ''

  get email (): string | null {
    return localStorage.getItem('email')
  }

  get version (): string {
    return `${process.env.NODE_ENV}-${process.env.VUE_APP_VERSION || ''}`
  }

  async setCorpName (): Promise<void> {
    const corpInformation = await GetCorpInformation.get()
    this.corpName = corpInformation.corp_name
  }

  logout (): void {
    MemberInfoGateway.removeMemberInfo()
    this.$router.push('/signIn')
    this.dropDownToggle = false
  }

  async goToAccount (): Promise<void> {
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const response = await CorpMembers.getInstance().getTiny({ corp_id: memberInfo.corp_id })

    const isNotAuthorized = response.code === 403

    if (isNotAuthorized) {
      Message.error(`${this.$t(`notification.${response.message.toLowerCase()}`)}`)
      return
    }

    this.dropDownToggle = false
    this.$router.push('/account')
  }

  async created () {
    if (!this.corpName) await this.setCorpName()
  }
}
