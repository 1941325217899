type ContentType = 'image/png' | 'image/tiff' | 'image/bmp' | 'image/jpg' | 'image/jpeg' | 'image/gif' | 'application/pdf'
export interface FileTransferHandler {
  getInstance (): FileTransferHandler;
  base64ToBlob (base64Data: string, contentType: ContentType, sliceSize: number): Blob;
  blobToBase64 (file: any): Promise<string | ArrayBuffer | null>;
  getBase64ObjectUrl (base64Data: string): string;
  download (url: string, fileName?: string): void;
}

export class FileTransferHandler implements FileTransferHandler {
  private static instance: FileTransferHandler
  // eslint-disable-next-line no-useless-constructor
  private constructor () {}
  public static getInstance (): FileTransferHandler {
    if (!this.instance) this.instance = new FileTransferHandler()
    return this.instance
  }
  private base64FileSignature: Dictionary<ContentType> = {
    CiVQR: 'application/pdf',
    JVBER: 'application/pdf',
    R0lGO: 'image/gif',
    iVBOR: 'image/png',
    Qk14n: 'image/bmp',
    SUkqA: 'image/tiff',
    '/9j/4': 'image/jpeg'
  }
  base64ToBlob (base64Data: string, contentType: ContentType, sliceSize = 512): Blob {
    const byteCharacters = atob(base64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
  }
  blobToBase64 (file: any): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = async () => {
        let base64Data: string | ArrayBuffer | null = reader.result
        if (typeof base64Data === 'string') base64Data = base64Data.split('base64,')[1]
        resolve(base64Data)
      }
      reader.readAsDataURL(file)
    })
  }
  download (url: string, fileName?: string): void {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName || 'sentbiz'
    link.click()
  }
  getBase64ObjectUrl (base64Data: string): string {
    const fileType = this.base64FileSignature[base64Data.substr(0, 5)]
    if (!fileType) return ''
    const blob: Blob = this.base64ToBlob(base64Data, fileType)
    return URL.createObjectURL(blob)
  }
}
