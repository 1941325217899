export const swiftCodesDictionary: { [key: string]: number } = {
  BAAVNVX: 10529,
  BIDVVNVX: 10530,
  EACBVNVX: 10531,
  ICBVVNVX: 10532,
  SGTTVNVX: 10533,
  SBITVNVX: 10534,
  ABBKVNVX: 10535,
  OJBAVNVX: 10536,
  SEAVVNVX: 10537,
  WBVNVNVX: 10538,
  PGBLVNVX: 10539,
  GBNKVNVX: 10540,
  KLBKVNVX: 10541,
  VCBCVNVX: 10542,
  VNTTVNVX: 10543,
  VRBAVNVX: 10544,
  EBVIVNVX: 10545,
  TPBVVNVX: 10546,
  SHBAVNVX: 10547,
  HDBCVNVX: 10548,
  MSCBVNVX: 10549,
  VPBKVNVX: 10550,
  VTCBVNVX: 10551,
  ORCOVNVX: 10552,
  NVBAVNVX: 10553,
  HLBBVNVX: 10554,
  BVBVVNVX: 10555,
  VIDPVNV5: 10556,
  SACLVNVX: 10557,
  ANZBVNVX: 10558,
  HSBCVNVX: 10559,
  NAMAVNVX: 10562,
  BFTVVNVX: 10570,
  ASCBVNVX: 10571,
  VNIBVNVX: 10572,
  SHBKVNVX: 10771,
  LVBKVNVX: 10770,
  HVBKVNVX: 11178,
  MCOBVNVX: 10560,
  SCBLVNVX: 11377
}
