import { CountryCode } from '@/static/Utils'

export const CountryPhoneCodes: Array<CountryCode> = [
  {
    name: 'Israel',
    name_korean: '이스라엘',
    phone_code: 972,
    iso: 'IL'
  },
  {
    name: 'Afghanistan',
    name_korean: '아프가니스탄',
    phone_code: 93,
    iso: 'AF'
  },
  {
    name: 'Albania',
    name_korean: '알바니아',
    phone_code: 355,
    iso: 'AL'
  },
  {
    name: 'Algeria',
    name_korean: '알제리',
    phone_code: 213,
    iso: 'DZ'
  },
  {
    name: 'AmericanSamoa',
    name_korean: '아메리칸사모아',
    phone_code: 1684,
    iso: 'AS'
  },
  {
    name: 'Andorra',
    name_korean: '안도라',
    phone_code: 376,
    iso: 'AD'
  },
  {
    name: 'Angola',
    name_korean: '앙골라',
    phone_code: 244,
    iso: 'AO'
  },
  {
    name: 'Anguilla',
    name_korean: '앵귈라',
    phone_code: 1264,
    iso: 'AI'
  },
  {
    name: 'Antigua and Barbuda',
    name_korean: '앤티가 바부다',
    phone_code: 1268,
    iso: 'AG'
  },
  {
    name: 'Argentina',
    name_korean: '아르헨티나',
    phone_code: 54,
    iso: 'AR'
  },
  {
    name: 'Armenia',
    name_korean: '아르메니아',
    phone_code: 374,
    iso: 'AM'
  },
  {
    name: 'Aruba',
    name_korean: '아루바',
    phone_code: 297,
    iso: 'AW'
  },
  {
    name: 'Australia',
    name_korean: '호주',
    phone_code: 61,
    iso: 'AU'
  },
  {
    name: 'Austria',
    name_korean: '오스트리아',
    phone_code: 43,
    iso: 'AT'
  },
  {
    name: 'Azerbaijan',
    name_korean: '아제르바이잔',
    phone_code: 994,
    iso: 'AZ'
  },
  {
    name: 'Bahamas',
    name_korean: '바하마',
    phone_code: 1242,
    iso: 'BS'
  },
  {
    name: 'Bahrain',
    name_korean: '바레인',
    phone_code: 973,
    iso: 'BH'
  },
  {
    name: 'Bangladesh',
    name_korean: '방글라데시',
    phone_code: 880,
    iso: 'BD'
  },
  {
    name: 'Barbados',
    name_korean: '바베이도스',
    phone_code: 1246,
    iso: 'BB'
  },
  {
    name: 'Belarus',
    name_korean: '벨라루스',
    phone_code: 375,
    iso: 'BY'
  },
  {
    name: 'Belgium',
    name_korean: '벨기에',
    phone_code: 32,
    iso: 'BE'
  },
  {
    name: 'Belize',
    name_korean: '벨리즈',
    phone_code: 501,
    iso: 'BZ'
  },
  {
    name: 'Benin',
    name_korean: '베냉',
    phone_code: 229,
    iso: 'BJ'
  },
  {
    name: 'Bermuda',
    name_korean: '버뮤다',
    phone_code: 1441,
    iso: 'BM'
  },
  {
    name: 'Bhutan',
    name_korean: '부탄',
    phone_code: 975,
    iso: 'BT'
  },
  {
    name: 'Bosnia and Herzegovina',
    name_korean: '보스니아 헤르체고비나',
    phone_code: 387,
    iso: 'BA'
  },
  {
    name: 'Botswana',
    name_korean: '보츠와나',
    phone_code: 267,
    iso: 'BW'
  },
  {
    name: 'Brazil',
    name_korean: '브라질',
    phone_code: 55,
    iso: 'BR'
  },
  {
    name: 'British Indian Ocean Territory',
    name_korean: '영국령 인도양 지역',
    phone_code: 246,
    iso: 'IO'
  },
  {
    name: 'Bulgaria',
    name_korean: '불가리아',
    phone_code: 359,
    iso: 'BG'
  },
  {
    name: 'Burkina Faso',
    name_korean: '부르키나파소',
    phone_code: 226,
    iso: 'BF'
  },
  {
    name: 'Burundi',
    name_korean: '부룬디',
    phone_code: 257,
    iso: 'BI'
  },
  {
    name: 'Cambodia',
    name_korean: '캄보디아',
    phone_code: 855,
    iso: 'KH'
  },
  {
    name: 'Cameroon',
    name_korean: '카메룬',
    phone_code: 237,
    iso: 'CM'
  },
  {
    name: 'Canada',
    name_korean: '캐나다',
    phone_code: 1,
    iso: 'CA'
  },
  {
    name: 'Cape Verde',
    name_korean: '카보베르데',
    phone_code: 238,
    iso: 'CV'
  },
  {
    name: 'Cayman Islands',
    name_korean: '케이맨 제도',
    phone_code: 345,
    iso: 'KY'
  },
  {
    name: 'Central African Republic',
    name_korean: '중앙아프리카 공화국',
    phone_code: 236,
    iso: 'CF'
  },
  {
    name: 'Chad',
    name_korean: '차드',
    phone_code: 235,
    iso: 'TD'
  },
  {
    name: 'Chile',
    name_korean: '칠레',
    phone_code: 56,
    iso: 'CL'
  },
  {
    name: 'China',
    name_korean: '중국',
    phone_code: 86,
    iso: 'CN'
  },
  {
    name: 'Christmas Island',
    name_korean: '크리스마스 섬',
    phone_code: 61,
    iso: 'CX'
  },
  {
    name: 'Colombia',
    name_korean: '콜롬비아',
    phone_code: 57,
    iso: 'CO'
  },
  {
    name: 'Comoros',
    name_korean: '코모로',
    phone_code: 269,
    iso: 'KM'
  },
  {
    name: 'Congo',
    name_korean: '콩고 공화국',
    phone_code: 242,
    iso: 'CG'
  },
  {
    name: 'Cook Islands',
    name_korean: '쿡 제도',
    phone_code: 682,
    iso: 'CK'
  },
  {
    name: 'Costa Rica',
    name_korean: '코스타리카',
    phone_code: 506,
    iso: 'CR'
  },
  {
    name: 'Croatia',
    name_korean: '크로아티아',
    phone_code: 385,
    iso: 'HR'
  },
  {
    name: 'Cuba',
    name_korean: '쿠바',
    phone_code: 53,
    iso: 'CU'
  },
  {
    name: 'Cyprus',
    name_korean: '키프로스',
    phone_code: 537,
    iso: 'CY'
  },
  {
    name: 'Czech Republic',
    name_korean: '체코',
    phone_code: 420,
    iso: 'CZ'
  },
  {
    name: 'Denmark',
    name_korean: '덴마크',
    phone_code: 45,
    iso: 'DK'
  },
  {
    name: 'Djibouti',
    name_korean: '지부티',
    phone_code: 253,
    iso: 'DJ'
  },
  {
    name: 'Dominica',
    name_korean: '도미니카 연방',
    phone_code: 1767,
    iso: 'DM'
  },
  {
    name: 'Dominican Republic',
    name_korean: '도미니카 공화국',
    phone_code: 1849,
    iso: 'DO'
  },
  {
    name: 'Ecuador',
    name_korean: '에콰도르',
    phone_code: 593,
    iso: 'EC'
  },
  {
    name: 'Egypt',
    name_korean: '이집트',
    phone_code: 20,
    iso: 'EG'
  },
  {
    name: 'El Salvador',
    name_korean: '엘살바도르',
    phone_code: 503,
    iso: 'SV'
  },
  {
    name: 'Equatorial Guinea',
    name_korean: '적도 기니',
    phone_code: 240,
    iso: 'GQ'
  },
  {
    name: 'Eritrea',
    name_korean: '에리트레아',
    phone_code: 291,
    iso: 'ER'
  },
  {
    name: 'Estonia',
    name_korean: '에스토니아',
    phone_code: 372,
    iso: 'EE'
  },
  {
    name: 'Ethiopia',
    name_korean: '에티오피아',
    phone_code: 251,
    iso: 'ET'
  },
  {
    name: 'Faroe Islands',
    name_korean: '페로 제도',
    phone_code: 298,
    iso: 'FO'
  },
  {
    name: 'Fiji',
    name_korean: '피지',
    phone_code: 679,
    iso: 'FJ'
  },
  {
    name: 'Finland',
    name_korean: '핀란드',
    phone_code: 358,
    iso: 'FI'
  },
  {
    name: 'France',
    name_korean: '프랑스',
    phone_code: 33,
    iso: 'FR'
  },
  {
    name: 'French Guiana',
    name_korean: '프랑스령 기아나',
    phone_code: 594,
    iso: 'GF'
  },
  {
    name: 'French Polynesia',
    name_korean: '프랑스령 폴리네시아',
    phone_code: 689,
    iso: 'PF'
  },
  {
    name: 'Gabon',
    name_korean: '가봉',
    phone_code: 241,
    iso: 'GA'
  },
  {
    name: 'Gambia',
    name_korean: '감비아',
    phone_code: 220,
    iso: 'GM'
  },
  {
    name: 'Georgia',
    name_korean: '조지아',
    phone_code: 995,
    iso: 'GE'
  },
  {
    name: 'Germany',
    name_korean: '독일',
    phone_code: 49,
    iso: 'DE'
  },
  {
    name: 'Ghana',
    name_korean: '가나',
    phone_code: 233,
    iso: 'GH'
  },
  {
    name: 'Gibraltar',
    name_korean: '지브롤터',
    phone_code: 350,
    iso: 'GI'
  },
  {
    name: 'Greece',
    name_korean: '그리스',
    phone_code: 30,
    iso: 'GR'
  },
  {
    name: 'Greenland',
    name_korean: '그린란드',
    phone_code: 299,
    iso: 'GL'
  },
  {
    name: 'Grenada',
    name_korean: '그레나다',
    phone_code: 1473,
    iso: 'GD'
  },
  {
    name: 'Guadeloupe',
    name_korean: '과들루프',
    phone_code: 590,
    iso: 'GP'
  },
  {
    name: 'Guam',
    name_korean: '괌',
    phone_code: 1671,
    iso: 'GU'
  },
  {
    name: 'Guatemala',
    name_korean: '과테말라',
    phone_code: 502,
    iso: 'GT'
  },
  {
    name: 'Guinea',
    name_korean: '기니',
    phone_code: 224,
    iso: 'GN'
  },
  {
    name: 'Guinea-Bissau',
    name_korean: '기니비사우',
    phone_code: 245,
    iso: 'GW'
  },
  {
    name: 'Guyana',
    name_korean: '가이아나',
    phone_code: 595,
    iso: 'GY'
  },
  {
    name: 'Haiti',
    name_korean: '아이티',
    phone_code: 509,
    iso: 'HT'
  },
  {
    name: 'Honduras',
    name_korean: '온두라스',
    phone_code: 504,
    iso: 'HN'
  },
  {
    name: 'Hungary',
    name_korean: '헝가리',
    phone_code: 36,
    iso: 'HU'
  },
  {
    name: 'Iceland',
    name_korean: '아이슬란드',
    phone_code: 354,
    iso: 'IS'
  },
  {
    name: 'India',
    name_korean: '인도',
    phone_code: 91,
    iso: 'IN'
  },
  {
    name: 'Indonesia',
    name_korean: '인도네시아',
    phone_code: 62,
    iso: 'ID'
  },
  {
    name: 'Iraq',
    name_korean: '이라크',
    phone_code: 964,
    iso: 'IQ'
  },
  {
    name: 'Ireland',
    name_korean: '아일랜드',
    phone_code: 353,
    iso: 'IE'
  },
  {
    name: 'Italy',
    name_korean: '이탈리아',
    phone_code: 39,
    iso: 'IT'
  },
  {
    name: 'Jamaica',
    name_korean: '자메이카',
    phone_code: 1876,
    iso: 'JM'
  },
  {
    name: 'Japan',
    name_korean: '일본',
    phone_code: 81,
    iso: 'JP'
  },
  {
    name: 'Jordan',
    name_korean: '요르단',
    phone_code: 962,
    iso: 'JO'
  },
  {
    name: 'Kazakhstan',
    name_korean: '카자흐스탄',
    phone_code: 7,
    iso: 'KZ'
  },
  {
    name: 'Kenya',
    name_korean: '케냐',
    phone_code: 254,
    iso: 'KE'
  },
  {
    name: 'Kiribati',
    name_korean: '키리바시',
    phone_code: 686,
    iso: 'KI'
  },
  {
    name: 'Kuwait',
    name_korean: '쿠웨이트',
    phone_code: 965,
    iso: 'KW'
  },
  {
    name: 'Kyrgyzstan',
    name_korean: '키르기스스탄',
    phone_code: 996,
    iso: 'KG'
  },
  {
    name: 'Latvia',
    name_korean: '라트비아',
    phone_code: 371,
    iso: 'LV'
  },
  {
    name: 'Lebanon',
    name_korean: '레바논',
    phone_code: 961,
    iso: 'LB'
  },
  {
    name: 'Lesotho',
    name_korean: '레소토',
    phone_code: 266,
    iso: 'LS'
  },
  {
    name: 'Liberia',
    name_korean: '라이베리아',
    phone_code: 231,
    iso: 'LR'
  },
  {
    name: 'Liechtenstein',
    name_korean: '리히텐슈타인',
    phone_code: 423,
    iso: 'LI'
  },
  {
    name: 'Lithuania',
    name_korean: '리투아니아',
    phone_code: 370,
    iso: 'LT'
  },
  {
    name: 'Luxembourg',
    name_korean: '룩셈부르크',
    phone_code: 352,
    iso: 'LU'
  },
  {
    name: 'Madagascar',
    name_korean: '마다가스카르',
    phone_code: 261,
    iso: 'MG'
  },
  {
    name: 'Malawi',
    name_korean: '말라위',
    phone_code: 265,
    iso: 'MW'
  },
  {
    name: 'Malaysia',
    name_korean: '말레이시아',
    phone_code: 60,
    iso: 'MY'
  },
  {
    name: 'Maldives',
    name_korean: '몰디브',
    phone_code: 960,
    iso: 'MV'
  },
  {
    name: 'Mali',
    name_korean: '말리',
    phone_code: 223,
    iso: 'ML'
  },
  {
    name: 'Malta',
    name_korean: '몰타',
    phone_code: 356,
    iso: 'MT'
  },
  {
    name: 'Marshall Islands',
    name_korean: '마셜 제도',
    phone_code: 692,
    iso: 'MH'
  },
  {
    name: 'Martinique',
    name_korean: '마르티니크',
    phone_code: 596,
    iso: 'MQ'
  },
  {
    name: 'Mauritania',
    name_korean: '모리타니',
    phone_code: 222,
    iso: 'MR'
  },
  {
    name: 'Mauritius',
    name_korean: '모리셔스',
    phone_code: 230,
    iso: 'MU'
  },
  {
    name: 'Mayotte',
    name_korean: '마요트',
    phone_code: 262,
    iso: 'YT'
  },
  {
    name: 'Mexico',
    name_korean: '멕시코',
    phone_code: 52,
    iso: 'MX'
  },
  {
    name: 'Monaco',
    name_korean: '모나코',
    phone_code: 377,
    iso: 'MC'
  },
  {
    name: 'Mongolia',
    name_korean: '몽골',
    phone_code: 976,
    iso: 'MN'
  },
  {
    name: 'Montenegro',
    name_korean: '몬테네그로',
    phone_code: 382,
    iso: 'ME'
  },
  {
    name: 'Montserrat',
    name_korean: '몬트세랫',
    phone_code: 1664,
    iso: 'MS'
  },
  {
    name: 'Morocco',
    name_korean: '모로코',
    phone_code: 212,
    iso: 'MA'
  },
  {
    name: 'Myanmar',
    name_korean: '미얀마',
    phone_code: 95,
    iso: 'MM'
  },
  {
    name: 'Namibia',
    name_korean: '나미비아',
    phone_code: 264,
    iso: 'NA'
  },
  {
    name: 'Nauru',
    name_korean: '나우루',
    phone_code: 674,
    iso: 'NR'
  },
  {
    name: 'Nepal',
    name_korean: '네팔',
    phone_code: 977,
    iso: 'NP'
  },
  {
    name: 'Netherlands',
    name_korean: '네덜란드',
    phone_code: 31,
    iso: 'NL'
  },
  {
    name: 'Netherlands Antilles',
    name_korean: '네덜란드령 안틸레스',
    phone_code: 599,
    iso: 'AN'
  },
  {
    name: 'New Caledonia',
    name_korean: '누벨칼레도니',
    phone_code: 687,
    iso: 'NC'
  },
  {
    name: 'New Zealand',
    name_korean: '뉴질랜드',
    phone_code: 64,
    iso: 'NZ'
  },
  {
    name: 'Nicaragua',
    name_korean: '니카라과',
    phone_code: 505,
    iso: 'NI'
  },
  {
    name: 'Niger',
    name_korean: '니제르',
    phone_code: 227,
    iso: 'NE'
  },
  {
    name: 'Nigeria',
    name_korean: '나이지리아',
    phone_code: 234,
    iso: 'NG'
  },
  {
    name: 'Niue',
    name_korean: '니우에',
    phone_code: 683,
    iso: 'NU'
  },
  {
    name: 'Norfolk Island',
    name_korean: '노퍽 섬',
    phone_code: 672,
    iso: 'NF'
  },
  {
    name: 'Northern Mariana Islands',
    name_korean: '북마리아나 제도',
    phone_code: 1670,
    iso: 'MP'
  },
  {
    name: 'Norway',
    name_korean: '노르웨이',
    phone_code: 47,
    iso: 'NO'
  },
  {
    name: 'Oman',
    name_korean: '오만',
    phone_code: 968,
    iso: 'OM'
  },
  {
    name: 'Pakistan',
    name_korean: '파키스탄',
    phone_code: 92,
    iso: 'PK'
  },
  {
    name: 'Palau',
    name_korean: '팔라우',
    phone_code: 680,
    iso: 'PW'
  },
  {
    name: 'Panama',
    name_korean: '파나마',
    phone_code: 507,
    iso: 'PA'
  },
  {
    name: 'Papua New Guinea',
    name_korean: '파푸아 뉴기니',
    phone_code: 675,
    iso: 'PG'
  },
  {
    name: 'Paraguay',
    name_korean: '파라과이',
    phone_code: 595,
    iso: 'PY'
  },
  {
    name: 'Peru',
    name_korean: '페루',
    phone_code: 51,
    iso: 'PE'
  },
  {
    name: 'Philippines',
    name_korean: '필리핀',
    phone_code: 63,
    iso: 'PH'
  },
  {
    name: 'Poland',
    name_korean: '폴란드',
    phone_code: 48,
    iso: 'PL'
  },
  {
    name: 'Portugal',
    name_korean: '포르투갈',
    phone_code: 351,
    iso: 'PT'
  },
  {
    name: 'Puerto Rico',
    name_korean: '푸에르토리코',
    phone_code: 1939,
    iso: 'PR'
  },
  {
    name: 'Qatar',
    name_korean: '카타르',
    phone_code: 974,
    iso: 'QA'
  },
  {
    name: 'Romania',
    name_korean: '루마니아',
    phone_code: 40,
    iso: 'RO'
  },
  {
    name: 'Rwanda',
    name_korean: '르완다',
    phone_code: 250,
    iso: 'RW'
  },
  {
    name: 'Samoa',
    name_korean: '사모아',
    phone_code: 685,
    iso: 'WS'
  },
  {
    name: 'San Marino',
    name_korean: '산마리노',
    phone_code: 378,
    iso: 'SM'
  },
  {
    name: 'Saudi Arabia',
    name_korean: '사우디아라비아',
    phone_code: 966,
    iso: 'SA'
  },
  {
    name: 'Senegal',
    name_korean: '세네갈',
    phone_code: 221,
    iso: 'SN'
  },
  {
    name: 'Serbia',
    name_korean: '세르비아',
    phone_code: 381,
    iso: 'RS'
  },
  {
    name: 'Seychelles',
    name_korean: '세이셸',
    phone_code: 248,
    iso: 'SC'
  },
  {
    name: 'Sierra Leone',
    name_korean: '시에라리온',
    phone_code: 232,
    iso: 'SL'
  },
  {
    name: 'Singapore',
    name_korean: '싱가포르',
    phone_code: 65,
    iso: 'SG'
  },
  {
    name: 'Slovakia',
    name_korean: '슬로바키아',
    phone_code: 421,
    iso: 'SK'
  },
  {
    name: 'Slovenia',
    name_korean: '슬로베니아',
    phone_code: 386,
    iso: 'SI'
  },
  {
    name: 'Solomon Islands',
    name_korean: '솔로몬 제도',
    phone_code: 677,
    iso: 'SB'
  },
  {
    name: 'South Africa',
    name_korean: '남아프리카 공화국',
    phone_code: 27,
    iso: 'ZA'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    name_korean: '사우스조지아 사우스샌드위치 제도',
    phone_code: 500,
    iso: 'GS'
  },
  {
    name: 'Spain',
    name_korean: '스페인',
    phone_code: 34,
    iso: 'ES'
  },
  {
    name: 'Sri Lanka',
    name_korean: '스리랑카',
    phone_code: 94,
    iso: 'LK'
  },
  {
    name: 'Sudan',
    name_korean: '수단',
    phone_code: 249,
    iso: 'SD'
  },
  {
    name: 'Suriname',
    name_korean: '수리남',
    phone_code: 597,
    iso: 'SR'
  },
  {
    name: 'Swaziland',
    name_korean: '스와질란드',
    phone_code: 268,
    iso: 'SZ'
  },
  {
    name: 'Sweden',
    name_korean: '스웨덴',
    phone_code: 46,
    iso: 'SE'
  },
  {
    name: 'Switzerland',
    name_korean: '스위스',
    phone_code: 41,
    iso: 'CH'
  },
  {
    name: 'Tajikistan',
    name_korean: '타지키스탄',
    phone_code: 992,
    iso: 'TJ'
  },
  {
    name: 'Thailand',
    name_korean: '태국',
    phone_code: 66,
    iso: 'TH'
  },
  {
    name: 'Togo',
    name_korean: '토고',
    phone_code: 228,
    iso: 'TG'
  },
  {
    name: 'Tokelau',
    name_korean: '토켈라우',
    phone_code: 690,
    iso: 'TK'
  },
  {
    name: 'Tonga',
    name_korean: '통가',
    phone_code: 676,
    iso: 'TO'
  },
  {
    name: 'Trinidad and Tobago',
    name_korean: '트리니다드 토바고',
    phone_code: 1868,
    iso: 'TT'
  },
  {
    name: 'Tunisia',
    name_korean: '튀니지',
    phone_code: 216,
    iso: 'TN'
  },
  {
    name: 'Turkey',
    name_korean: '터키',
    phone_code: 90,
    iso: 'TR'
  },
  {
    name: 'Turkmenistan',
    name_korean: '투르크메니스탄',
    phone_code: 993,
    iso: 'TM'
  },
  {
    name: 'Turks and Caicos Islands',
    name_korean: '터크스 케이커스 제도',
    phone_code: 1649,
    iso: 'TC'
  },
  {
    name: 'Tuvalu',
    name_korean: '투발루',
    phone_code: 688,
    iso: 'TV'
  },
  {
    name: 'Uganda',
    name_korean: '우간다',
    phone_code: 256,
    iso: 'UG'
  },
  {
    name: 'Ukraine',
    name_korean: '우크라이나',
    phone_code: 380,
    iso: 'UA'
  },
  {
    name: 'United Arab Emirates',
    name_korean: '아랍에미리트',
    phone_code: 971,
    iso: 'AE'
  },
  {
    name: 'United Kingdom',
    name_korean: '영국',
    phone_code: 44,
    iso: 'GB'
  },
  {
    name: 'United States',
    name_korean: '미국',
    phone_code: 1,
    iso: 'US'
  },
  {
    name: 'Uruguay',
    name_korean: '우루과이',
    phone_code: 598,
    iso: 'UY'
  },
  {
    name: 'Uzbekistan',
    name_korean: '우즈베키스탄',
    phone_code: 998,
    iso: 'UZ'
  },
  {
    name: 'Vanuatu',
    name_korean: '바누아투',
    phone_code: 678,
    iso: 'VU'
  },
  {
    name: 'Wallis and Futuna',
    name_korean: '왈리스 퓌튀나',
    phone_code: 681,
    iso: 'WF'
  },
  {
    name: 'Yemen',
    name_korean: '예멘',
    phone_code: 967,
    iso: 'YE'
  },
  {
    name: 'Zambia',
    name_korean: '잠비아',
    phone_code: 260,
    iso: 'ZM'
  },
  {
    name: 'Zimbabwe',
    name_korean: '짐바브웨',
    phone_code: 263,
    iso: 'ZW'
  },
  {
    name: 'land Islands',
    name_korean: '올란드 제도',
    phone_code: 0,
    iso: 'AX'
  },
  {
    name: 'Antarctica',
    name_korean: '남극',
    phone_code: 0,
    iso: 'AQ'
  },
  {
    name: 'Bolivia, Plurinational State of',
    name_korean: '볼리비아',
    phone_code: 591,
    iso: 'BO'
  },
  {
    name: 'Brunei Darussalam',
    name_korean: '브루나이',
    phone_code: 673,
    iso: 'BN'
  },
  {
    name: 'Cocos (Keeling) Islands',
    name_korean: '코코스 제도',
    phone_code: 61,
    iso: 'CC'
  },
  {
    name: 'Congo, The Democratic Republic of the',
    name_korean: '콩고 민주 공화국',
    phone_code: 243,
    iso: 'CD'
  },
  {
    name: 'Cote d\'Ivoire',
    name_korean: '코트디부아르',
    phone_code: 225,
    iso: 'CI'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    name_korean: '포클랜드 제도',
    phone_code: 500,
    iso: 'FK'
  },
  {
    name: 'Guernsey',
    name_korean: '건지 섬',
    phone_code: 44,
    iso: 'GG'
  },
  {
    name: 'Holy See (Vatican City State)',
    name_korean: '바티칸 시국',
    phone_code: 379,
    iso: 'VA'
  },
  {
    name: 'Hong Kong',
    name_korean: '홍콩',
    phone_code: 852,
    iso: 'HK'
  },
  {
    name: 'Iran, Islamic Republic of',
    name_korean: '이란',
    phone_code: 98,
    iso: 'IR'
  },
  {
    name: 'Isle of Man',
    name_korean: '맨 섬',
    phone_code: 44,
    iso: 'IM'
  },
  {
    name: 'Jersey',
    name_korean: '저지 섬',
    phone_code: 44,
    iso: 'JE'
  },
  {
    name: 'Korea, Democratic People\'s Republic of',
    name_korean: '조선민주주의인민공화국 (북한)',
    phone_code: 850,
    iso: 'KP'
  },
  {
    name: 'Korea, Republic of',
    name_korean: '대한민국',
    phone_code: 82,
    iso: 'KR'
  },
  {
    name: 'Lao People\'s Democratic Republic',
    name_korean: '라오스',
    phone_code: 856,
    iso: 'LA'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    name_korean: '리비아',
    phone_code: 218,
    iso: 'LY'
  },
  {
    name: 'Macao',
    name_korean: '마카오',
    phone_code: 853,
    iso: 'MO'
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    name_korean: '마케도니아 공화국',
    phone_code: 389,
    iso: 'MK'
  },
  {
    name: 'Micronesia, Federated States of',
    name_korean: '미크로네시아 연방',
    phone_code: 691,
    iso: 'FM'
  },
  {
    name: 'Moldova, Republic of',
    name_korean: '몰도바',
    phone_code: 373,
    iso: 'MD'
  },
  {
    name: 'Mozambique',
    name_korean: '모잠비크',
    phone_code: 258,
    iso: 'MZ'
  },
  {
    name: 'Palestinian Territory, Occupied',
    name_korean: '팔레스타인',
    phone_code: 970,
    iso: 'PS'
  },
  {
    name: 'Pitcairn',
    name_korean: '핏케언 제도',
    phone_code: 872,
    iso: 'PN'
  },
  {
    name: 'Réunion',
    name_korean: '레위니옹',
    phone_code: 262,
    iso: 'RE'
  },
  {
    name: 'Russia',
    name_korean: '러시아',
    phone_code: 7,
    iso: 'RU'
  },
  {
    name: 'Saint Barthélemy',
    name_korean: '생바르텔레미',
    phone_code: 590,
    iso: 'BL'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    name_korean: '세인트헬레나',
    phone_code: 290,
    iso: 'SH'
  },
  {
    name: 'Saint Kitts and Nevis',
    name_korean: '세인트키츠 네비스',
    phone_code: 1869,
    iso: 'KN'
  },
  {
    name: 'Saint Lucia',
    name_korean: '세인트루시아',
    phone_code: 1758,
    iso: 'LC'
  },
  {
    name: 'Saint Martin',
    name_korean: '신트마르턴',
    phone_code: 590,
    iso: 'MF'
  },
  {
    name: 'Saint Pierre and Miquelon',
    name_korean: '생피에르 미클롱',
    phone_code: 508,
    iso: 'PM'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    name_korean: '세인트빈센트 그레나딘',
    phone_code: 1784,
    iso: 'VC'
  },
  {
    name: 'Sao Tome and Principe',
    name_korean: '상투메 프린시페',
    phone_code: 239,
    iso: 'ST'
  },
  {
    name: 'Somalia',
    name_korean: '소말리아',
    phone_code: 252,
    iso: 'SO'
  },
  {
    name: 'Svalbard and Jan Mayen',
    name_korean: '스발바르 얀마옌 제도',
    phone_code: 47,
    iso: 'SJ'
  },
  {
    name: 'Syrian Arab Republic',
    name_korean: '시리아',
    phone_code: 963,
    iso: 'SY'
  },
  {
    name: 'Taiwan, Province of China',
    name_korean: '중화민국',
    phone_code: 886,
    iso: 'TW'
  },
  {
    name: 'Tanzania, United Republic of',
    name_korean: '탄자니아',
    phone_code: 255,
    iso: 'TZ'
  },
  {
    name: 'Timor-Leste',
    name_korean: '동티모르',
    phone_code: 670,
    iso: 'TL'
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    name_korean: '베네수엘라',
    phone_code: 58,
    iso: 'VE'
  },
  {
    name: 'Viet Nam',
    name_korean: '베트남',
    phone_code: 84,
    iso: 'VN'
  },
  {
    name: 'Virgin Islands, British',
    name_korean: '영국령 버진아일랜드',
    phone_code: 1284,
    iso: 'VG'
  },
  {
    name: 'Virgin Islands, U.S.',
    name_korean: '미국령 버진아일랜드',
    phone_code: 1340,
    iso: 'VI'
  }
]

export const allCountriesIso = CountryPhoneCodes.map(country => country.iso)
export const allCountriesPhoneCodes = CountryPhoneCodes.map(country => country.phone_code)
