import { IFormView } from '@/presentation/IFormView'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { FormValidator } from '@/presentation/FormValidator'
import { SignUp } from '@/gateway/affiliate/SignUp'
import i18n from '@/plugins/i18n'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import FormRule from '@/models/forms/FormRule'

export interface AffiliatedSignUpField {
  biz_license_number: string;
  password: string;
  audience: 'biz_temp';
}

export interface AffiliatedSignUp extends IFormView {
  field: AffiliatedSignUpField;
}

export class AffiliatedSignUp implements AffiliatedSignUp {
  private formValidator: FormValidator = new FormValidator()
  private signUp: SignUp = SignUp.getInstance()

  formName = 'affiliatedLoginForm'
  field: AffiliatedSignUpField = {
    biz_license_number: '',
    password: '',
    audience: 'biz_temp'
  }
  forms: Array<FormItemsOption> = [
    {
      prop: 'biz_license_number',
      type: 'text',
      placeHolder: i18n.t('commons.corporate_registration_number') as string,
      maxlength: 10,
      minlength: 10,
      showLimit: true,
      value: undefined
    },
    {
      prop: 'password',
      type: 'text',
      placeHolder: i18n.t('commons.temporary_password') as string,
      subType: 'password',
      value: undefined
    }
  ]
  rules: Dictionary<Array<FormRule>> = {
    biz_license_number: [{
      required: true,
      message: i18n.t('commons.plz_type_corp_number') as string,
      trigger: 'blur'
    }],
    password: [{
      required: true,
      message: i18n.t('commons.plz_type_temp_password') as string,
      trigger: 'blur'
    }]
  }

  submitLabel = i18n.t('commons.go_signup') as string

  async onSubmit (formName: any): Promise<boolean | void> {
    const isValid = await this.formValidator.validate(formName)
    if (!isValid) return isValid
    const response = await this.signUp.request(this.field)
    if (response.isSuccess) MemberInfoGateway.setMemberInfo(this.field.biz_license_number, response.auth)
    return response.isSuccess
  }
}
