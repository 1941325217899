import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { Module } from 'vuex'
import { RootState } from '@/store/RootState'
import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'

export interface RecipientState {
  registerRecipients: Dictionary<any>;
  validatedRecipients: Dictionary<Array<TransferRow>>;
}

export const recipientStore: Module<RecipientState, RootState> = {
  state: {
    registerRecipients: {},
    validatedRecipients: {}
  },
  mutations: {
    setRegisterRecipients (state, recipients: Dictionary<Array<ParsedRecipient>> = {}) {
      state.registerRecipients = recipients
    },
    setValidatedRecipients (state, recipients: Dictionary<any> = {}) {
      state.validatedRecipients = recipients
    }
  },
  getters: {
    getRegisterRecipients (state): Dictionary<Array<ParsedRecipient>> {
      return state.registerRecipients
    },
    getValidatedRecipients (state): Dictionary<Array<TransferRow>> {
      return state.validatedRecipients
    }
  }
}
