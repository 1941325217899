import { Module } from 'vuex'
import { RootState } from '@/store/RootState'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'

export interface AffiliateState {
  corpAll: CorpAll;
  corpStatus?: CorpStatus | 0;
}

export const affiliateState: Module<AffiliateState, RootState> = {
  state: {
    corpAll: {},
    corpStatus: 0
  },
  mutations: {
    setCorpAllState (state, corpAll: CorpAll = {}) {
      state.corpAll = corpAll
    },
    setCorpStatusState (state, corpStatus: CorpStatus) {
      state.corpStatus = corpStatus
    }
  },
  getters: {
    getCorpAllState (state): CorpAll {
      return state.corpAll
    },
    getCorpStatusState (state): CorpStatus | undefined {
      return state.corpStatus
    }
  }
}
