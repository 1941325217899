import { sentbizApi } from '../axios'
import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'

export interface GetRemittanceBase extends IApiRequest {
  getInstance (): GetRemittanceBase;
  request (): Promise<Array<IRemittanceBase>>;
}

export class GetRemittanceBase implements GetRemittanceBase {
  private static instance: GetRemittanceBase

  private constructor () {
  }

  public static getInstance (): GetRemittanceBase {
    if (!this.instance) this.instance = new GetRemittanceBase()
    return this.instance
  }

  async request (): Promise<Array<IRemittanceBase>> {
    const requestOption: RequestOption = {
      url: 'remittance/bases',
      method: 'get'
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.list || []
  }
}
