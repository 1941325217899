import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'

interface UpdateRemittanceCommentParam {
  remittance_id: string;
  comment: string;
}

export interface UpdateRemittanceComment extends IApiRequest {
  getInstance (): UpdateRemittanceComment;
  request (data: UpdateRemittanceCommentParam): Promise<any>;
}

export class UpdateRemittanceComment implements UpdateRemittanceComment {
  private static instance: UpdateRemittanceComment
  private constructor () {}
  public static getInstance (): UpdateRemittanceComment {
    if (!this.instance) this.instance = new UpdateRemittanceComment()
    return this.instance
  }

  async request (data: UpdateRemittanceCommentParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'remittance/comment',
      method: 'put',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
