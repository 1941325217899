import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { FxRates } from '@/gateway/fxRates/FxRates'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import { VirtualAccount } from '@/gateway/remittance/model/VirtualAccount'
import { StatusType } from '@/enums'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { TransactionReceiptHandler, TransactionReceiptParam } from '@/lib/pdf/cert/TransactionReceiptHandler'
import SheetHandler from '@/lib/SheetHandler'
import TransferSheetHandler from '@/lib/TransferSheetHandler'
import i18n from '@/plugins/i18n'
import { RemittanceHistoryGroup, RemittanceHistoryGroupDetail } from '@/models/remittance'
import MemberInfo from '@/models/MemberInfo'
import { ITransferHistoryUseCase, TransferHistoryUseCase } from '@/usecase/transferHistory/TransferHistoryUseCase'
import dayjs from 'dayjs'
import { TranslateResult } from 'vue-i18n'
import Decimal from 'decimal.js'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import {
  CurrencyBalance,
  TransactionCurrencyReceiptParam,
  TransactionReceiptCurrencyHandler
} from '@/lib/pdf/cert/TransactionReceiptCurrencyHandler'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'

export interface ITransferHistoryComponentPresentation {
  virtualAccount: VirtualAccount
  useCase: ITransferHistoryUseCase

  downloadDetail(id: string, list: Array<RemittanceHistoryGroup>, corpInfo?: CorpInformation): Promise<void>
  getVirtualAccount(): Promise<void>
  createSuccess(vueComponent: Vue): void
  cancelRemittance(id: string, vueComponent: Vue): void
  makeTransferReceipt(
    statusType: StatusType | Array<StatusType>,
    remittanceGroup: RemittanceHistoryGroup,
    remittanceDetails: Array<RemittanceHistoryGroupDetail>
  ): Promise<void>
  makeTransferCurrencyReceipt(
    statusType: StatusType | Array<StatusType>,
    remittanceGroup: RemittanceHistoryGroup,
    remittanceDetails: Array<RemittanceHistoryGroupDetail>
  ): Promise<void>
  getBaseToSendCurrencyRate(
    krwFxRates: Dictionary<string>,
    baseCurrency: keyof typeof CurrencyEnum,
    sendCurrency: keyof typeof CurrencyEnum
  ): string
  convertRemittanceDetail(
    detailRemittance: Array<RemittanceHistoryGroupDetail>,
    sheetName: string,
    fxRatesId: string
  ): Promise<Dictionary<Array<any>>>
  getRemittanceCalculated(remittance: RemittanceHistoryGroupDetail, fxRates: FxRates): Promise<CalculateResult>
}

export class TransferHistoryComponentPresentation implements ITransferHistoryComponentPresentation {
  public virtualAccount: VirtualAccount = {
    bank_code: '',
    account_number: '',
    account_holder_name: ''
  }
  public useCase: ITransferHistoryUseCase

  constructor() {
    this.useCase = new TransferHistoryUseCase()
  }

  async downloadDetail(id: string, list: Array<RemittanceHistoryGroup>): Promise<void> {
    const fxRatesId = list.find(remittanceGroup => remittanceGroup.id === id)?.fx_task_id
    if (!fxRatesId) return
    await this.useCase.getCorpInfo()
    const sheetName = i18n.t('sheet.sheet_name.detail_remittance') as string
    const sheetHandler: SheetHandler = new TransferSheetHandler()
    const remittanceDetails = await this.useCase.getRemittanceGroupDetail(id)
    sheetHandler.sheetJson = await this.convertRemittanceDetail(remittanceDetails, sheetName, fxRatesId)
    sheetHandler.saveSheetByJson('transfer_history.xlsx')
  }

  async getVirtualAccount(): Promise<void> {
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    this.virtualAccount = this.useCase.getVirtualAccount(memberInfo.corp_id)
  }

  createSuccess(vueComponent: Vue): void {
    vueComponent.$message({
      type: 'success',
      message: `${i18n.t('notification.transfer_canceled')}`
    })
    vueComponent.$emit('reload')
  }

  cancelRemittance(id: string, vueComponent: Vue): void {
    vueComponent
      .$confirm(`${i18n.t('commons.transfer_cancel_question')}`, {
        customClass: 'large-contents',
        confirmButtonText: `${i18n.t('commons.do_cancel_transfer')}`,
        cancelButtonText: `${i18n.t('commons.close')}`
      })
      .then(async () => {
        const response = await this.useCase.cancelRemittanceGroup(id)
        const isSuccessResponse: boolean = response?.code === 200
        isSuccessResponse && this.createSuccess(vueComponent)
      })
      .catch(() => {})
  }

  async makeTransferReceipt(
    statusType: StatusType | Array<StatusType>,
    remittanceGroup: RemittanceHistoryGroup,
    remittanceDetails: Array<RemittanceHistoryGroupDetail>
  ): Promise<void> {
    const corpInfo: CorpInformation | undefined = await this.useCase.getCorpInfo()
    if (!corpInfo) return
    const fxTaskId = remittanceGroup.fx_task_id
    const fxRates: FxRates = await this.useCase.getFxRates(fxTaskId)
    const krwFxRatesV3: KrwFxRates = await this.useCase.getKrwRates(fxTaskId)
    const transactionRemittances = remittanceDetails.filter(remittance => {
      if (Array.isArray(statusType)) return statusType.some(status => +status === +remittance.status_type)
      return +remittance.status_type === +statusType
    })
    const transactionReceiptParam: TransactionReceiptParam = {
      corpInfo,
      remittanceGroup,
      remittanceDetails: transactionRemittances,
      fxRates,
      krwFxRatesV3: krwFxRatesV3,
      statusType
    }
    const transactionReceipt = new TransactionReceiptHandler(transactionReceiptParam)
    await transactionReceipt.printDocument()
  }

  private getCurrencyRemittance(
    statusType: StatusType | Array<StatusType>,
    rates: FxRates,
    detailList: Array<RemittanceHistoryGroupDetail>
  ): Dictionary<CurrencyBalance> {
    const currenciesBalance: Dictionary<CurrencyBalance> = {}
    const remittances: Array<RemittanceHistoryGroupDetail> = Array.isArray(statusType)
      ? detailList.filter(element => statusType.includes(element.status_type))
      : detailList.filter(element => element.status_type === statusType)
    const calculator: Calculator = new Calculator(rates)
    remittances.forEach(remittance => {
      const calculatorSource: ICalculatorSource = {
        type: CalculatorSourceType.TRANSFER_CURRENCY_RECEIPT,
        remittanceHistoryGroupDetail: remittance
      }
      calculator.setSource(calculatorSource)
      const calculated: CalculateResult = calculator.calculate()
      const baseAmountCurrency = remittance.base_amount.currency
      if (!currenciesBalance[baseAmountCurrency]) {
        currenciesBalance[baseAmountCurrency] = {
          amount: 0,
          amountKrw: 0,
          feeKrw: 0,
          subTotalAmountKrw: 0,
          transactionNo: 0
        }
      }
      Decimal.set({ precision: 32 })
      const bigDecimalAmount: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].amount)
      const bigDecimalCommission: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].feeKrw)
      const bigDecimalSendAmount: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].subTotalAmountKrw)
      const bigDecimalAmountKrw: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].amountKrw)
      currenciesBalance[baseAmountCurrency].amount = bigDecimalAmount.plus(calculated.baseAmount).toNumber()
      currenciesBalance[baseAmountCurrency].feeKrw = bigDecimalCommission.plus(calculated.commission).toNumber()
      currenciesBalance[baseAmountCurrency].subTotalAmountKrw = bigDecimalSendAmount
        .plus(calculated.sendAmount)
        .toNumber()
      currenciesBalance[baseAmountCurrency].amountKrw = bigDecimalAmountKrw
        .plus(calculated.sendAmount)
        .minus(calculated.commission)
        .toNumber()
      currenciesBalance[baseAmountCurrency].transactionNo++
    })
    return currenciesBalance
  }

  async makeTransferCurrencyReceipt(
    statusType: StatusType | Array<StatusType>,
    remittanceGroup: RemittanceHistoryGroup,
    remittanceDetails: Array<RemittanceHistoryGroupDetail>
  ): Promise<void> {
    const corpInfo: CorpInformation | undefined = await this.useCase.getCorpInfo()
    if (!corpInfo) return
    const fxTaskId = remittanceGroup.fx_task_id
    const fxRates: FxRates = await this.useCase.getFxRates(fxTaskId)
    const krwFxRates: KrwFxRates = await this.useCase.getKrwRates(fxTaskId)
    const currenciesBalance: Dictionary<CurrencyBalance> = this.getCurrencyRemittance(
      statusType,
      fxRates,
      remittanceDetails
    )
    const isEmptyRemittance = !Object.keys(currenciesBalance).length
    if (isEmptyRemittance) return
    const transactionReceiptParam: TransactionCurrencyReceiptParam = {
      corpInfo,
      currenciesBalance,
      krwFxRates,
      remittanceGroup,
      fxTaskId,
      statusType
    }
    const transactionReceipt = new TransactionReceiptCurrencyHandler(transactionReceiptParam)
    await transactionReceipt.printDocument()
  }

  async convertRemittanceDetail(
    detailRemittance: Array<RemittanceHistoryGroupDetail>,
    sheetName: string,
    fxRatesId: string
  ): Promise<Dictionary<Array<any>>> {
    const sheetJson: Dictionary<Array<any>> = {}
    sheetJson[sheetName] = []
    const sortedRemittances = detailRemittance.sort((remA, remB) => {
      if (remA === remB) return 0
      return remA.id < remB.id ? -1 : 1
    })
    for (const remittance of sortedRemittances) {
      const fxRates: FxRates = await this.useCase.getFxRates(fxRatesId)
      const isVersion4 = remittance.version === 4
      const receiveCurrency = remittance.receive_amount.currency
      const rateFeeParam = {
        currency: receiveCurrency,
        rate_fee: remittance.rate_fee
      }
      const getKrwRates = isVersion4 ? this.useCase.getKrwRatesV4 : this.useCase.getKrwRates
      const krwFxRate = isVersion4 ? await getKrwRates(fxRatesId, rateFeeParam) : await getKrwRates(fxRatesId)
      const recipient = remittance.recipient
      const middleName = recipient.middle_name ? `${recipient.middle_name} ` : ' '
      const fullName = `${recipient.last_name}${middleName}${recipient.first_name}`
      const krwRate = this.getBaseToSendCurrencyRate(krwFxRate as any, receiveCurrency)
      const calculated = await this.getRemittanceCalculated(remittance, fxRates)
      const commission = isVersion4 ? calculated.fixedCommission : calculated.commission
      const convertedRemittanceMap: Map<TranslateResult, TranslateResult | string> = new Map<
        string,
        TranslateResult | string
      >()
      convertedRemittanceMap
        .set(i18n.t('sheet.field.transfer_request_date'), dayjs(remittance.created_at).format('YYYY-MM-DD'))
        .set(i18n.t('commons.paying_company'), this.useCase.corpInfo.corp_name)
        .set(i18n.t('commons.transfer_number'), remittance.id)
        .set(i18n.t('sheet.field.corp_pid'), recipient.corp_pid)
        .set(i18n.t('sheet.field.receiver_name'), fullName)
        .set(i18n.t('sheet.field.receive_country'), i18n.t(`country.${[recipient.country?.toLowerCase()]}`))
        .set(i18n.t('sheet.field.base_currency'), remittance.base_amount.currency)
        .set(i18n.t('sheet.field.base_amount'), `${remittance.base_amount.balance}`)
        .set(
          `${i18n.t('sheet.field.base_amount')} (${remittance.send_amount.currency})`,
          `${remittance.send_amount.balance - commission}`
        )
        .set(`${i18n.t('sheet.field.sender_fee')} (${remittance.send_amount.currency})`, `${commission}`)
        .set(
          `${i18n.t('sheet.field.send_amount')} (${remittance.send_amount.currency})`,
          `${remittance.send_amount.balance}`
        )
        .set(
          `${i18n.t('sheet.field.receiver_fee')} (${remittance.send_amount.currency})`,
          `${calculated.receiverCommissionSendCurrency}`
        )
        .set(i18n.t('sheet.field.applied_rates'), krwRate)
        .set(i18n.t('sheet.field.fx_currency'), `${receiveCurrency}${remittance.send_amount.currency}`)
        .set(i18n.t('commons.receive_currency'), receiveCurrency)
        .set(i18n.t('sheet.field.receive_amount'), `${remittance.receive_amount.balance}`)
        .set(i18n.t('sheet.field.memo'), `${remittance.comment}`)
      sheetJson[sheetName].push(Object.fromEntries(convertedRemittanceMap))
    }
    return sheetJson
  }

  getBaseToSendCurrencyRate(
    krwFxRates: Dictionary<string>,
    baseCurrency: keyof typeof CurrencyEnum,
    sendCurrency: keyof typeof CurrencyEnum = 'KRW'
  ): string {
    const krwRate = krwFxRates[`${baseCurrency.toLowerCase()}_${sendCurrency.toLowerCase()}`]
    if (baseCurrency === sendCurrency) return '1'
    return Number(krwRate).toFixed(5)
  }

  async getRemittanceCalculated(remittance: RemittanceHistoryGroupDetail, fxRates: FxRates): Promise<CalculateResult> {
    const calculatorSource: ICalculatorSource = {
      type: CalculatorSourceType.TRANSFER_HISTORY,
      remittanceHistoryGroupDetail: remittance
    }
    const calculator: Calculator = new Calculator(fxRates)
    calculator.setSource(calculatorSource)
    return calculator.calculate()
  }
}
