
import Faq from '@/components/faq/FAQ.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: { Faq }
})
export default class TabFAQView extends Vue {

}
