import CountryEnum from '@/enums/CountryEnum'

export enum ShareHolderStatus {
  NOT_OWNER = 1,
  OWNER = 10,
  SKIPPABLE = 20,
  QUARTER_UPPER,
  LARGEST,
  EXECUTIVES,
  DOMINATION,
  UNKNOWN
}

export interface ShareHolderInformation {
  name: string;
  english_name: string;
  nationality?: keyof typeof CountryEnum;
  birthday: Date;
  share?: string;
}

export interface ShareHolderInformationField {
  skipOwnership?: boolean;
  quarterStake?: boolean;
  ownershipType?: ShareHolderStatus;
  share_holder_information?: ShareHolderInformation;
}
