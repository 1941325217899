import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { CorpAll } from '@/gateway/affiliate/model/CorpAll'

export interface GetCorpAll extends IApiRequest {
  getInstance (): GetCorpAll;
  request (params: { corp_id: string }): Promise<CorpAll>;
}

export class GetCorpAll implements GetCorpAll {
  private static instance: GetCorpAll
  private constructor () {}
  public static getInstance (): GetCorpAll {
    if (!this.instance) this.instance = new GetCorpAll()
    return this.instance
  }

  async request (params: { corp_id: string }): Promise<any> {
    const requestOption: RequestOption = {
      url: 'corp/all',
      method: 'get',
      params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response?.data
  }
}
