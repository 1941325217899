import Vue from 'vue'
import axios, { AxiosInstance } from 'axios'
import router from '@/router'
import store from '@/store'
import AxiosOptions from '@/gateway/axios/AxiosOptions'
import MemberInfo from '@/models/MemberInfo'
import i18n from '@/plugins/i18n'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

const $this = new Vue()

export interface AxiosCreator {
  create (options: AxiosOptions): AxiosInstance;
}

export class AxiosCreator implements AxiosCreator {
  axiosInstance: AxiosInstance = axios.create()

  create (options: AxiosOptions): AxiosInstance {
    this.setRequestAction(options)
    this.setResponseAction(options)
    return this.axiosInstance
  }

  private setRequestAction (options: AxiosOptions) {
    this.axiosInstance.interceptors.request.use(
      config => {
        if (!options.disableLoadingOverlay) store.commit('enableLoading')
        if (options.baseHeader?.headers) {
          Object.keys(options.baseHeader.headers).forEach(header => {
            config.headers.post[header] = options.baseHeader?.headers[header]
          })
        }
        config.headers.post['Content-Type'] = 'application/json'
        config.baseURL = options.api
        if (options.isAuthTokenRequired) {
          const auth = localStorage.getItem('auth')
          if (auth) config.headers.common['Authorization'] = `Bearer ${auth}`
        }
        return config
      },
      error => {
        store.commit('disableLoading')
        return Promise.reject(error)
      }
    )
  }

  private setResponseAction (options: AxiosOptions) {
    this.axiosInstance.interceptors.response.use(
      response => {
        store.commit('disableLoading')
        const isError = response.data.code && response.data.code !== 200
        if (isError) {
          const message = i18n.t('notification.errors.error')
          $this.$message({
            message: `${message} [${response.data.code}]`,
            type: 'error'
          })
        }
        return response.data || {}
      },
      error => {
        store.commit('disableLoading')
        const statusCode = error.response?.status
        let message = error.response ? `${statusCode} ${error.response.statusText}` : 'Network Error'
        const invalidToken = statusCode === 401
        if (options.isAuthTokenRequired && invalidToken) {
          let nextRoute = '/signIn'
          if (MemberInfoGateway.hasAuthInfo()) {
            const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
            if (memberInfo.aud === 'biz_temp') nextRoute = '/signUp'
          }
          MemberInfoGateway.removeMemberInfo()
          router.replace(nextRoute)
          return error.response
        }
        $this.$message({ message, type: 'error' })
        return error.response
      }
    )
  }
}
