import MemberInfo from '@/models/MemberInfo'
import { Message } from 'element-ui'
import { SignUpType } from '@/dome/constants/SignUpType'

export class MemberInfoGateway {
  private static authIndicatorName = 'email'
  private static authTokenName = 'auth'
  private static memberInfo: MemberInfo | undefined = undefined

  static getUserEmail (): string {
    return localStorage.getItem(this.authIndicatorName) || ''
  }

  static hasAuthInfo (): boolean {
    return !!localStorage.getItem(this.authIndicatorName) && !!localStorage.getItem(this.authTokenName)
  }

  static getMemberInfo (): MemberInfo {
    if (!this.memberInfo) {
      const jwtPayload: string | null = localStorage.getItem(this.authTokenName)
      if (!jwtPayload) {
        this.memberInfo = undefined
        Message({ message: 'There is no login information', type: 'warning' })
        throw new Error('HAS NO LOGIN INFO')
      }
      const memberInfo: MemberInfo = JSON.parse(atob(jwtPayload.split('.')[1]))
      this.memberInfo = {
        aud: memberInfo.aud,
        corp_id: memberInfo.corp_id.toString(),
        exp: memberInfo.exp,
        member_id: memberInfo.member_id,
        locale: memberInfo.locale,
        sign_up_type: memberInfo.sign_up_type && memberInfo.sign_up_type.length ? memberInfo.sign_up_type : [SignUpType.SENTBIZ]
      }
    }
    return this.memberInfo
  }

  static isAllowFraud (): boolean {
    const signUpType: Array<SignUpType> = this.getMemberInfo().sign_up_type
    return signUpType && !!signUpType.length && signUpType.includes(SignUpType.FRAUD)
  }

  static isAllowBiz (): boolean {
    const signUpType: Array<SignUpType> = this.getMemberInfo().sign_up_type
    return signUpType && !!signUpType.length && signUpType.includes(SignUpType.SENTBIZ)
  }

  static setMemberInfo (email: string, auth: string): void {
    this.memberInfo = undefined
    localStorage.setItem(this.authIndicatorName, email)
    localStorage.setItem(this.authTokenName, auth)
  }

  static removeMemberInfo (): void {
    this.memberInfo = undefined
    localStorage.removeItem(this.authIndicatorName)
    localStorage.removeItem(this.authTokenName)
  }
}
