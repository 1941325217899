import store from '@/store'
import { read, writeFile, utils, WorkSheet, ColInfo, JSON2SheetOpts, WorkBook } from 'xlsx'
import i18n from '@/plugins/i18n'

export interface sheetHandlerOption {
  range?: number | 1
  useCellDataOption?: boolean
  cellDataOptions?: Dictionary<CellDataOption>
}

export interface CellDataOption {
  type?: string
  defaultValue?: any
}

export interface InvalidSheetHeader {
  sheetName: string
  headerName: string
}

abstract class SheetHandler {
  protected _sheetJson: any
  protected _sheetValidHeaders: Array<string> = []

  get sheetJson(): any {
    return this._sheetJson
  }
  set sheetJson(value: any) {
    this._sheetJson = value
  }

  get sheetValidHeaders(): Array<string> {
    return this._sheetValidHeaders
  }

  protected abstract filterValidSheets(sheetNames: Array<string>): Array<string>

  protected filterEmptyHeader(sheetJson: Array<any>): Array<any> {
    const filteredSheetJson: Array<any> = []
    const baseColumns = Object.keys(Object.assign({}, sheetJson[0]))
    sheetJson = sheetJson.slice(1)
    sheetJson.forEach(row => {
      const initialRow: Dictionary<any> = {}
      baseColumns.forEach(baseColumn => {
        if (!row[baseColumn]) initialRow[baseColumn] = ''
        initialRow[baseColumn] = row[baseColumn]
      })
      const columnKeys = Array(...Object.keys(initialRow))
      columnKeys.forEach((key: string) => {
        if (key.includes('__EMPTY_')) delete initialRow[key]
      })
      const isExistAnyColumn = !!Object.keys(initialRow).length
      if (isExistAnyColumn) filteredSheetJson.push(initialRow)
    })
    return filteredSheetJson
  }

  protected getColumnOptions(sheetJson: Array<Dictionary<any>>): Array<ColInfo> {
    const columnInfos: Array<ColInfo> = []
    Object.keys(sheetJson[0]).forEach(columnKey => {
      const columnKeyWidth: number = columnKey === '이름' ? columnKey.length + 16 : columnKey.length + 5
      const columnNameWidth: number = sheetJson[0][columnKey].toString().length * 1.8 + 3
      columnInfos.push({ wch: Math.floor(Math.max(columnKeyWidth, columnNameWidth)) })
    })
    return columnInfos
  }

  protected setRangeCellDataType(sheetJson: Array<Dictionary<any>>): string {
    const columnSize = Object.keys(sheetJson[0]).length - 1
    const ROW_END = 200
    const range = { s: { c: 0, r: 0 }, e: { c: columnSize, r: ROW_END } }
    return utils.encode_range(range)
  }

  protected setCellDataType(
    sheet: WorkSheet,
    sheetJson: Array<Dictionary<any>>,
    option: Dictionary<CellDataOption>
  ): WorkSheet {
    const alphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    const column = alphabet.concat(alphabet.map(char => `A${char}`))
    const ROW_START = 3
    const ROW_END = 200
    Object.keys(sheetJson[0]).forEach((columnName, index) => {
      if (option[columnName]) {
        for (let i = ROW_START; i < ROW_END; i++) {
          const address = `${column[index]}${i}`
          sheet[address] = { t: option[columnName].type, v: option[columnName].defaultValue || '' }
        }
      }
    })
    return sheet
  }

  protected async sheetToWorkBook(file: Blob): Promise<any> {
    const workBook: any = await new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        let binary = ''
        const bytes = new Uint8Array(e.target?.result)
        bytes.forEach(bin => {
          binary += String.fromCharCode(bin)
        })
        const workBook: WorkBook = read(binary, { type: 'binary' })
        resolve(workBook)
      }
      reader.readAsArrayBuffer(file)
    })
    return workBook
  }

  protected workBookToJson(workBook: WorkBook, range = 0): Dictionary<any> {
    const sheetJson: Dictionary<any> = {}
    workBook.SheetNames.forEach((workSheetName: string) => {
      const workSheet = workBook.Sheets[workSheetName]
      let workSheetJson: Array<any> = utils.sheet_to_json(workSheet, { range })
      workSheetJson = this.filterEmptyHeader(workSheetJson)
      if (workSheetJson.length) sheetJson[workSheetName] = workSheetJson
    })
    return sheetJson
  }

  public getInvalidSheetHeader(sheetValidHeaders: Array<string>): InvalidSheetHeader {
    let sheetName = ''
    let headerName = ''
    for (const countryWithMethod of Object.keys(this.sheetJson)) {
      const countryWithMethodJson = this.sheetJson[countryWithMethod]
      const invalidHeaders = Object.keys(countryWithMethodJson[0]).filter((key: string) => {
        return !sheetValidHeaders.includes(key)
      })
      headerName = invalidHeaders.toString()
      if (headerName) {
        sheetName = countryWithMethod
        break
      }
    }
    return { sheetName, headerName }
  }

  public jsonToSheet(sheetJson = this.sheetJson, option: JSON2SheetOpts = {}): WorkSheet {
    return utils.json_to_sheet(sheetJson, option)
  }

  public saveSheetByJson = (name = 'sentbiz.xlsx', options?: sheetHandlerOption) => {
    const workBook = utils.book_new()
    Object.keys(this.sheetJson).forEach(sheetName => {
      const eachSheetJson = this.sheetJson[sheetName]
      let eachSheet: WorkSheet = this.jsonToSheet(eachSheetJson)
      eachSheet['!cols'] = this.getColumnOptions(eachSheetJson)

      if (options?.useCellDataOption) {
        const option: Dictionary<CellDataOption> = options?.cellDataOptions || {}
        eachSheet = this.setCellDataType(eachSheet, eachSheetJson, option)
        eachSheet['!ref'] = this.setRangeCellDataType(eachSheetJson)
      }
      utils.book_append_sheet(workBook, eachSheet, sheetName)
    })
    writeFile(workBook, name, { bookType: 'xlsx' })
  }

  public saveSheetFile = (workSheet: WorkSheet, name = 'sentbiz.xlsx') => {
    const workBook = utils.book_new()
    utils.book_append_sheet(workBook, workSheet)
    writeFile(workBook, name)
  }

  public async sheetToJson(file: Blob, options?: sheetHandlerOption): Promise<Dictionary<any>> {
    store.commit('enableLoading', i18n.t('notification.loading.upload_excel'))
    await new Promise(resolve => setTimeout(resolve, 50))
    const workBook: WorkBook = await this.sheetToWorkBook(file)
    workBook.SheetNames = this.filterValidSheets(workBook.SheetNames)
    this.sheetJson = this.workBookToJson(workBook, options?.range)
    return this.sheetJson
  }
}

export default SheetHandler
