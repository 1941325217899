
import Vue from 'vue'
import Component from 'vue-class-component'
import RouterOption from '@/models/RouterOption'
import { Prop } from 'vue-property-decorator'

@Component
export default class MainNaviWrapper extends Vue {
  @Prop(Array) navs!: Array<RouterOption>
  @Prop(Object) activeNav!: RouterOption
  @Prop(Object) parentNav!: RouterOption

  moveNavRouterLinkPath(nav: RouterOption) {
    const path = nav.children ? `${nav.path}/${nav.children[0].path}` : nav.path
    this.$router.push(path)
  }

  isParentActiveNav(path: string): boolean {
    return this.parentNav.path === path
  }
}
