
import Vue from 'vue'
import Component from 'vue-class-component'
import LanguageButton from '@/components/commons/LanguageButton.vue'
import CustomerCenterInfo from '@/components/commons/CustomerCenterInfo.vue'

type Path = { path: string; label: string; query?: Dictionary<string> }
@Component({
  components: { LanguageButton, CustomerCenterInfo }
})
export default class SignInWrapper extends Vue {
  koreanString: string = '한국어'
  englishString: string = 'English'

  tabPaths: Array<Path> = []
  titleLabels: Dictionary<string> = {
    signIn: this.$t('commons.login') as string,
    password: this.$t('commons.register_password') as string,
    signUp: this.$t('commons.signup_sentbiz') as string
  }

  get isSignInUpPage(): boolean {
    const signInUpPaths = ['/signIn', '/signUp']
    return signInUpPaths.includes(this.$route.path)
  }

  get titleLabel(): string {
    const routePath = this.$route.path.substr(1)
    return this.titleLabels[routePath]
  }

  setTabPaths(): void {
    this.tabPaths = [
      { path: `${process.env.VUE_APP_LANDING_PAGE}SentBiz`, label: `${this.$t('commons.sentbiz_introduce')}` },
      { path: `${process.env.VUE_APP_LANDING_PAGE}FAQ`, label: `${this.$t('commons.faq')}` },
      { path: `https://dashboard.sentbiz.com/terms`, label: `이용약관 및 개인정보 처리방침` }
    ]
  }

  created(): void {
    this.setTabPaths()
  }
}
