enum ReceiveMethodEnum {
  RNONE = 0,
  BANK,
  PICKUP,
  BILL,
  PAYMENT_GATEWAY,
  DEBIT_CARD,
  HOME_DELIVERY,
  ALFA_PICKUP,
  BRI_PICKUP,
  ALIPAY,
  MG_PICKUP,
  USD_ANYWHERE=21
}

export default ReceiveMethodEnum
