
import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import $eventBus from '@/plugins/eventBus'
import { CompanyInfo } from '@/presentation/affiliate/CompanyInfo'
import { BusinessCategory } from '@/presentation/affiliate/CompanyInfoField'
import { Prop } from 'vue-property-decorator'
import AddressModal from '@/components/address/AddressModal.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import { Address } from '@/presentation/address/AddressField'

@Component({
  components: { BasicDialog, AddressModal, FormItems }
})
export default class CompanyDocument extends Vue {
  @Prop(String) docName!: string

  document: CompanyInfo = new CompanyInfo(this.emitBusinessCategory)
  addressDialog = false

  get getAddress(): Address {
    return {
      country: this.document.field.country,
      region: this.document.field.region,
      city: this.document.field.city,
      line1: this.document.field.line1 || '',
      line2: this.document.field.line2,
      postal_code: this.document.field.postal_code
    }
  }

  emitBusinessCategory(isCorporateType: boolean) {
    this.$emit('onSelectedBusinessCategory', isCorporateType)
  }

  onSelectedBusinessCategory(): void {
    if (!this.document.field.biz_category) return
    const isCorporateType =
      BusinessCategory[this.document.field.biz_category] === BusinessCategory[BusinessCategory.CORPORATE_BUSINESS]
    this.emitBusinessCategory(isCorporateType)
    this.document.onSelectedBusinessCategory(isCorporateType)
  }

  onSelectedPurpose(): void {
    const isEtcSelected = this.document.onSelectedPurpose()
    this.$emit('setDocumentEnable', 'leaderAccount', isEtcSelected)
    this.$emit('setDocumentEnable', 'owner', isEtcSelected)
  }

  onClickAddress(): void {
    this.addressDialog = true
  }

  onSelectedAddress(address: Address): void {
    this.document.field = Object.assign(this.document.field, address)
    this.addressDialog = false
  }

  async onSubmit(): Promise<void> {
    const isValid = await this.document.onSubmit(this.$refs[this.document.formName])
    if (!isValid || Object.keys(isValid).length >= 1) return
    this.$emit('onSubmitted', this.docName)
  }

  created() {
    $eventBus.$on('companyInfoValidate', async (resolve: any, reject: any) => {
      const isValid = await this.document.formValidator.validate(this.$refs[this.document.formName])
      if (isValid) {
        this.document.setCorpAll()
        resolve()
      } else {
        reject(this.docName)
      }
    })
  }
}
