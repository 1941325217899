import { UpdateCorpMemberLocale, UpdateCorpMemberLocaleParam } from '@/gateway/corpMember/UpdateCorpMemberLocale'
import { ICorpMemberLocalePresentation } from '@/presentation/user/CorpMemberLocalePresentation'

export interface ICorpMemberLocaleUseCase {
  updateCorpMemberLocale (data: UpdateCorpMemberLocaleParam): Promise<any>
}

export class CorpMemberLocaleUseCase implements ICorpMemberLocalePresentation {
  async updateCorpMemberLocale (data: UpdateCorpMemberLocaleParam): Promise<any> {
    return UpdateCorpMemberLocale.getInstance().request(data)
  }
}
