import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { ApplyTransfer } from '@/views/transfer/model/ApplyTransfer'
import { Module } from 'vuex'
import { RootState } from '@/store/RootState'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'

export interface TransferState {
  applyTransfers: Dictionary<Array<ApplyTransfer>>;
  validatedTransfers: Dictionary<any>;
  manualValidatedTransfers: Dictionary<Array<TransferRow>>;
  analogueRemittanceGroups: Array<RemittanceGroupDetail>;
  analogueConfirmedRemittanceGroups: Array<RemittanceGroupDetail>;
  registerTransfers: Array<TransferRow>;
  createdRemittance: Dictionary<any>;
}

export const transferStore: Module<TransferState, RootState> = {
  state: {
    applyTransfers: {},
    validatedTransfers: {},
    manualValidatedTransfers: {},
    analogueRemittanceGroups: [],
    analogueConfirmedRemittanceGroups: [],
    registerTransfers: [],
    createdRemittance: {}
  },
  mutations: {
    setApplyTransfers (state, transfers: Dictionary<Array<ApplyTransfer>> = {}): void {
      state.applyTransfers = transfers
    },
    setValidatedTransfers (state, transfers: Dictionary<Array<TransferRow>> = {}): void {
      state.validatedTransfers = transfers
    },
    setManualValidatedTransfers (state, transfers: Dictionary<Array<TransferRow>> = {}): void {
      state.manualValidatedTransfers = transfers
    },
    setAnalogueRemittanceGroups (state, remittanceGroups: Array<RemittanceGroupDetail>) {
      state.analogueRemittanceGroups = remittanceGroups
    },
    setAnalogueConfirmedRemittanceGroups (state, remittanceGroups: Array<RemittanceGroupDetail>) {
      state.analogueConfirmedRemittanceGroups = remittanceGroups
    },
    setRegisterTransfers (state, transfers: Array<TransferRow> = []): void {
      state.registerTransfers = transfers
    },
    setCreatedRemittance (state, remittance: Dictionary<any>): void {
      state.createdRemittance = remittance
    }
  },
  getters: {
    getApplyTransfers (state): Dictionary<Array<ApplyTransfer>> {
      return state.applyTransfers
    },
    getValidatedTransfers (state): Dictionary<Array<TransferRow>> {
      return state.validatedTransfers
    },
    getManualValidatedTransfers (state): Dictionary<Array<TransferRow>> {
      return state.manualValidatedTransfers
    },
    getAnalogueRemittanceGroups (state): Array<RemittanceGroupDetail> {
      return state.analogueRemittanceGroups
    },
    getAnalogueConfirmedRemittanceGroups (state): Array<RemittanceGroupDetail> {
      return state.analogueConfirmedRemittanceGroups
    },
    getRegisterTransfers (state): Array<TransferRow> {
      return state.registerTransfers
    },
    getCreatedRemittance (state): Dictionary<any> {
      return state.createdRemittance
    }
  }
}
