import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { FxRates } from '@/gateway/fxRates/FxRates'
import { FileTransferHandler } from '@/lib/FileTransferHandler'
import { IndividualReceiptHandler, IndividualReceiptParam } from '@/lib/pdf/cert/IndividualReceiptHanlder'
import { TransferConfirmationHandler, TransferConfirmationParam } from '@/lib/pdf/cert/TransferConfirmationHandler'
import { RemittanceHistoryGroup, RemittanceHistoryGroupDetail } from '@/models/remittance'
import { ITransferHistoryUseCase, TransferHistoryUseCase } from '@/usecase/transferHistory/TransferHistoryUseCase'

export interface ITransferHistoryDetailsPresentation {
  useCase: ITransferHistoryUseCase

  getInvoiceFile(remittanceId: string, vueComponent: Vue): Promise<any>
  makeDepositCertification(
    id: string,
    list: Array<RemittanceHistoryGroupDetail>,
    groupDetail: RemittanceHistoryGroup
  ): Promise<void>
  makeIndividualReceipt(
    id: string,
    list: Array<RemittanceHistoryGroupDetail>,
    groupDetail: RemittanceHistoryGroup,
    isRefund?: boolean
  ): Promise<void>
  updateComment(comment: Dictionary<any>): Promise<boolean>
}

export class TransferHistoryDetailsPresentation implements ITransferHistoryDetailsPresentation {
  public useCase: ITransferHistoryUseCase

  constructor() {
    this.useCase = new TransferHistoryUseCase()
  }

  async getInvoiceFile(remittanceId: string, vueComponent: Vue): Promise<any> {
    const base64String = await this.useCase.getRemittanceInvoice(remittanceId)
    if (!base64String) {
      vueComponent.$message({ message: '인보이스 파일이 존재하지 않습니다.', type: 'error' })
      return
    }
    const fileTransferHandler = FileTransferHandler.getInstance()
    const blobUrl: string = fileTransferHandler.getBase64ObjectUrl(base64String)
    if (!blobUrl) return vueComponent.$message({ message: '알 수 없는 인보이스 파일입니다.', type: 'error' })
    fileTransferHandler.download(blobUrl, `${remittanceId}_invoice`)
  }

  async makeDepositCertification(
    id: string,
    list: Array<RemittanceHistoryGroupDetail>,
    groupDetail: RemittanceHistoryGroup
  ): Promise<void> {
    const corpInfo: CorpInformation | undefined = await this.useCase.getCorpInfo()
    if (!corpInfo) return
    const remittance: RemittanceHistoryGroupDetail | undefined = list.find(element => element.id === id)
    if (!remittance) return
    const remittanceGroup: RemittanceHistoryGroup = groupDetail
    const fxTaskId = remittanceGroup.fx_task_id
    const fxRates: FxRates = await this.useCase.getFxRates(fxTaskId)
    const transferConfirmationParam: TransferConfirmationParam = {
      corpInfo,
      remittance,
      fxRates
    }
    const transferConfirmation = new TransferConfirmationHandler(transferConfirmationParam)
    await transferConfirmation.printDocument()
  }

  async makeIndividualReceipt(
    id: string,
    list: Array<RemittanceHistoryGroupDetail>,
    groupDetail: RemittanceHistoryGroup,
    isRefund?: boolean
  ): Promise<void> {
    const corpInfo: CorpInformation | undefined = await this.useCase.getCorpInfo()
    if (!corpInfo) return
    const remittance: RemittanceHistoryGroupDetail | undefined = list.find(element => element.id === id)
    if (!remittance) return
    const remittanceGroup: RemittanceHistoryGroup = groupDetail
    const isVersion4 = remittance.version === 4
    const rateFeeParam = {
      currency: remittance.receive_amount.currency,
      rate_fee: remittance.rate_fee
    }
    const fxTaskId = remittanceGroup.fx_task_id
    const [fxRates, krwFxRates] = await Promise.all([
      this.useCase.getFxRates(fxTaskId),
      isVersion4 ? this.useCase.getKrwRatesV4(fxTaskId, rateFeeParam) : this.useCase.getKrwRates(fxTaskId)
    ])
    const isRefundParam: boolean = !!isRefund
    const individualReceiptParam: IndividualReceiptParam = {
      remittanceGroup,
      remittance,
      fxRates,
      krwFxRates,
      corpInfo,
      isRefund: isRefundParam
    }
    const individualReceipt = new IndividualReceiptHandler(individualReceiptParam)
    await individualReceipt.printDocument()
  }

  async updateComment(comment: Dictionary<any>): Promise<boolean> {
    const updateRemittanceCommentParam = {
      remittance_id: comment.remittance_id,
      comment: comment.comment
    }
    const response = await this.useCase.updateRemittanceComment(updateRemittanceCommentParam)
    return response?.code === 200
  }
}
