import store from '@/store'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import { regexToPatternString } from '@/data/Regex'
import { FormValidator } from '@/presentation/FormValidator'
import { Form, IFormView } from '@/presentation/IFormView'
import { CeoInformation, CeoInformationField, IdentificationType } from '@/presentation/affiliate/CeoInformationField'
import CountryEnum from '@/enums/CountryEnum'
import { KoreanBank } from '@/data/KoreanBank'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { UpdateCorpAll } from '@/gateway/affiliate/UpdateCorpAll'
import FormRule from '@/models/forms/FormRule'

export interface LeaderAccount extends IFormView {
  formValidator: FormValidator;

  leaderInfoFormOptions: Array<Array<FormItemsOption>>;
  leaderInfoTitle: string;

  isEnable (): boolean;
  isActivate (): boolean;
  setCorpAll (): void;
  onClickSubButton (): Promise<void>;

  addLeaderInfo (): void;
  subLeaderInfo (index: number): void;
  onSelectedLeaderCountry (index: number): void;
}

export class LeaderAccount implements LeaderAccount {
  private corpAll: CorpAll
  constructor () {
    this.corpAll = store.getters.getCorpAllState
    const ceoInformationList = !this.corpAll.ceo_information_list || !this.corpAll.ceo_information_list.length
      ? [Object.assign({}, this.leaderField)]
      : this.corpAll.ceo_information_list
    this.field = {
      ceo_information_list: ceoInformationList,
      bank_name: this.corpAll.bank_name || undefined,
      bank_account_number: this.corpAll.bank_account_number,
      account_holder: this.corpAll.account_holder
    }
    this.fieldInitialize()
  }
  private fieldInitialize (): void {
    this.field.ceo_information_list?.forEach((ceoInformation, index) => {
      if (index) this.addLeaderInfo(true)
      this.onSelectedLeaderCountry(index)
    })
  }

  formValidator: FormValidator = new FormValidator()
  formName = 'leaderAccountDocuments'
  formClass = 'divided-form'

  private leaderField: CeoInformation = {
    name: '',
    english_name: '',
    nationality: undefined,
    identification_number: undefined,
    gender: undefined,
    birthday: undefined
  }
  field: CeoInformationField

  leaderInfoTitle = '대표자 신원 정보'
  private required: FormRule = { required: true, message: 'This field is required' }
  private leaderFormOption: Array<FormItemsOption> = [
    {
      prop: 'name',
      model: 'name',
      label: '성명(한글)',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('korean'), message: 'korean only' }]
    },
    {
      prop: 'english_name',
      model: 'english_name',
      label: '성명(영문)',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('english'), message: 'english only' }]
    },
    {
      prop: 'nationality',
      model: 'nationality',
      label: '국적',
      type: 'select',
      options: getEnumOptions(CountryEnum, getEnumValueArray(CountryEnum), true).sort((a, b) => {
        const nameA = a.label
        const nameB = b.label
        if (a.value === 'KR') return -1
        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0)
      }),
      emitHandlerName: 'onSelectedLeaderCountry',
      value: undefined,
      rules: [this.required]
    },
    {
      prop: 'identification_number',
      model: 'identification_number',
      label: '주민등록번호',
      type: 'text',
      hide: true,
      value: undefined,
      rules: [this.required]
    },
    {
      prop: 'gender',
      model: 'gender',
      label: '성별',
      type: 'select',
      hide: true,
      options: [{ label: '여자 (female)', value: 'female' }, { label: '남자 (male)', value: 'male' }],
      value: undefined,
      rules: [this.required]
    },
    {
      prop: 'birthday',
      model: 'birthday',
      label: '생년월일',
      type: 'date',
      hide: true,
      value: undefined,
      rules: [this.required]
    }
  ]
  leaderInfoFormOptions: Array<Array<FormItemsOption>> = [
    this.leaderFormOption.map((option) => {
      const newOption = Object.assign({}, option)
      newOption.prop = `ceo_information_list.0.${newOption.prop}`
      return newOption
    })
  ]

  private sourcePurposeTitle = '계좌 정보'
  private sourcePurposeFormOptions: Array<FormItemsOption> = [
    {
      prop: 'bank_name',
      label: '은행명',
      type: 'select',
      options: KoreanBank.map(bank => {
        return { label: bank.name_kr, value: bank.name_en, key: bank.id }
      }),
      value: undefined,
      rules: [this.required]
    },
    {
      prop: 'bank_account_number',
      label: '계좌번호',
      type: 'text',
      value: undefined,
      rules: [{ ...this.required, pattern: regexToPatternString('number'), message: 'numbers only' }]
    },
    {
      prop: 'account_holder',
      label: '예금주',
      type: 'text',
      value: undefined,
      rules: [this.required]
    }
  ]

  forms: Array<Form> = [
    {
      subTitle: this.sourcePurposeTitle,
      formItemOptions: this.sourcePurposeFormOptions
    }
  ]

  isEnable (): boolean {
    if (!this.field.ceo_information_list) return false
    const ceoInfo = this.field.ceo_information_list[0]
    const hasCeoInformationList = Object.values(ceoInfo).some(field => {
      if (field === '0001-01-01T00:00:00Z') return false
      return !!field
    })
    const hasAccountFields: boolean = !!this.field.bank_name || !!this.field.bank_account_number || !!this.field.account_holder
    return hasCeoInformationList || hasAccountFields
  }

  isActivate (): boolean {
    return this.corpAll.status === Number(CorpStatus.FORM_CEO_PROCESSING)
  }

  onSelectedLeaderCountry (index: number): void {
    if (!this.field.ceo_information_list) return
    const nationality = this.field.ceo_information_list[index].nationality
    if (!nationality) return
    const isKorean = Number(CountryEnum[nationality]) === Number(CountryEnum.KR)
    const label = isKorean ? '주민등록번호 (숫자만 입력)' : '여권번호'
    const showModels: Array<string> = isKorean ? ['identification_number'] : ['identification_number', 'gender', 'birthday']
    const hideModels: Array<string> = isKorean ? ['gender', 'birthday'] : []
    this.leaderInfoFormOptions[index].forEach(option => {
      if (option.model === 'identification_number') {
        option.label = label
        if (isKorean) {
          option.maxlength = 13
          option.minlength = 13
          option.showLimit = true
          option.rules = [
            this.required,
            { pattern: regexToPatternString('number'), message: '숫자만 입력해 주세요' }
          ]
        } else {
          delete option.maxlength
          delete option.minlength
          delete option.showLimit
          option.rules = [this.required]
        }
      }
      if (option.model && showModels.includes(option.model)) option.hide = false
      if (option.model && hideModels.includes(option.model)) option.hide = true
    })
    this.field.ceo_information_list[index].identification_type = isKorean
      ? IdentificationType.REGISTRATION_CARD
      : IdentificationType.PASSPORT
  }

  addLeaderInfo (isInitialize = false): void {
    const addLength = this.leaderInfoFormOptions.length
    const addLeaderInfoFormOption = this.leaderFormOption.map(option => {
      const newOption = Object.assign({}, option)
      newOption.prop = `ceo_information_list.${addLength}.${newOption.prop}`
      return newOption
    })
    if (!this.field.ceo_information_list) return
    if (!isInitialize) this.field.ceo_information_list.push(Object.assign({}, this.leaderField))
    this.leaderInfoFormOptions.push(addLeaderInfoFormOption)
  }

  subLeaderInfo (index: number): void {
    if (!this.field.ceo_information_list) return
    this.field.ceo_information_list.splice(index, 1)
    this.leaderInfoFormOptions.splice(index, 1)
  }

  setCeoBirthInfo () {
    this.field.ceo_information_list?.forEach(ceoInfo => {
      const isPassport = Number(ceoInfo.identification_type) === Number(IdentificationType.PASSPORT)
      if (isPassport || !ceoInfo.identification_number) return
      const genderChar = ceoInfo.identification_number.charAt(6)
      const isFemale = Number(genderChar) % 2 === 0
      ceoInfo.gender = isFemale ? 'female' : 'male'
      const yearPrefix = Number(genderChar) < 3 ? '19' : '20'
      const yearSuffix = ceoInfo.identification_number.substring(0, 2)
      const year = yearPrefix + yearSuffix
      const birth = `${year}-${ceoInfo.identification_number.substr(2, 2)}-${ceoInfo.identification_number.substr(4, 2)}T00:00:00Z`
      ceoInfo.birthday = new Date(birth)
    })
  }

  setCorpAll () {
    this.setCeoBirthInfo()
    this.corpAll = store.getters.getCorpAllState
    this.corpAll = Object.assign({}, this.corpAll, this.field)
    this.corpAll.status = CorpStatus.FORM_CEO_PROCESSING
    store.commit('setCorpAllState', this.corpAll)
  }

  private async updateCorpAll () {
    const updateCorpAll: UpdateCorpAll = UpdateCorpAll.getInstance()
    await updateCorpAll.request(this.corpAll)
  }

  subButtonLabel = '임시 저장'
  async onClickSubButton (): Promise<void> {
    this.setCorpAll()
    await this.updateCorpAll()
  }

  submitLabel = '다음'
  async onSubmit (formName: any): Promise<boolean | void> {
    const isValid = await this.formValidator.validate(formName)
    if (!isValid) return isValid
    this.setCorpAll()
    await this.updateCorpAll()
    return isValid
  }
}
