import { AxiosCreator } from '@/gateway/axios/AxiosCreator'
import AxiosOptions from '@/gateway/axios/AxiosOptions'
import { AxiosInstance } from 'axios'

const sentbizApiOption: AxiosOptions = {
  api: process.env.VUE_APP_SENTBIZ_API,
  isAuthTokenRequired: true
}
export const sentbizApi: AxiosInstance = new AxiosCreator().create(sentbizApiOption)
const sentbizApiWithoutOverlayOption: AxiosOptions = {
  ...sentbizApiOption,
  disableLoadingOverlay: true
}
export const sentbizApiWithoutOverlay: AxiosInstance = new AxiosCreator().create(sentbizApiWithoutOverlayOption)

const sentbizApiWithoutAuthOption: AxiosOptions = {
  api: process.env.VUE_APP_SENTBIZ_API,
  isAuthTokenRequired: false
}
export const sentbizApiWithoutAuth: AxiosInstance = new AxiosCreator().create(sentbizApiWithoutAuthOption)

const headers = {
  'Access-Control-Allow-Methods': 'GET, HEAD, OPTIONS, POST',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Max-Age': '1296000'
}
const addressApiOption: AxiosOptions = {
  baseHeader: { headers: headers },
  api: process.env.VUE_APP_ADDRESS_API,
  isAuthTokenRequired: false
}
export const addressApi: AxiosInstance = new AxiosCreator().create(addressApiOption)
