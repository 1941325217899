import { sentbizApi } from '../axios'
import { SnapshotResponse } from './model/SnapshotResponse'

export interface IGetDefaultSnapshot {
  request (corpId: string): Promise<SnapshotResponse>;
}

export default class GetDefaultSnapshot implements IGetDefaultSnapshot {
  private static instance: GetDefaultSnapshot
  private constructor () {}
  public static getInstance (): GetDefaultSnapshot {
    if (!this.instance) this.instance = new GetDefaultSnapshot()
    return this.instance
  }

  async request (corpId: string): Promise<SnapshotResponse> {
    const requestOption: RequestOption = {
      url: 'snapshot/default',
      method: 'get',
      params: { corp_id: corpId }
    }
    const response: any = await sentbizApi.request(requestOption)
    return response.data
  }
}
