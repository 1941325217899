import CountryEnum from '@/enums/CountryEnum'

interface Bank {
  id: number;
  country: keyof typeof CountryEnum;
  name_kr: string;
  name_en: string;
  order: number;
}

export const KoreanBank: Array<Bank> = [
  {
    id: 10325,
    country: 'KR',
    name_en: 'Daegu Bank',
    name_kr: '대구은행',
    order: 0
  },
  {
    id: 10306,
    country: 'KR',
    name_en: 'Busan Bank',
    name_kr: '부산은행',
    order: 1
  },
  {
    id: 10308,
    country: 'KR',
    name_en: 'KwangJu Bank',
    name_kr: '광주은행',
    order: 2
  },
  {
    id: 10310,
    country: 'KR',
    name_en: 'Jeju Bank',
    name_kr: '제주은행',
    order: 3
  },
  {
    id: 10312,
    country: 'KR',
    name_en: 'JeonBuk Bank',
    name_kr: '전북은행',
    order: 4
  },
  {
    id: 10313,
    country: 'KR',
    name_en: 'Suhyup Bank',
    name_kr: '수협은행',
    order: 5
  },
  {
    id: 10314,
    country: 'KR',
    name_en: 'Kyongnam Bank',
    name_kr: '경남은행',
    order: 6
  },
  {
    id: 10317,
    country: 'KR',
    name_en: 'Woori Bank',
    name_kr: '우리은행',
    order: 7
  },
  {
    id: 10319,
    country: 'KR',
    name_en: 'Standard Chartered First Bank Korea',
    name_kr: 'SC은행',
    order: 8
  },
  {
    id: 10321,
    country: 'KR',
    name_en: 'Shinhan Bank',
    name_kr: '신한은행',
    order: 9
  },
  {
    id: 10323,
    country: 'KR',
    name_en: 'Citibank Korea',
    name_kr: '씨티은행',
    order: 10
  },
  {
    id: 10326,
    country: 'KR',
    name_en: 'Hana Bank',
    name_kr: '하나은행',
    order: 11
  },
  {
    id: 10305,
    country: 'KR',
    name_en: 'Korea Development Bank',
    name_kr: 'KDB산업은행',
    order: 12
  },
  {
    id: 10309,
    country: 'KR',
    name_en: 'Kookmin Bank',
    name_kr: 'KB국민은행',
    order: 13
  },
  {
    id: 10307,
    country: 'KR',
    name_en: 'Industrial Bank of Korea',
    name_kr: 'IBK기업은행',
    order: 14
  },
  {
    id: 10311,
    country: 'KR',
    name_en: 'Hana Bank',
    name_kr: '외환은행',
    order: 15
  },
  {
    id: 10316,
    country: 'KR',
    name_en: 'Korea federation of Commuity Credit cooperative',
    name_kr: '새마을은행',
    order: 16
  },
  {
    id: 10318,
    country: 'KR',
    name_en: 'National Credit Union Federation of Korea',
    name_kr: '신협은행',
    order: 17
  },
  {
    id: 10320,
    country: 'KR',
    name_en: 'Korea federation of savings banks',
    name_kr: '저축은행',
    order: 18
  },
  {
    id: 10322,
    country: 'KR',
    name_en: 'National Forestry Cooperative Federation',
    name_kr: '산림조합',
    order: 19
  },
  {
    id: 11325,
    country: 'KR',
    name_en: 'Mutual Savings Bank',
    name_kr: '상호저축은행',
    order: 20
  },
  {
    id: 10315,
    country: 'KR',
    name_en: 'National Agricultural Cooperative Federation',
    name_kr: 'NH농협은행',
    order: 21
  },
  {
    id: 11346,
    country: 'KR',
    name_en: 'K Bank',
    name_kr: '케이뱅크',
    order: 22
  },
  {
    id: 11347,
    country: 'KR',
    name_en: 'Kakao Bank',
    name_kr: '카카오뱅크',
    order: 23
  },
  {
    id: 10324,
    country: 'KR',
    name_en: 'Korea Post Office',
    name_kr: '우체국',
    order: 24
  },
  {
    id: 10769,
    country: 'KR',
    name_en: 'National Agricultural Cooperative',
    name_kr: 'NH지역농축협은행',
    order: 25
  },
  {
    id: 13012,
    country: 'KR',
    name_en: 'Toss Bank',
    name_kr: '토스뱅크',
    order: 26
  }
]
