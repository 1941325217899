import { Wallet } from '@/models/wallet/Wallet'
import { GetWalletParam } from './model/GetWalletParam'
import { sentbizApiWithoutOverlay } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'

export interface GetWallet extends IApiRequest {
    getInstance (): GetWallet;
    request (params: GetWalletParam): Promise<Array<Wallet>>;
}

export class GetWallet implements GetWallet {
    private static instance: GetWallet

    private constructor () {}

    public static getInstance (): GetWallet {
      if (!this.instance) this.instance = new GetWallet()
      return this.instance
    }

    async request (params: GetWalletParam): Promise<Array<Wallet>> {
      const requestOption: RequestOption = {
        url: 'wallet',
        method: 'get',
        params
      }
      const response = await sentbizApiWithoutOverlay.request(requestOption)
      return response.data.list
    }
}
