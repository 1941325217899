import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { VirtualAccount } from '@/gateway/remittance/model/VirtualAccount'

export interface GetVirtualAccount extends IApiRequest {
  getInstance (): GetVirtualAccount;

  request (data: { corp_id: string }): Promise<any>;
}

export class GetVirtualAccount implements GetVirtualAccount {
  private static instance: GetVirtualAccount

  private constructor () {}
  public static getInstance (): GetVirtualAccount {
    if (!this.instance) this.instance = new GetVirtualAccount()
    return this.instance
  }

  async request (data: { corp_id: string }): Promise<VirtualAccount> {
    const requestOption: RequestOption = {
      url: 'virtual-account',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }
}
