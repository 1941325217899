import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { OtpParam } from '@/gateway/corpMember/model/OtpParam'

export interface CheckOtp extends IApiRequest {
  getInstance (): CheckOtp;
  request (data: OtpParam): Promise<any>;
}

export class CheckOtp implements CheckOtp {
  private static instance: CheckOtp
  private constructor () {}
  public static getInstance (): CheckOtp {
    if (!this.instance) this.instance = new CheckOtp()
    return this.instance
  }

  async request (data: OtpParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'corp-member/otp',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
