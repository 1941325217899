import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { IWalletPresentation, WalletPresentation } from '@/presentation/wallet/WalletPresentation'
import { Module } from 'vuex'
import { RootState } from '../RootState'

interface WalletState {
  walletActivityDot: boolean;
  currenciesAndBalance: Array<{ currency: keyof typeof CurrencyEnum, balance: string }>;
}

export const walletStore: Module<WalletState, RootState> = {
  state: {
    walletActivityDot: false,
    currenciesAndBalance: []
  },
  modules: {},
  getters: {
    hasNewWalletActivityDot (state): boolean {
      return state.walletActivityDot
    },
    getCurrenciesAndBalance (state): Array<{ currency: keyof typeof CurrencyEnum, balance: string }> {
      return state.currenciesAndBalance
    }
  },
  mutations: {
    setNewWalletActivityDot (state, newResponseTimestamp) {
      const existTimestamp = Date.parse(`${localStorage.getItem('latestWalletStamp')}`)
      state.walletActivityDot = !existTimestamp ? true : newResponseTimestamp > existTimestamp
    },
    async setWalletCurrenciesAndBalance (state) {
      let walletPresentation: IWalletPresentation = new WalletPresentation()
      await walletPresentation.initialize()
      state.currenciesAndBalance = walletPresentation.getWalletCurrenciesAndBalance()
    }
  }
}
