import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import 'swiper/components/pagination/pagination.min.css'

import SwiperClass, { Navigation, Pagination /* add some modules if you need */ } from 'swiper'
// configure Swiper to use modules
SwiperClass.use([Navigation, Pagination])

Vue.use(VueAwesomeSwiper)
