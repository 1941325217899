import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'

export interface GetRemittanceInvoice extends IApiRequest {
  getInstance (): GetRemittanceInvoice;
  request (data: { remittance_id: string }): Promise<string>;
}

export class GetRemittanceInvoice implements GetRemittanceInvoice {
  private static instance: GetRemittanceInvoice
  private constructor () {}
  public static getInstance (): GetRemittanceInvoice {
    if (!this.instance) this.instance = new GetRemittanceInvoice()
    return this.instance
  }

  async request (data: { remittance_id: string }): Promise<string> {
    const requestOption: RequestOption = {
      url: 'remittance/invoice',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data?.file
  }
}
