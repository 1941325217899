
import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import { LeaderAccount } from '@/presentation/affiliate/LeaderAccount'
import FormItemsModelInput from '@/components/shared/FormItemsModelInput.vue'
import { Prop } from 'vue-property-decorator'
import $eventBus from '@/plugins/eventBus'

@Component({
  components: { FormItemsModelInput, FormItems }
})
export default class LeaderAccountDocument extends Vue {
  @Prop(String) docName!: string

  document: LeaderAccount = new LeaderAccount()

  async onSubmit (): Promise<void> {
    const isValid = await this.document.onSubmit(this.$refs[this.document.formName])
    if (!isValid) return
    this.$emit('onSubmitted', this.docName)
  }

  created () {
    $eventBus.$on('leaderAccountInfoValidate', async (resolve: any, reject: any) => {
      const isValid = await this.document.formValidator.validate(this.$refs[this.document.formName])
      if (isValid) {
        this.document.setCorpAll()
        resolve()
      } else {
        reject(this.docName)
      }
    })
    if (!this.document.isEnable()) return
    this.$emit('setDocumentEnable', this.docName)
    if (this.document.isActivate()) this.$emit('moveTab', this.docName)
  }
}
