import {
  BusinessCategory,
  BusinessDivision,
  FundSource,
  StockInfo,
  TransactionPurpose
} from '@/presentation/affiliate/CompanyInfoField'
import CountryEnum from '@/enums/CountryEnum'
import { CeoInformation } from '@/presentation/affiliate/CeoInformationField'
import { ShareHolderInformation, ShareHolderStatus } from '@/presentation/affiliate/ShareHolderInformationField'

export enum CorpStatus {
  REGISTERED = 1,
  FORM_COMPANY_PROCESSING,
  FORM_CEO_PROCESSING,
  FORM_OWNER_PROCESSING,
  FORM_COMPLETE = 5,
  PROCESSING = 10,
  CONFIRMED = 20
}

export interface CorpAll {
  id?: number
  name?: string
  name_ko?: string
  biz_license_number?: string
  symbol?: string
  base_currency?: string
  biz_type?: string
  biz_item?: string
  assigned_virtual_account?: string
  status?: CorpStatus
  updated_at?: Date
  created_at?: Date
  approved_at?: Date
  fee_terms?: string
  cut_off_time?: string
  special_terms?: string
  memo?: string
  biz_category?: BusinessCategory
  biz_division?: BusinessDivision
  stock_info?: StockInfo
  line1?: string
  line2?: string
  line1Detail?: string
  city?: string
  region?: string
  postal_code?: string
  country?: string
  phone_code?: number
  phone_number?: number
  homepage?: string
  founded_at?: Date | string
  fund_source?: FundSource
  transaction_purpose?: TransactionPurpose
  ceo_information_list?: Array<CeoInformation>
  bank_name?: string
  bank_account_number?: string
  account_holder?: string
  share_holder_status?: ShareHolderStatus
  share_holder_information?: ShareHolderInformation
  temp_email?: string
}

export interface CompletedCorpAll {
  id: number
  name: string
  biz_license_number: string
  symbol: string
  base_currency: string
  biz_type: string
  biz_item: string
  assigned_virtual_account: string
  status: CorpStatus
  updated_at: Date
  created_at: Date
  approved_at: Date
  fee_terms: string
  cut_off_time: string
  special_terms: string
  memo: string
  biz_category: BusinessCategory
  biz_division: BusinessDivision
  stock_info: StockInfo
  line1: string
  line2: string
  city: string
  region: string
  postal_code: string
  country: keyof typeof CountryEnum
  phone_code: number
  phone_number: number
  homepage: string
  founded_at: Date
  fund_source: FundSource
  transaction_purpose: TransactionPurpose
  ceo_information_list: Array<CeoInformation>
  bank_name: string
  bank_account_number: string
  account_holder: string
  share_holder_status: ShareHolderStatus
  share_holder_information: ShareHolderInformation
}
