import { beforeLoginPaths } from '@/data/BeforeLoginPaths'
import Routes from '@/router/Routes'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { RawLocation, Route, RouteConfig } from 'vue-router/types/router'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

const allowParentPaths = ['affiliate']
const routes: Array<RouteConfig> = Routes

// For avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation): Promise<Route> {
  // @ts-ignore
  return originalPush.call(this, location).catch(err => {
    if (err.from?.path !== err.to?.path) return console.error(err)
  })
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const parentPath = to.path.split('/')[1]
  const isAllowPath = beforeLoginPaths.includes(to.path) || allowParentPaths.includes(parentPath)
  const isFraudPath = to.path.includes('dome')
  if (isFraudPath && !MemberInfoGateway.isAllowFraud()) return next('/home')
  if (isAllowPath) return next()
  if (MemberInfoGateway.hasAuthInfo()) return next()
  return next('/signIn')
})

Vue.use(VueRouter)

export default router
