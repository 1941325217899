
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ReviewCard from '@/components/landing/ReviewCard.vue'
import BasicSwiperOptions from '@/models/BasicSwiperOptions'

@Component({
  components: {
    ReviewCard, Swiper, SwiperSlide
  }
})
export default class extends Vue {
  @Prop(Object) swiperInfos!: BasicSwiperOptions;

  removeBr (target: string): string {
    return target.replace(/<br>/g, '')
  }

  get isEnglish (): boolean {
    return localStorage.getItem('locale') === 'en'
  }
}
