
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class MainTitle extends Vue {
  detectOfInTitle (target: string): string {
    return `${this.$t(target)}`.replace('의', '<span class="text-of">의</span>')
  }
}
