import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { Wallet } from '@/models/wallet/Wallet'
import { IWalletUseCase, WalletUseCase } from '@/usecase/wallet/WalletUseCase'
import { GetWalletParam } from '@/gateway/wallet/model/GetWalletParam'
import { CreatePaymentTransactionParam } from '@/gateway/wallet/model/CreatePaymentTransactionParam'
import { CreatePaymentTransactionResponse } from '@/gateway/wallet/model/CreatePaymentTransactionResponse'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface IWalletPresentation {
  wallets: Array<Wallet>

  getWalletByCurrency (currency: keyof typeof CurrencyEnum): Wallet | undefined;
  getWalletCurrenciesAndBalance (): Array<{ currency: keyof typeof CurrencyEnum, balance: string }>;
  createPaymentTransaction (currency: keyof typeof CurrencyEnum, walletId: number): Promise<CreatePaymentTransactionResponse>;
  initialize (): Promise<void>;
}

export class WalletPresentation implements IWalletPresentation {
    public wallets: Array<Wallet>
    private useCase: IWalletUseCase
    private corpId: number = Number(MemberInfoGateway.getMemberInfo().corp_id)

    constructor () {
      this.useCase = new WalletUseCase()
      this.wallets = []
    }

    getWalletByCurrency (currency: keyof typeof CurrencyEnum): Wallet | undefined {
      return this.wallets.find(wallet => wallet.currency === currency)
    }

    getWalletCurrenciesAndBalance (): Array<{ currency: keyof typeof CurrencyEnum, balance: string }> {
      return this.wallets.map(wallet => {
        return {
          currency: wallet.currency,
          balance: wallet.balance.toLocaleString()
        }
      })
    }

    createPaymentTransaction (currency: keyof typeof CurrencyEnum, walletId: number): Promise<CreatePaymentTransactionResponse> {
      const transactionParam: CreatePaymentTransactionParam = {
        corp_id: this.corpId,
        wallet_id: walletId,
        currency
      }
      return this.useCase.createPaymentTransaction(transactionParam)
    }

    async initialize (): Promise<void> {
      const getWalletParam: GetWalletParam = {
        corp_id: this.corpId
      }
      this.wallets = await this.useCase.getWallet(getWalletParam)
    }
}
