import SheetHandler from '@/lib/SheetHandler'
import TransferField from '@/enums/TransferField'
import i18n from '@/plugins/i18n'

class TransferSheetHandler extends SheetHandler {
  protected _sheetJson: any = {}
  private transferFields = Object.keys(TransferField).filter(key => typeof TransferField[key as any] === 'number')
  protected _sheetValidHeaders: Array<string> = this.transferFields.map(field => field.toLowerCase())

  get sheetJson (): any {
    return this._sheetJson
  }

  set sheetJson (value: any) {
    this._sheetJson = value
  }

  get sheetValidHeaders (): Array<string> {
    return this._sheetValidHeaders
  }

  protected filterValidSheets (sheetNames: Array<string>): Array<string> {
    const transferRegisterLabelKo: string = i18n.t('sheet.sheet_name.apply_transfer', 'ko') as string
    const transferRegisterLabelEn: string = i18n.t('sheet.sheet_name.apply_transfer', 'en') as string
    const trimLabelKo = transferRegisterLabelKo.toLowerCase().replace(/ /g, '')
    const isMatchSheetNameKo = sheetNames.some(sheetName => sheetName.toLowerCase() === trimLabelKo || sheetName.toLowerCase() === transferRegisterLabelKo)
    const isMatchSheetNameEn = sheetNames.some(sheetName => sheetName.toLowerCase() === transferRegisterLabelEn.toLowerCase())
    if (isMatchSheetNameKo || isMatchSheetNameEn) return sheetNames
    return [i18n.t('sheet.sheet_name.apply_transfer') as string]
  }

  public getFieldObject (): Array<any> {
    return [{
      corps_id: i18n.t('sheet.field.corps_id'),
      amount: i18n.t('sheet.field.base_amount'),
      base_currency: i18n.t('sheet.field.base_currency'),
      comment: i18n.t('sheet.field.comment')
    }]
  }

  public makeSheetJson (): Dictionary<any> {
    const sheetName = i18n.t('sheet.sheet_name.apply_transfer') as string
    const sheet: Dictionary<Array<any>> = {}
    sheet[sheetName] = this.getFieldObject()
    return sheet
  }

  public setSheetJsonToExport (sheetJson: Dictionary<any>): void {
    this.sheetJson = sheetJson
  }
}

export default TransferSheetHandler
